<fleetoperate-header
  [title]="title"
  [backAction]="backAction"
  [actions]="actions"
  class="default-background padding-right-md padding-left-md"
></fleetoperate-header>
<fleetoperate-error-message
  *ngIf="message"
  [error]="message"
  class="default-background padding-bottom-md padding-left-md"
></fleetoperate-error-message>
<form
  [formGroup]="createLoadForm"
  #createLoadFormRef="ngForm"
  (ngSubmit)="onSubmit(createLoadFormRef)"
  fxLayout="column"
  class="default-background padding-right-md padding-left-md create-load-form"
>
  <div fxLayout="column" fxLayoutAlign="end end">
    <div>Calculated distance in {{ rate.distanceUnits }} {{ rate.distance }}</div>
    <div>Cost of delivery in CAD {{ rate.rate }}</div>
  </div>

  <hr class="solid" />

  <mat-form-field class="margin-right-md" appearance="outline">
    <mat-label>Origin</mat-label>
    <input matInput #originText placeholder="Origin" formControlName="origin" required />
    <mat-error *ngIf="origin.touched && origin.invalid">
      {{ getOriginErrorMessage() }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="margin-right-md" appearance="outline">
    <mat-label>Destination</mat-label>
    <input matInput #destinationText placeholder="Enter drop off" formControlName="destination" required />
    <mat-error *ngIf="destination.touched && destination.invalid">
      {{ getDestinationErrorMessage() }}
    </mat-error>
  </mat-form-field>
  <div class="small-form-fields-container">
    <mat-form-field class="margin-right-md" appearance="outline">
      <mat-label>Pickup Date</mat-label>
      <input
        matInput
        readonly
        [matDatepicker]="pickupDate"
        [min]="minDatePickup"
        (dateChange)="onPickupDateChange($event)"
        formControlName="pickupDate"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="pickupDate"></mat-datepicker-toggle>
      <mat-datepicker touchUi="true" #pickupDate></mat-datepicker>
      <mat-error *ngIf="pickupDate.touched && pickupDate.invalid">
        {{ getPickupDateErrorMessage() }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="margin-right-md" appearance="outline">
      <mat-label>Pickup Time</mat-label>
      <input
        appTimeMask
        maxlength="5"
        matInput
        placeholder=""
        formControlName="pickupTime"
        required
        [errorStateMatcher]="pickupTimeMatcher"
      />
      <mat-error
        *ngIf="
          (createLoadForm.errors && createLoadForm.errors.invalidMaxPickup) ||
          (pickupTime.touched && pickupTime.dirty && pickupTime.invalid)
        "
      >
        {{ getPickupTimeErrorMessage() }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="small-form-fields-container">
    <mat-form-field class="margin-right-md" appearance="outline">
      <mat-label>Delivery Date</mat-label>
      <input
        #fromDeliveryDate
        matInput
        readonly
        [matDatepicker]="deliveryDate"
        [min]="minDateDelivery"
        formControlName="deliveryDate"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="deliveryDate"></mat-datepicker-toggle>
      <mat-datepicker touchUi="true" #deliveryDate></mat-datepicker>
      <mat-error *ngIf="deliveryDate.touched && deliveryDate.invalid">
        {{ getDeliveryDateErrorMessage() }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="margin-right-md" appearance="outline">
      <mat-label>Delivery Time</mat-label>
      <input
        appTimeMask
        maxlength="5"
        matInput
        placeholder=""
        formControlName="deliveryTime"
        required
        [errorStateMatcher]="deliveryTimeMatcher"
      />
      <mat-error
        *ngIf="
          (createLoadForm.errors && createLoadForm.errors.invalidMaxDelivery) ||
          (deliveryTime.touched && deliveryTime.dirty && deliveryTime.invalid)
        "
      >
        {{ getDeliveryTimeErrorMessage() }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="small-form-fields-container">
    <mat-form-field class="margin-right-md" appearance="outline">
      <mat-label>Commodity</mat-label>
      <input matInput placeholder="Describe goods in brief" formControlName="commodity" required />
      <mat-error *ngIf="commodity.touched && commodity.invalid">
        {{ getCommodityErrorMessage() }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="margin-right-md" appearance="outline">
      <mat-label>Order #</mat-label>
      <input matInput placeholder="Order number" formControlName="orderNumber" />
      <mat-error *ngIf="orderNumber.touched && orderNumber.invalid">
        {{ getOrderNumberErrorMessage() }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="small-form-fields-container">
    <mat-form-field class="margin-right-md" appearance="outline">
      <mat-label>Weight in Kgs</mat-label>
      <input matInput min="0" placeholder="Enter weight of goods" formControlName="weightInKgs" required />
      <mat-error *ngIf="weightInKgs.touched && weightInKgs.invalid">
        {{ getWeightInKgsErrorMessage() }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="margin-right-md" appearance="outline">
      <mat-label>Fits in a</mat-label>
      <mat-select formControlName="fitsIn" placeholder="Vehicle body type" required>
        <mat-option>--</mat-option>
        <mat-option *ngFor="let fitsIn of fitsInTypes" [value]="fitsIn.name">
          {{ fitsIn.displayName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="fitsIn.touched && fitsIn.invalid">
        {{ getFitsInErrorMessage() }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="small-form-fields-container">
    <mat-form-field class="margin-right-md" appearance="outline">
      <mat-label>Customer name</mat-label>
      <input matInput placeholder="Customer name" formControlName="customerName" required />
      <mat-error *ngIf="customerName.touched && customerName.invalid">
        {{ getCustomerNameErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="margin-right-md" appearance="outline">
      <mat-label class="padding-left-md">Customer mobile</mat-label>
      <span class="area-code">+1</span>
      <input
        maxlength="12"
        class="customer-mobile"
        appPhoneMask
        matInput
        placeholder="###-###-####"
        formControlName="customerPhone"
        required
      />
      <mat-error *ngIf="customerPhone.touched && customerPhone.invalid">
        {{ getCustomerPhoneErrorMessage() }}
      </mat-error>
    </mat-form-field>
  </div>

  <mat-form-field class="margin-right-md" appearance="outline">
    <mat-label>Comments</mat-label>
    <textarea matInput placeholder="Short description" formControlName="comments"></textarea>
    <mat-error *ngIf="comments.touched && comments.invalid">
      {{ getCommentsErrorMessage() }}
    </mat-error>
  </mat-form-field>
</form>
