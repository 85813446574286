<fleetoperate-delivery-shipper-app-toolbar
  [data]="user"
  [profilePath]="shipperProfilePath"
  (logout)="onLogout()"
></fleetoperate-delivery-shipper-app-toolbar>
<fleetoperate-header
  [title]="title"
  [backAction]="backAction"
  [actions]="actions"
  class="margin-left-md margin-right-md"
>
</fleetoperate-header>
<fleetoperate-error-message
  *ngIf="errorMessage"
  [error]="errorMessage"
  class="margin-left-md margin-bottom-md"
></fleetoperate-error-message>
<div *ngIf="shipment && shipment.status !== 'draft'" class="margin-bottom-md margin-left-md">
  Approved
</div>
<fleetoperate-shipment-summary
  *ngIf="shipment"
  [shipment]="shipment"
  class="padding-left-md padding-right-md"
></fleetoperate-shipment-summary>

<fleetoperate-edit-shipment-details
  [shipment]="shipment"
  (update)="onEditShipmentDetailsUpdate()"
  (valid)="onShipmentDetailsFormValid($event)"
  (dirty)="onShipmentDetailsIsDirty($event)"
></fleetoperate-edit-shipment-details>
<fleetoperate-edit-pickup
  [shipment]="shipment"
  (update)="onEditPickupUpdate()"
  (valid)="onPickupFormValid($event)"
  (dirty)="onPickupIsDirty($event)"
></fleetoperate-edit-pickup>
<fleetoperate-edit-delivery
  [shipment]="shipment"
  (update)="onEditDeliveryUpdate()"
  (valid)="onDeliveryFormValid($event)"
  (dirty)="onDeliveryIsDirty($event)"
  class="margin-bottom-md"
></fleetoperate-edit-delivery>
