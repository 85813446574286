import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export interface ToastData {
  message: string;
  iconType: string;
}

@Component({
  selector: 'fleetoperate-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})

export class ToastComponent implements OnInit {

  constructor(public snackBarRef: MatSnackBarRef<any>, 
              @Inject(MAT_SNACK_BAR_DATA) public data: ToastData ) {}
  
  ngOnInit(){} 
}
