/// <reference types="@types/googlemaps" />
import { Injectable, NgZone } from '@angular/core';
import { GoogleAddressAutocompleteModule } from './google-address-autocomplete.module';
import { FormGroup } from '@angular/forms';

class AddressModel {
  fullAddress: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  streetNumber: string;
  streetName: string;
}


@Injectable({
  providedIn: GoogleAddressAutocompleteModule
})
export class GoogleAddressAutocompleteService {

  constructor(private readonly zone: NgZone,) {

  }

  getPlaceAutocomplete(form: FormGroup, addressText: any, formField: string): void{
    const autocomplete = new google.maps.places.Autocomplete(addressText.nativeElement, {
      componentRestrictions: {
        country: ['US', 'CA']
      },
      types: []
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      autocomplete.setFields(['formatted_address']);
      const place = autocomplete.getPlace();

      if (place) {
        this.zone.run(() => {
          form.patchValue({ [formField]: place.formatted_address });
        });
      }
    });
    return undefined;
  }

  parseAddress(address: string): AddressModel {
    const parsedAddressArr = address.split(',');
    if (address && parsedAddressArr.length === 4) {
      const addressModel: AddressModel = {
        fullAddress: address,
        streetAddress: '',
        city: '',
        state: '',
        zipcode: '',
        streetName: '',
        streetNumber: '',
        country: ''
      };

      addressModel.streetAddress = parsedAddressArr[0].trim();
      addressModel.city = parsedAddressArr[1].trim();
      addressModel.state = parsedAddressArr[2].trim();
      addressModel.zipcode = addressModel.state.substring(3, addressModel.state.length).trim();
      addressModel.state = addressModel.state.split(' ')[0];
      addressModel.country = parsedAddressArr[3].trim();
      return addressModel;
    }
  }

  // Todo: USE THIS FUNCTION INSTEAD OF other parseAddress 
  //NEWparseAddress(address: any): AddressModel {
    
  //   let addressModel : AddressModel = new AddressModel();

 
  //   addressModel.fullAddress = address.formatted_address;

  //   let addressArr = address.address_components

  //   console.log(addressArr);
  //   for (let i = 0; i < addressArr.length; i++) {

  //     if (addressArr[i].types.includes('street_number')) {
  //       console.log("Street Number" ,addressArr[i].long_name)
  //       addressModel.streetNumber = addressArr[i].long_name;
  //     }
  //     else if (addressArr[i].types.includes('route')) {
  //       console.log("route",addressArr[i].long_name)
  //       addressModel.streetName = addressArr[i].long_name;
  //     }
  //     else if (addressArr[i].types.includes('locality') || addressArr[i].types.includes('sublocality_level_1')) {
  //       console.log("city",addressArr[i].long_name)
  //       addressModel.city = addressArr[i].long_name;
  //     }
  //     else if (addressArr[i].types.includes('administrative_area_level_1')) {
  //       console.log(addressArr[i].short_name)
  //       addressModel.state = addressArr[i].short_name;
  //     }
  //     else if (addressArr[i].types.includes('country')) {
  //       console.log(addressArr[i].short_name)
  //       addressModel.country = addressArr[i].short_name;
  //     }
  //     else if (addressArr[i].types.includes('postal_code')) {
  //       console.log(addressArr[i].long_name)
  //       addressModel.zipcode = addressArr[i].long_name;
  //     }
  //   } 

  //   if (addressModel.fullAddress && addressModel.city && addressModel.state && addressModel.zipcode && addressModel.streetNumber && addressModel.streetName) {
  //     addressModel.streetAddress = addressModel.streetNumber + " " + addressModel.streetName;
  //     return addressModel
  //   }

  //   return undefined;
  // }

}

