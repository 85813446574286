import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { UiKitMaterialModule } from '@fleetoperate/ui-kit-material';
import { DeliveryShipperFeatureShellModule } from '@fleetoperate/delivery-shipper/feature-shell';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppState } from './state/app.state';
import { MatIconRegistry } from '@angular/material/icon';
import { CheckForUpdateModule } from '@fleetoperate/shared/util';
import { SharedMessagingDataAccessMessagingModule } from '@fleetoperate/shared/messaging/data-access-messaging';
import { SharedUiEnvironmentRibbonModule } from '@fleetoperate/shared/ui/environment-ribbon';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([AppState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    UiKitMaterialModule,
    SharedUiEnvironmentRibbonModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.serviceWorker }),
    DeliveryShipperFeatureShellModule,
    CheckForUpdateModule,
    SharedMessagingDataAccessMessagingModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: 'apiUrl',
      useValue: environment.apiUrl
    },
    {
      provide: 'appName',
      useValue: 'delivery-shipper'
    },
    {
      provide: 'environmentData',
      useValue: environment
    },
    {
      provide: 'registrationApiUrl',
      useValue: environment.apiUrl + environment.registrationUri
    },
    {
      provide: 'signinSuccessPath',
      useValue: environment.signinSuccessPath
    },
    {
      provide: 'loggingEnabled',
      useValue: environment.loggingEnabled
    },
    {
      provide: 'firebase',
      useValue: environment.firebase
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private readonly iconRegistry: MatIconRegistry, private readonly sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('clear', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/clear-24px.svg'));
    iconRegistry.addSvgIcon(
      'check_circle',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/check_circle-24px.svg')
    );
    iconRegistry.addSvgIcon('info', sanitizer.bypassSecurityTrustResourceUrl('info/img/icons/info-24px.svg'));
    iconRegistry.addSvgIcon('warning', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/warning-24px.svg'));
    iconRegistry.addSvgIcon('error', sanitizer.bypassSecurityTrustResourceUrl('info/img/icons/error-24px.svg'));
  }
}
