import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { ShipmentEventComponent } from './shipment-event/shipment-event.component';
import { ShipmentEventsComponent } from './shipment-events.component';
@NgModule({
  declarations: [ShipmentEventsComponent, ShipmentEventComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatListModule,
    MatCardModule,
    FlexLayoutModule,
    MatDividerModule
  ],
  exports: [ShipmentEventsComponent]
})
export class ShipmentEventsModule { }
