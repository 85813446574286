import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipmentDetailsComponent } from './shipment-details/shipment-details.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedShipperUiShipperModule } from '@fleetoperate/shared/shipper/ui-shipper';
import { ShipmentDocumentsModule } from '../shipment-documents/shipment-documents.module';
import { ShipmentMapModule } from '../shipment-map/shipment-map.module';
import { ShipmentEventsModule } from '../shipment-events/shipment-events.module';
import { ShipmentConfirmationPendingViewsModule } from '../shipment-confirmation-pending-views/shipment-confirmation-pending-views.module';
import { SharedUiHeaderModule } from '@fleetoperate/shared/ui/header';
import { SharedUiErrorMessageModule } from '@fleetoperate/shared/ui/error-message';
import { DeliveryShipperUiAppToolbarModule } from '@fleetoperate/delivery-shipper/ui-app-toolbar';

@NgModule({
  declarations: [ShipmentDetailsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    MatButtonModule,
    SharedShipperUiShipperModule,
    ShipmentDocumentsModule,
    ShipmentMapModule,
    ShipmentEventsModule,
    ShipmentConfirmationPendingViewsModule,
    DeliveryShipperUiAppToolbarModule,
    SharedUiHeaderModule,
    SharedUiErrorMessageModule
  ]
})
export class ShipmentDetailsModule {}

export { ShipmentDetailsComponent } from './shipment-details/shipment-details.component';
