import { Component, OnInit, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { AppAction } from '../models/app-action.model';

@Component({
  selector: 'fleetoperate-fleetoperate-toolbar',
  templateUrl: './fleetoperate-toolbar.component.html',
  styleUrls: ['./fleetoperate-toolbar.component.scss']
})
export class FleetoperateToolbarComponent implements OnInit {
  @Input() data: User;
  @Input() profilePath: string;
  @Input() actions: Array<AppAction>;
  @Output() logout: EventEmitter<any> = new EventEmitter();
  @Output() logo: EventEmitter<any> = new EventEmitter();
  name: string;
  constructor(private readonly router: Router) {}

  onLogout(): void {
    this.logout.emit();
  }

  onLogoClick(): void {
    this.logo.emit();
  }

  ngOnInit() {
    this.name = (this.data && this.data.name) || '';
  }
}
