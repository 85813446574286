import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'fleetoperate-cancel-create-load-dialog',
  templateUrl: './cancel-create-load-dialog.component.html',
  styleUrls: ['./cancel-create-load-dialog.component.scss']
})
export class CancelCreateLoadDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CancelCreateLoadDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() { }

}
