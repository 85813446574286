export class Storage {
  key: string;
  object: Object;


  constructor(key: string, object: Object) {
    this.key = key;
    this.object = object;
  }
}
