import { RegistrationCompanyStateModel } from './registration.state';

export class ClearRegistration {
  static readonly type = '[registration] clear registration';
  constructor() {}
}

export class SetOperateLocation {
    static readonly type = '[registration] set operate location';
    constructor(public operateInUS: boolean, public dotNumber: string) {}
}

export class SetMcDotOperateLocation {
  static readonly type = '[registration] set mc dot operate location';
  constructor(public mcNumber: number, public dotNumber: number) {}
}

export class SetCompany {
    static readonly type = '[registration] set company';
    constructor(public payload: RegistrationCompanyStateModel) {}
}

export class UpdateCompany {
  static readonly type = '[registration] update company';
  constructor(public payload: RegistrationCompanyStateModel) {}
}

export class ClearCompany {
  static readonly type = '[registration] clear company';
  constructor() {}
}
