<fleetoperate-environment-ribbon
  *ngIf="!isProd"
  position="bottom-left"
  [colour]="ribbonColour"
  [text]="ribbonText"
  [hoverText]="ribbonHoverText"
></fleetoperate-environment-ribbon>
<mat-progress-bar
  *ngIf="(isLoading | async)"
  mode="indeterminate"
  color="primary"
  style="position: absolute; top: 0; z-index: 100;"
>
</mat-progress-bar>
<router-outlet></router-outlet>
