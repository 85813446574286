<div fxFlexAlign="center"
     fxLayout="column">
     <div fxLayout fxFlexAlign="center" class="brand-title" *ngIf="!isDeliveryShipper; else showDeliveryLogo">
          <img src="assets/img/FleetOperate-white-logo.png" />
          <div class="fleetoperate-logo-light" fxFlexAlign="center" fxLayout="column">
            <span class="fleetoperate-logo-top"></span>
            <span class="fleetoperate-logo-bottom"></span>
          </div>
     </div>
     <ng-template #showDeliveryLogo>
          <div fxLayout fxFlexAlign="center" class="delivery-brand-title">
            <img  src="assets/img/FleetOperate-delivery-white-logo.png"/>
            <div class="fleetoperate-logo-light" fxFlexAlign="center" fxLayout="column">
               <span class="fleetoperate-logo-top"></span>
               <span class="fleetoperate-logo-bottom"></span>
             </div>
          </div>
        </ng-template>
          <h2 fxFlexAlign="center" class="padding-top-x2">Forgot Password Successful</h2>
          <span fxFlexAlign="center">{{message}}</span>
          <span fxFlexAlign="center" class="padding-top-x4">< <a [routerLink]="" (click)="onNavigateToSignIn()">back to Signin</a></span>
<div>
     


