import { Component, OnInit, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { CompanyModel } from './company-model';
import { RegistrationService } from '../services/registration.service';
import { ActivatedRoute } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';

const RequiredFieldMessage = 'You must enter a value';
const InvalidEmailAddress = 'Invalid email address';
const InvalidCompanyName = 'Please enter a valid company name';
const MaximumLengthFieldMessage = (value: number) => `Must be less than ${value} characters`;
const MinimumLengthFieldMessage = (value: number) => `Must be atleast ${value} characters`;
const ConfirmPasswordErrorMessage = 'Passwords do not match';
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnChanges {
  form: FormGroup;
  hidePassword: boolean;
  hideConfirmPassword: boolean;
  step: number;
  numSteps: number;
  matcher: ErrorStateMatcher;
  backRoute: string;
  forwardRoute: string;

  constructor(
    private readonly route: ActivatedRoute,
    private fb: FormBuilder,
    private registrationService: RegistrationService
  ) {
    this.hidePassword = true;
    this.hideConfirmPassword = true;
    this.form = this.createForm();
    this.matcher = new PasswordsInvalidErrorMatcher();
  }

  ngOnInit() {
    const formModel = this.getFormModel();
    this.populateForm(formModel);
    this.step = this.route.snapshot.data.step;
    this.numSteps = this.route.snapshot.data.numSteps;
    this.backRoute = this.route.snapshot.data.backRoute;
    this.forwardRoute = this.route.snapshot.data.forwardRoute;
  }

  ngOnChanges() {
    this.rebuildForm();
  }

  onNavigateToNextStep() {
    if (!this.form.valid) {
      return;
    }
    const model = this.prepareSaveModel();
    this.registrationService.saveCompany(model, this.forwardRoute);
    this.rebuildForm();
  }

  onNavigateToLogin(): void {
    this.registrationService.navigateToSignIn();
  }

  onNavigateToPreviousStep() {
    this.registrationService.navigateToPreviousStep(this.backRoute);
  }

  get firstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }
  get companyName() {
    return this.form.get('companyName');
  }
  get email() {
    return this.form.get('email');
  }
  get password() {
    return this.form.get('password');
  }
  get confirmPassword() {
    return this.form.get('confirmPassword');
  }

  getEmailErrorMessage(): string {
    const errors = this.email.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? InvalidEmailAddress : '';
  }

  getFirstNameErrorMessage(): string {
    const errors = this.firstName.errors;
    return errors.required
      ? RequiredFieldMessage
      : errors.maxlength
      ? MaximumLengthFieldMessage(errors.maxlength.requiredLength)
      : '';
  }

  getLastNameErrorMessage(): string {
    const errors = this.lastName.errors;
    return errors.required
      ? RequiredFieldMessage
      : errors.maxlength
      ? MaximumLengthFieldMessage(errors.maxlength.requiredLength)
      : '';
  }

  getCompanyNameErrorMessage() {
    const errors = this.companyName.errors;
    return errors.required
      ? RequiredFieldMessage
      : errors.maxlength
      ? MaximumLengthFieldMessage(errors.maxlength.requiredLength)
      : errors.pattern
      ? InvalidCompanyName
      : '';
  }

  getPasswordErrorMessage() {
    const errors = this.password.errors;
    return errors.required
      ? RequiredFieldMessage
      : errors.minlength
      ? MinimumLengthFieldMessage(errors.minlength.requiredLength)
      : '';
  }

  getConfirmPasswordErrorMessage() {
    const errors = this.confirmPassword.errors;

    let message = errors && errors.minlength ? MinimumLengthFieldMessage(errors.minlength.requiredLength) : '';
    message = !message && this.form.hasError('notSame') ? ConfirmPasswordErrorMessage : message;

    return message;
  }

  confirmPasswordHasError(): boolean {
    return (this.confirmPassword.touched && this.confirmPassword.invalid) || this.form.hasError('notSame');
  }

  private createForm(): FormGroup {
    const form = this.fb.group(
      {
        firstName: ['', [Validators.required, Validators.maxLength(20)]],
        lastName: ['', [Validators.required, Validators.maxLength(20)]],
        companyName: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern("^[A-Za-z0-9]+(([-\\s][A-Za-z0-9]+)|('((\\s?[A-Za-z0-9]+)|$)))*$")
          ]
        ],
        email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$')]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.minLength(8)]]
      },
      { validator: this.checkPasswords }
    );

    return form;
  }

  private checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  private populateForm(model: CompanyModel): void {
    this.form.setValue({
      firstName: model.firstName,
      lastName: model.lastName,
      companyName: model.companyName,
      email: model.email,
      password: model.password,
      confirmPassword: model.password
    });
    this.form.markAsDirty();
  }

  private rebuildForm() {
    const model = this.getFormModel();
    this.form.reset({
      firstName: model.firstName,
      lastName: model.lastName,
      companyName: model.companyName,
      email: model.email,
      password: model.password
    });
  }

  private getFormModel(): CompanyModel {
    return this.registrationService.getCompany();
  }

  private prepareSaveModel(): CompanyModel {
    const formModel = this.form.value;
    const titleCaseFirstName = this.convertToTitleCase(formModel.firstName as string);
    const titleCaseLastName = this.convertToTitleCase(formModel.lastName as string);
    const titleCaseCompanyName = this.convertToTitleCase(formModel.companyName as string);
    const lowerCaseEmail = (formModel.email as string).toLowerCase();

    const saveModel = {
      firstName: titleCaseFirstName,
      lastName: titleCaseLastName,
      companyName: titleCaseCompanyName,
      email: lowerCaseEmail,
      password: formModel.password as string
    };

    return saveModel as CompanyModel;
  }

  private convertToTitleCase(str: string): string {
    return str.replace(/\w\S*/g, t => t.charAt(0).toUpperCase() + t.substr(1));
  }
}
class PasswordsInvalidErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return control.parent.errors && control.touched && (invalidCtrl || invalidParent);
  }
}
