import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule, MatIconModule, MatButtonModule, MatInputModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedAuthenticationFeatureAuthenticationRoutingModule } from './shared-authentication-feature-authentication-routing.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignInFormComponent } from './sign-in-form/sign-in-form.component';
import { SharedDataAccessModule } from '@fleetoperate/shared/data-access';
import { LoggingModule, DateTimeModule } from '@fleetoperate/shared/util';
import { SharedAuthenticationAuthenticationModule } from '@fleetoperate/shared/authentication/data-access';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { SharedDriverUiDriverModule } from '@fleetoperate/shared/driver/ui-driver';
import { SharedUiDialogModule } from '@fleetoperate/shared/ui/dialog';
import { ErrorDataModule } from '@fleetoperate/shared/data-access-error';

@NgModule({
  declarations: [SignInComponent, SignInFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    SharedDataAccessModule,
    SharedAuthenticationAuthenticationModule,
    LoggingModule,
    SharedAuthenticationFeatureAuthenticationRoutingModule,
    ForgotPasswordModule,
    SharedUiDialogModule,
    DateTimeModule,
    SharedDriverUiDriverModule,
    ErrorDataModule
  ]
})
export class SharedAuthenticationFeatureAuthenticationModule { }
