import { Component, OnInit, ChangeDetectorRef, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { DocumentType } from '@fleetoperate/shared/documents/data-access-documents';
import { ShipmentDocumentsService } from '../shipment-documents.service';
import { first, tap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ShipmentDocument } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { ShipmentService } from '../../shared/shipment.service';
import * as moment from 'moment-timezone';
import {
  SHIPMENT_STATUS_DELIVERED,
  SHIPMENT_STATUS_COMPLETED,
  SHIPMENT_STATUS_INACTIVE
} from '../../shared/shipment-status';

@Component({
  selector: 'fleetoperate-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit, OnChanges {
  @Input() shipmentId: string;
  @Input() shipmentStatus: string;
  @Output() error: EventEmitter<string> = new EventEmitter();

  errorMessage: string;

  documentDisplayedColumns = ['type', 'created', 'createdBy', 'role', 'action'];
  documentDataSource: ShipmentDocument[] = [];

  documentTypes: DocumentType[];
  documentForm: FormGroup;
  loading: boolean;

  constructor(
    private readonly fb: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly shipmentDocumentsService: ShipmentDocumentsService,
    private readonly shipmentService: ShipmentService
  ) {
    this.documentForm = this.createDocumentForm();
  }

  ngOnInit() {
    this.shipmentDocumentsService.$errorMessage.subscribe((errorMessage: string) => this.error.emit(errorMessage));
    this.shipmentDocumentsService.$documentTypes.subscribe(
      (documentTypes: DocumentType[]) => (this.documentTypes = documentTypes)
    );
    this.shipmentService.$message.subscribe((message: string) => this.error.emit(message));
    this.shipmentService.$shipmentDocuments.subscribe(
      (shipmentDocuments: ShipmentDocument[]) => {
        this.documentDataSource = shipmentDocuments;
        this.updateLoadingState(false);
      },
      () => {
        this.updateLoadingState(false);
      }
    );
    this.shipmentService.$shipmentDocument.subscribe((shipmentDocument: ShipmentDocument) =>
      this.downloadDocument(shipmentDocument)
    );
    this.loadDriverDocumentTypes();
  }

  ngOnChanges(): void {
    if (this.shipmentId) {
      this.loadShipmentDocuments(this.shipmentId);
    }
  }

  formatCreatedDate(date: string) {
    if (!date) return;
    return moment.tz(date, 'America/New_York').format('MMMM DD, YYYY h A z');
  }

  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.documentForm.patchValue({
          file: file,
          filename: file.name
        });
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
        this.uploadDocument();
      };
    }
  }

  onShipmentDocumentDownload(shipmentDocumentId: string): void {
    this.updateLoadingState(true);
    this.shipmentService
      .downloadDocument(this.shipmentId, shipmentDocumentId)
      .pipe(first())
      .subscribe(
        () => {
          this.updateLoadingState(false);
        },
        (error: string) => {
          this.updateLoadingState(false);
        }
      );
  }

  onShipmentDocumentDelete(shipmentDocumentId: string): void {
    this.updateLoadingState(true);
    this.shipmentDocumentsService
      .deleteDocument(this.shipmentId, shipmentDocumentId)
      .pipe(
        first(),
        tap(() => this.loadShipmentDocuments(this.shipmentId))
      )
      .subscribe(
        () => {
          this.updateLoadingState(false);
        },
        (error: string) => {
          this.updateLoadingState(false);
        }
      );
  }

  updateLoadingState(loading: boolean) {
    this.loading = loading;
  }

  get documentFormDocumentType(): FormGroup {
    return this.documentForm.controls.documentType as FormGroup;
  }
  get documentFormFile(): FormControl {
    return this.documentForm.controls.file as FormControl;
  }
  get documentFormFilename(): FormControl {
    return this.documentForm.controls.filename as FormControl;
  }

  loadDocuments() {
    this.loadShipmentDocuments(this.shipmentId);
  }

  private loadDriverDocumentTypes(): void {
    this.updateLoadingState(true);
    this.shipmentDocumentsService
      .loadShipmentDocumentTypes()
      .pipe(first())
      .subscribe(
        (documentTypes: DocumentType[]) => {
          this.updateLoadingState(false);
        },
        (error: string) => {
          this.updateLoadingState(false);
        }
      );
  }

  private loadShipmentDocuments(shipmentId: string): void {
    this.updateLoadingState(true);
    this.shipmentService.loadShipmentDocuments(shipmentId);
  }

  private downloadDocument(shipmentDocument: ShipmentDocument): void {
    if (shipmentDocument && shipmentDocument.fileUrl) {
      window.open(shipmentDocument.fileUrl);
    }
  }

  private createDocumentForm(): FormGroup {
    return this.fb.group({
      documentType: [undefined, Validators.required],
      file: [null],
      filename: [undefined]
    });
  }

  private uploadDocument(): void {
    this.updateLoadingState(true);
    this.errorMessage = undefined;

    const documentType = this.documentFormDocumentType.value;
    const fileName = this.documentFormFilename;
    const extension = fileName.value.split('.')[1].toLowerCase();

    this.shipmentDocumentsService
      .sendDocument(this.shipmentId, this.documentFormFile, documentType, extension)
      .pipe(
        first(),
        tap(() => this.loadShipmentDocuments(this.shipmentId))
      )
      .subscribe(
        () => {
          this.updateLoadingState(false);
        },
        (error: string) => {
          this.updateLoadingState(false);
        }
      );
  }
}
