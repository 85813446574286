import { Component, OnInit, Input } from '@angular/core';
import { Quote } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { CurrencyFormatService } from '@fleetoperate/shared/util';
class Action {
  name: string;
  action: (data: any) => any;
  disabled: boolean;
  color: string;
}

@Component({
  selector: 'fleetoperate-shipment-quote',
  templateUrl: './shipment-quote.component.html',
  styleUrls: ['./shipment-quote.component.scss']
})
export class ShipmentQuoteComponent implements OnInit {
  @Input() quote: Quote;
  @Input() actions: Action[];
  quoteId: string;
  actorName: string;
  actorPicture: string;
  actorLink: string;
  offerCurrencyUnit: string;
  primaryOfferRate: string;
  secondaryOfferRate: string;
  message: string;

  constructor(private readonly currencyFormatService: CurrencyFormatService) {
    this.actions = [];
  }

  ngOnInit(): void {
    if (!this.quote) {
      return;
    }
    this.quoteId = this.quote.id;
    this.actorName = this.quote.actorName;
    this.actorPicture = this.quote.actorPicture;
    // this.actorLink = this.quote.bids[0].actorLink;
    // this.offerCurrencyUnit = this.quote.bids[0].currencyUnit;
    this.message = this.quote.message;

    if (this.quote && this.quote.bids && this.quote.bids.length > 0 && this.quote.bids[0] && this.quote.primaryOffer) {
      this.primaryOfferRate = this.formatCurrency(this.quote.primaryOffer);
      this.secondaryOfferRate = this.formatCurrency(this.quote.secondaryOffer);
    }
  }
  applyDefaultProfileIcon(): void {
    this.actorPicture = undefined;
  }

  private formatCurrency(offerRate: number): string {
    return this.currencyFormatService.convertToCurrency(offerRate);
  }
}
