import { Component, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Shipper, ShipperDataService } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { first } from 'rxjs/internal/operators/first';
import { ShipperService } from '../../shared/shipper.service';
import { ToastService } from '@fleetoperate/shared/util';

const RequiredFieldMessage = 'Required';

@Component({
  selector: 'fleetoperate-profile-company-info',
  templateUrl: './profile-company-info.component.html',
  styleUrls: ['./profile-company-info.component.scss']
})
export class ProfileCompanyInfoComponent implements OnInit, OnChanges {
  shipper: Shipper;

  companyInfoForm: FormGroup;
  companyInfoFormMessage: string;
  companyInfoReadOnly: boolean;

  loading: boolean;

  constructor(
    private readonly fb: FormBuilder,
    private readonly shipperService: ShipperService,
    private readonly toastService: ToastService
  ) {
    this.companyInfoFormMessage = undefined;
    this.companyInfoReadOnly = false;
    this.loading = false;
  }

  ngOnInit(): void {
    this.companyInfoForm = this.createCompanyInfoForm(this.shipper);
    this.shipperService.shipper$.subscribe((shipper: Shipper) => {
      this.shipper = shipper;
      this.resetCompanyInfoForm(this.shipper, undefined);
    });
  }

  ngOnChanges(): void {
    if (this.shipper) {
      this.resetCompanyInfoForm(this.shipper, undefined);
    }
  }

  onCompanyInfoFormSubmit(companyInfoFormRef: NgForm): void {
    if (!companyInfoFormRef.valid) {
      return;
    }

    this.loading = true;

    this.companyInfoReadOnly = true;
    const newShipper = this.prepareCompanyInfoSaveModel(this.shipper, this.companyInfoForm);

    this.shipperService
      .updateShipper(newShipper)
      .pipe(first())
      .subscribe(
        (shipper: Shipper) => {
          this.companyInfoFormMessage = undefined;
          this.toastService.showSuccess('Successfully updated company information');
          this.loading = false;
          this.companyInfoReadOnly = false;
        },
        (error: any) => {
          this.companyInfoFormMessage = error.message;
          this.loading = false;
        }
      );
  }

  onCompanyInfoFormCancel(companyInfoFormRef: NgForm): void {
    this.resetCompanyInfoForm(this.shipper, companyInfoFormRef);
  }

  get companyName() {
    return this.companyInfoForm.get('companyName');
  }
  get city() {
    return this.companyInfoForm.get('city');
  }
  get zipCode() {
    return this.companyInfoForm.get('zipCode');
  }
  get state() {
    return this.companyInfoForm.get('state');
  }
  get address() {
    return this.companyInfoForm.get('address');
  }

  getCompanyNameErrorMessage(): string {
    const errors = this.companyName.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getCityErrorMessage(): string {
    const errors = this.city.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getZipCodeErrorMessage(): string {
    const errors = this.zipCode.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getStateErrorMessage(): string {
    const errors = this.state.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getAddressErrorMessage(): string {
    const errors = this.address.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  private createCompanyInfoForm(shipper?: Shipper): FormGroup {
    const companyInfoFormModel = {
      companyName: shipper && shipper.companyName ? shipper.companyName : '',
      city: shipper && shipper.city ? shipper.city : '',
      zipCode: shipper && shipper.zipCode ? shipper.zipCode : '',
      state: shipper && shipper.state ? shipper.state : '',
      address: shipper && shipper.address ? shipper.address : ''
    };

    const form = this.fb.group({
      companyName: [{ value: companyInfoFormModel.companyName, disabled: true }, Validators.required],
      city: [companyInfoFormModel.city, Validators.required],
      zipCode: [companyInfoFormModel.zipCode, Validators.required],
      state: [companyInfoFormModel.state, Validators.required],
      address: [companyInfoFormModel.address, Validators.required]
    });

    return form;
  }

  private resetCompanyInfoForm(shipper: Shipper, companyInfoFormRef: NgForm): void {
    if (companyInfoFormRef) {
      companyInfoFormRef.resetForm();
    }
    const companyInfoFormModel = {
      companyName: shipper && shipper.companyName ? shipper.companyName : '',
      city: shipper && shipper.city ? shipper.city : '',
      zipCode: shipper && shipper.zipCode ? shipper.zipCode : '',
      state: shipper && shipper.state ? shipper.state : '',
      address: shipper && shipper.address ? shipper.address : ''
    };
    this.companyInfoForm.reset(companyInfoFormModel);
  }

  private prepareCompanyInfoSaveModel(shipper: Shipper, companyInfoForm: FormGroup): Shipper {
    const formModel = companyInfoForm.value;
    const saveModel = Object.assign({}, shipper);

    saveModel.companyName = shipper.companyName as string;
    saveModel.city = formModel.city as string;
    saveModel.zipCode = formModel.zipCode as string;
    saveModel.state = formModel.state as string;
    saveModel.address = formModel.address as string;

    return saveModel;
  }
}
