import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShipperDataService } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ROUTE_SHIPMENT_SEARCH } from '@fleetoperate/delivery-shipper/feature-shipment';
import { ROUTE_SIGNIN } from '@fleetoperate/shared/authentication/feature-authentication';
import { LoggerService } from '@fleetoperate/shared/util';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly shipperDataService: ShipperDataService,
    private readonly logger: LoggerService
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.shipperDataService.loadShipper().subscribe(
        () => {
          this.navigateToShipmentSearch();
        },
        (error: any) => {
          this.logger.error(`Unable to load shipper. Sending user to sign in.`);
          // this.navigateToSignIn();
          this.navigateToShipmentSearch();
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private navigateToShipmentSearch(): void {
    this.router.navigate([ROUTE_SHIPMENT_SEARCH]);
  }

  private navigateToSignIn(): void {
    this.router.navigate([ROUTE_SIGNIN]);
  }
}
