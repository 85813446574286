import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryShipperFeatureProfileRoutingModule } from './delivery-shipper-feature-profile-routing.module';
import { ShipperProfileModule } from './shipper-profile/shipper-profile.module';

@NgModule({
  imports: [CommonModule, DeliveryShipperFeatureProfileRoutingModule, ShipperProfileModule],
  declarations: []
})
export class DeliveryShipperFeatureProfileModule {}
