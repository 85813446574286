import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { State, Action, StateContext } from '@ngxs/store';

export class Navigate {
    static readonly type = '[registrationRouter] navigate';
    constructor(public payload: string) {}
}

@State<string>({
    name: 'registrationRouter',
    defaults: ''
})
export class RegistrationRouterState {
  constructor(private readonly ngZone: NgZone, 
              private readonly router: Router) {}

  @Action(Navigate)
  async changeRoute(context: StateContext<string>, action: Navigate) {
      const path = action.payload;
      await this.ngZone.run(() => this.router.navigate([path])).then();
      context.setState( path );
  }
}
