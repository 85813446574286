<h1 fxLayoutAlign="left" mat-dialog-title class="title">{{ data.title }}</h1>
<div fxLayoutAlign="left" mat-dialog-content class="margin-bottom-lg" *ngIf="data.message; else showHtmlContent">
  {{ data.message }}
</div>
<ng-template #showHtmlContent>
  <div fxLayoutAlign="left" mat-dialog-content [innerHtml]="htmlContent"></div>
</ng-template>
<div fxFlex cdkFocusInitial mat-dialog-actions align="end">
  <button
    *ngFor="let action of data.actions"
    mat-flat-button
    [disabled]="action.disabled"
    [color]="action.color"
    (click)="action.action()"
  >
    {{ action.name }}
  </button>
</div>
