import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { SignInModel } from '../shared/sign-in-model';
import { AuthenticationService } from '../shared/authentication.service';
import { RegistrationService } from '@fleetoperate/shared/registration/feature-registration';
import { ErrorDataService, Error } from '@fleetoperate/shared/data-access-error';
import { DateTimeService } from '@fleetoperate/shared/util';
import { ROUTE_FORGOT_PASSWORD } from '../forgot-password/shared/routes';

const RequiredFieldMessage = 'You must enter a value';
const InvalidEmailAddress = 'Invalid email address';
const MinimumLengthFieldMessage = (value: number) => `Must be atleast ${value} characters`;
const ACTION_SIGN_IN = 'Sign in';

@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss']
})
export class SignInFormComponent implements OnInit {
  form: FormGroup;
  hidePassword = true;
  loading: boolean;
  errorMessage: string;
  allowSignUp: boolean;

  private subs = new SubSink();

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly authenticationApi: AuthenticationService,
    private readonly registrationService: RegistrationService,
    private readonly errorDataService: ErrorDataService,
    private readonly dateTimeService: DateTimeService,
    @Inject('signinSuccessPath') private signinSuccessPath: string,
    @Inject('registrationApiUrl') private registrationApiUrl: any
  ) {
    this.form = this.createForm();
    this.allowSignUp = !!this.registrationApiUrl;
  }

  ngOnInit() {}

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const model = this.prepareSaveModel();

    this.subs.add(
      this.authenticationApi
        .login(model)
        .pipe(
          catchError((error: any) => {
            this.errorMessage = error.message;
            this.loading = false;

            let errorInformation = {
              affectedUser: model.username,
              attemptedAction: ACTION_SIGN_IN,
              capturedTime: this.dateTimeService.getUTCDateFormatted(),
              error: error
            } as Error;
            return this.errorDataService.createError(errorInformation);
          })
        )
        .subscribe((signIn: SignInModel) => {
          this.errorMessage = undefined;
          this.form.reset();
          this.loading = false;

          this.navigateToDriverSearch();
        })
    );
  }

  onNavigateToSignup() {
    this.registrationService.navigateToSignUp();
  }

  get email() {
    return this.form.get('email');
  }
  get password() {
    return this.form.get('password');
  }

  getEmailErrorMessage() {
    const errors = this.email.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? InvalidEmailAddress : '';
  }

  getPasswordErrorMessage() {
    const errors = this.password.errors;
    return errors.required
      ? RequiredFieldMessage
      : errors.minlength
      ? MinimumLengthFieldMessage(errors.minlength.requiredLength)
      : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });

    return form;
  }

  private prepareSaveModel(): SignInModel {
    const formModel = this.form.value;

    const model = {
      username: formModel.email as string,
      password: formModel.password as string
    };

    return model as SignInModel;
  }

  private navigateToDriverSearch(): void {
    this.router.navigate([this.signinSuccessPath]);
  }

  onNavigateToForgotPassword(): void {
    this.router.navigate([`/${ROUTE_FORGOT_PASSWORD}`]);
  }
}
