import { Injectable } from '@angular/core';
import {
  Shipment,
  Quote,
  CarrierType,
  DriverType,
  Action,
  Event,
  ShipmentDataService,
  Bid,
  SHIPMENT_STATUS_CONFIRMATION_PENDING,
  ShipmentDocument
} from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { LoggerService, ToastService } from '@fleetoperate/shared/util';
import { first, finalize, catchError, map } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import {
  QUOTE_STATUS_ACCEPTED,
  QUOTE_STATUS_OWNER_ACCEPTED,
  QUOTE_STATUS_DECLINED_DISABLED,
  QUOTE_STATUS_UNDO_DECLINED_DISABLED,
  QUOTE_STATUS_CONFIRMED,
  QUOTE_STATUS_NOT_ACCEPTED,
  QUOTE_STATUS_CANCELLED,
  QUOTE_STATUS_OWNER_COUNTERED,
  QUOTE_STATUS_COUNTERED,
  QUOTE_STATUS_PASSED,
  QUOTE_STATUS_OWNER_DECLINED,
  QUOTE_STATUS_DRIVER_ACCEPTED
} from './quote.status';
import { SHIPMENT_STATUS_UNSECURED, SHIPMENT_STATUS_BOOKED, SHIPMENT_STATUS_INACTIVE } from './shipment-status';
import { Router } from '@angular/router';
import { ROUTE_SHIPMENT_DETAILS } from './routes';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CounterOfferQuoteDialogComponent } from './counter-offer-quote-dialog/counter-offer-quote-dialog.component';
import { AcceptQuoteConfirmationDialogComponent } from './accept-quote-confirmation-dialog/accept-quote-confirmation-dialog.component';

export class QuotesModel {
  driverQuotes: Quote[];
  carrierQuotes: Quote[];
}

const MESSAGE_ERROR_LOADING_SHIPMENT = 'Error loading shipment. Please try again.';
const MESSAGE_ERROR_LOADING_SHIPMENT_DOCUMENTS = 'Error loading shipment documents. Please try again.';
const ERROR_COMPLETING_SHIPMENT_MESSAGE = 'Error completing shipment. Please try again.';
const ERROR_DOWNLOAD_DOCUMENT_MESSAGE = 'Error downloading document. Please try again.';
const ERROR_MESSAGE = 'There was an error. Please try again.';

@Injectable()
export class ShipmentService {
  $message: Observable<string>;
  $shipment: Observable<Shipment>;
  $shipmentDocuments: Observable<ShipmentDocument[]>;
  $shipmentDocument: Observable<ShipmentDocument>;
  $quotes: Observable<QuotesModel>;

  private messageSubject: Subject<string>;
  private shipmentSubject: Subject<Shipment>;
  private shipmentDocumentsSubject: Subject<ShipmentDocument[]>;
  private shipmentDocumentSubject: Subject<ShipmentDocument>;
  private quotesSubject: Subject<QuotesModel>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly shipmentDataService: ShipmentDataService,
    private readonly toastService: ToastService
  ) {
    this.messageSubject = new Subject<string>();
    this.$message = this.messageSubject.asObservable();

    this.shipmentSubject = new Subject<Shipment>();
    this.$shipment = this.shipmentSubject.asObservable();

    this.shipmentDocumentsSubject = new Subject<ShipmentDocument[]>();
    this.$shipmentDocuments = this.shipmentDocumentsSubject.asObservable();

    this.shipmentDocumentSubject = new Subject<ShipmentDocument>();
    this.$shipmentDocument = this.shipmentDocumentSubject.asObservable();

    this.quotesSubject = new Subject<QuotesModel>();
    this.$quotes = this.quotesSubject.asObservable();
  }

  loadShipment(id: string): void {
    this.fetchShipment(id);
  }

  loadEvents(): Event[] {
    return this.addMockEventsToShipment();
  }

  loadShipmentDocuments(shipmentId: string): void {
    this.shipmentDataService
      .getShipmentDocuments(shipmentId)
      .pipe(first())
      .subscribe(
        (shipmentDocuments: ShipmentDocument[]) => {
          this.shipmentDocumentsSubject.next(shipmentDocuments);
        },
        (error: any) => {
          this.updateMessage(MESSAGE_ERROR_LOADING_SHIPMENT_DOCUMENTS);
        }
      );
    // this.shipmentDocumentsSubject.next(this.createMockShipmentDocuments()); // TODO: Remove mock data when API is complete
  }

  downloadDocument(shipmentId, documentId: string): Observable<any> {
    this.updateMessage(undefined);
    // this.shipmentDocumentSubject.next(this.createMockShipmentDocument()); // TODO: Remove mock data when API is complete
    return this.shipmentDataService.getShipmentDocument(shipmentId, documentId).pipe(
      map((shipmentDocument: ShipmentDocument) => {
        this.shipmentDocumentSubject.next(shipmentDocument);
        this.updateMessage(undefined);
        this.toastService.showSuccess('Sucessfully downloaded document');
        return of();
      }),
      catchError((error: string) => {
        this.updateMessage(ERROR_DOWNLOAD_DOCUMENT_MESSAGE);
        throw error;
      })
    );
  }

  completeShipment(shipmentId: string): Observable<any> {
    return this.shipmentDataService.completeShipment(shipmentId).pipe(
      map((shipment: Shipment) => {
        this.fetchShipment(shipmentId);
        this.toastService.showSuccess('Sucessfully completed shipment');
      }),
      catchError((error: string) => {
        this.updateMessage(ERROR_COMPLETING_SHIPMENT_MESSAGE);
        throw error;
      })
    );
  }

  private updateMessage(message: string): void {
    this.messageSubject.next(message || null);
  }

  private fetchShipment(shipmentId: string): void {
    this.shipmentDataService
      .getShipment(shipmentId)
      .pipe(first())
      .subscribe(
        (shipment: Shipment) => {
          let quotes = {
            driverQuotes: [],
            carrierQuotes: []
          } as QuotesModel;

          if (shipment) {
            this.shipmentSubject.next(shipment);
          }

          if (this.validShipment(shipment)) {
            quotes = this.determineQuotes(shipment);
          }

          this.quotesSubject.next(quotes);
          this.updateMessage(null);
        },
        (error: any) => {
          this.updateMessage(MESSAGE_ERROR_LOADING_SHIPMENT);
        }
      );
  }

  private validShipment(shipment: Shipment): boolean {
    return shipment && shipment.status && shipment.quotes && shipment.quotes.length && shipment.quotes.length > 0;
  }

  private determineQuotes(shipment: Shipment): QuotesModel {
    if (!shipment || !shipment.status || !shipment.quotes || !shipment.quotes.length || shipment.quotes.length <= 0) {
      return;
    }

    const quotesModel = {
      driverQuotes: [],
      carrierQuotes: []
    } as QuotesModel;

    // Stub data for testing scenarios
    //shipment.status = SHIPMENT_STATUS_UNSECURED;
    //shipment.quotes = [];
    //this.addMockQuote(shipment, CarrierType, ShipperType, undefined);
    //this.addMockQuote(shipment, CarrierType, ShipperType, undefined);
    // this.addMockQuote(shipment, DriverType, ShipperType, undefined);
    // this.addMockQuote(shipment, DriverType, DriverType, QUOTE_STATUS_ACCEPTED);
    // this.addMockQuote(shipment, DriverType, ShipperType, QUOTE_STATUS_DRIVER_ACCEPTED);
    // this.addMockQuote(shipment, DriverType, ShipperType, QUOTE_STATUS_OWNER_ACCEPTED);
    // this.addMockQuote(shipment, DriverType, ShipperType, QUOTE_STATUS_CANCELLED);
    // this.addMockQuote(shipment, DriverType, ShipperType, QUOTE_STATUS_CONFIRMED);
    // this.addMockQuote(shipment, DriverType, ShipperType, QUOTE_STATUS_COUNTERED);
    // this.addMockQuote(shipment, DriverType, ShipperType, QUOTE_STATUS_DECLINED_DISABLED);
    // this.addMockQuote(shipment, DriverType, ShipperType, QUOTE_STATUS_UNDO_DECLINED_DISABLED);
    // this.addMockQuote(shipment, DriverType, ShipperType, QUOTE_STATUS_NOT_ACCEPTED);
    // this.addMockQuote(shipment, DriverType, ShipperType, QUOTE_STATUS_OWNER_COUNTERED);
    // this.addMockQuote(shipment, DriverType, ShipperType, QUOTE_STATUS_OWNER_DECLINED);
    // this.addMockQuote(shipment, DriverType, ShipperType, QUOTE_STATUS_PASSED);

    shipment.quotes
      .filter((quote: Quote) => quote)
      .forEach((quote: Quote) => {
        const foundQuote = this.setupQuote(quote, shipment.status, quote.status);

        if (foundQuote && foundQuote.actor && foundQuote.actor.toLowerCase() === CarrierType) {
          quotesModel.carrierQuotes.push(foundQuote);
        } else if (foundQuote && foundQuote.actor && foundQuote.actor.toLowerCase() === DriverType) {
          quotesModel.driverQuotes.push(foundQuote);
        }
      });
    return quotesModel;
  }

  private setupQuote(quote: Quote, shipmentStatus: string, quoteStatus: string): Quote {
    let newQuote = Object.assign({}, quote);

    if (QUOTE_STATUS_ACCEPTED === quoteStatus && SHIPMENT_STATUS_UNSECURED === shipmentStatus) {
      const actions: Action[] = [];
      actions.push(this.createDeclineAction(quote));
      actions.push(this.createAcceptAction(quote));
      actions.push(this.createCounterAction(quote, false));
      newQuote.actions = actions;
      newQuote.message = undefined;
      newQuote.primaryOffer = quote.primaryOffer;
    }

    if (QUOTE_STATUS_OWNER_ACCEPTED === quoteStatus && SHIPMENT_STATUS_CONFIRMATION_PENDING === shipmentStatus) {
      const actions: Action[] = [];
      actions.push(this.createDeclineAction(quote));
      newQuote.actions = actions;
      newQuote.message = 'Driver confirmation pending';
      newQuote.primaryOffer = quote.primaryOffer;
    }

    if (QUOTE_STATUS_DECLINED_DISABLED === quoteStatus) {
      const actions: Action[] = [];
      actions.push(this.createDeclineAction(quote));
      actions.push(this.createAcceptAction(quote, false));
      actions.push(this.createCounterAction(quote, false));
      newQuote.actions = actions;
      newQuote.message = undefined;
      newQuote.primaryOffer = quote.primaryOffer;
    }

    if (QUOTE_STATUS_UNDO_DECLINED_DISABLED === quoteStatus) {
      const actions: Action[] = [];
      actions.push(this.createUndoDeclineAction(quote));
      actions.push(this.createAcceptAction(quote, false));
      actions.push(this.createCounterAction(quote, false));
      newQuote.actions = actions;
      newQuote.message = undefined;
      newQuote.primaryOffer = quote.primaryOffer;
    }

    if (QUOTE_STATUS_CONFIRMED === quoteStatus && SHIPMENT_STATUS_BOOKED === shipmentStatus) {
      newQuote.actions = undefined;
      newQuote.message = undefined;
      newQuote.primaryOffer = quote.primaryOffer;
    }

    if (QUOTE_STATUS_NOT_ACCEPTED === quoteStatus) {
      newQuote.actions = undefined;
      newQuote.message = undefined;
      newQuote.primaryOffer = quote.primaryOffer;
    }

    if (QUOTE_STATUS_CANCELLED === quoteStatus && SHIPMENT_STATUS_UNSECURED === shipmentStatus) {
      newQuote.actions = undefined;
      newQuote.message = 'Cancelled';
      newQuote.primaryOffer = quote.primaryOffer;
    }

    if (QUOTE_STATUS_OWNER_COUNTERED === quoteStatus && SHIPMENT_STATUS_UNSECURED === shipmentStatus) {
      const actions: Action[] = [];
      actions.push(this.createDeclineAction(quote));
      actions.push(this.createAcceptAction(quote, false));
      actions.push(this.createCounterAction(quote, false));
      newQuote.actions = actions;
      newQuote.message = 'Countered';
      newQuote.primaryOffer = quote.primaryOffer;
    }

    if (QUOTE_STATUS_COUNTERED === quoteStatus && SHIPMENT_STATUS_UNSECURED === shipmentStatus) {
      const actions: Action[] = [];
      actions.push(this.createDeclineAction(quote));
      actions.push(this.createAcceptAction(quote));
      actions.push(this.createCounterAction(quote));
      newQuote.actions = actions;
      newQuote.message = undefined;
      newQuote.primaryOffer = quote.primaryOffer;
    }

    if (QUOTE_STATUS_PASSED === quoteStatus && SHIPMENT_STATUS_UNSECURED === shipmentStatus) {
      newQuote = undefined;
    }

    if (QUOTE_STATUS_OWNER_DECLINED === quoteStatus && SHIPMENT_STATUS_UNSECURED === shipmentStatus) {
      const actions: Action[] = [];
      actions.push(this.createUndoDeclineAction(quote));
      actions.push(this.createAcceptAction(quote, false));
      actions.push(this.createCounterAction(quote, false));
      newQuote.actions = actions;
      newQuote.message = 'Declined';
      newQuote.primaryOffer = quote.primaryOffer;
    }

    if (QUOTE_STATUS_DRIVER_ACCEPTED === quoteStatus && SHIPMENT_STATUS_UNSECURED === shipmentStatus) {
      const actions: Action[] = [];
      actions.push(this.createDeclineAction(quote));
      actions.push(this.createAcceptAction(quote, false));
      actions.push(this.createCounterAction(quote, false));
      newQuote.actions = actions;
      newQuote.message = undefined;
      newQuote.primaryOffer = quote.primaryOffer;
    }

    if (QUOTE_STATUS_OWNER_DECLINED === quoteStatus && SHIPMENT_STATUS_INACTIVE === shipmentStatus) {
      const actions: Action[] = [];
      actions.push(this.createUndoDeclineAction(quote, false));
      actions.push(this.createAcceptAction(quote, false));
      actions.push(this.createCounterAction(quote, false));
      newQuote.actions = actions;
      newQuote.message = 'Declined';
      newQuote.primaryOffer = quote.primaryOffer;
    }

    if (QUOTE_STATUS_OWNER_DECLINED !== quoteStatus && SHIPMENT_STATUS_INACTIVE === shipmentStatus) {
      const actions: Action[] = [];
      actions.push(this.createDeclineAction(quote, false));
      actions.push(this.createAcceptAction(quote, false));
      actions.push(this.createCounterAction(quote, false));
      newQuote.actions = actions;
      newQuote.message = undefined;
      newQuote.primaryOffer = quote.primaryOffer;
    }

    return newQuote;
  }

  private createAcceptAction(quote: Quote, enabled: boolean = true): Action {
    return {
      name: 'Accept Offer',
      action: (data: any) => this.onAccept(quote),
      disabled: !enabled,
      color: 'accept'
    } as Action;
  }

  private createDeclineAction(quote: Quote, enabled: boolean = true): Action {
    return {
      name: 'Decline Offer',
      action: (data: any) => this.onDecline(quote),
      disabled: !enabled,
      color: 'decline'
    } as Action;
  }

  private createUndoDeclineAction(quote: Quote, enabled: boolean = true): Action {
    return {
      name: 'Undo Decline',
      action: (data: any) => this.onUndoDecline(quote),
      disabled: !enabled,
      color: 'undecline'
    } as Action;
  }

  private createCounterAction(quote: Quote, enabled: boolean = true): Action {
    return {
      name: 'Counter Offer',
      action: (data: any) => this.onCounter(quote),
      disabled: !enabled,
      color: 'counter'
    } as Action;
  }

  private onAccept(quote: Quote): void {
    if (!quote || !quote.shipmentID || !quote.bids || quote.bids.length <= 0 || !quote.bids[0] || !quote.primaryOffer) {
      this.logger.error(`No quote or bid offer to accept for accept quote event with quote id: ${quote.id}.`);
    }
    const actions = this.createAcceptAndDeclineDialogActions(quote);
    const dialogRef = this.dialog.open(AcceptQuoteConfirmationDialogComponent, {
      width: '40%',
      height: '60%',
      data: {
        actorName: quote.actorName,
        actorPicture: quote.actorPicture,
        primaryOfferRate: quote.primaryOffer,
        isAcceptedDialog: true,
        actions
      },
      disableClose: true
    });
    const confirmAction = actions[0];
    const cancelAction = actions[1];
    confirmAction.action = () => this.acceptConfirmAction(dialogRef, quote, confirmAction, cancelAction);
    cancelAction.action = () => this.dialogCancelAction(dialogRef, confirmAction, cancelAction);

    dialogRef.afterClosed().subscribe();
  }

  private onDecline(quote: Quote): void {
    if (!quote || !quote.shipmentID || !quote.bids || quote.bids.length <= 0 || !quote.bids[0] || !quote.primaryOffer) {
      this.logger.error(`No quote or bid offer to decline for decline quote event with quote id: ${quote.id}.`);
    }

    const actions = this.createAcceptAndDeclineDialogActions(quote);
    const dialogRef = this.dialog.open(AcceptQuoteConfirmationDialogComponent, {
      width: '40%',
      height: '60%',
      data: {
        actorName: quote.actorName,
        actorPicture: quote.actorPicture,
        primaryOfferRate: quote.primaryOffer,
        isAcceptedDialog: false,
        actions
      },
      disableClose: true
    });
    const confirmAction = actions[0];
    const cancelAction = actions[1];
    confirmAction.action = () => this.declineConfirmAction(dialogRef, quote, confirmAction, cancelAction);
    cancelAction.action = () => this.dialogCancelAction(dialogRef, confirmAction, cancelAction);

    dialogRef.afterClosed().subscribe();
  }

  private onUndoDecline(quote: Quote): void {
    if (!quote || !quote.shipmentID || !quote.bids || quote.bids.length <= 0 || !quote.bids[0] || !quote.primaryOffer) {
      this.logger.error(
        `No quote or bid offer to undo decline for undo decline quote event with quote id: ${quote.id}.`
      );
    }
    const actions = this.createAcceptAndDeclineDialogActions(quote);
    const dialogRef = this.dialog.open(AcceptQuoteConfirmationDialogComponent, {
      width: '40%',
      height: '60%',
      data: { actorName: quote.actorName, primaryOfferRate: quote.primaryOffer, isAcceptedDialog: false, actions },
      disableClose: true
    });
    const confirmAction = actions[0];
    const cancelAction = actions[1];
    confirmAction.action = () => this.undoDeclineConfirmAction(dialogRef, quote, confirmAction, cancelAction);
    cancelAction.action = () => this.dialogCancelAction(dialogRef, confirmAction, cancelAction);

    dialogRef.afterClosed().subscribe();
  }

  private onCounter(quote: Quote): void {
    if (!quote || !quote.shipmentID || !quote.bids || quote.bids.length <= 0 || !quote.bids[0] || !quote.primaryOffer) {
      this.logger.error(`No quote or bid offer to counter for counter quote event with quote id: ${quote.id}.`);
    }

    const actions = this.createCounterDialogActions(quote);
    const dialogRef = this.dialog.open(CounterOfferQuoteDialogComponent, {
      width: '40%',
      height: '60%',
      data: {
        actorName: quote.actorName,
        actorPicture: quote.actorPicture,
        primaryOfferRate: quote.primaryOffer,
        confirmAndCancelActions: actions
      },
      disableClose: true
    });
    const confirmAction = actions[0];
    const cancelAction = actions[1];
    confirmAction.action = (counterOfferRate: number) =>
      this.counterConfirmAction(dialogRef, quote, confirmAction, cancelAction, counterOfferRate);
    cancelAction.action = () => this.dialogCancelAction(dialogRef, confirmAction, cancelAction);

    dialogRef.afterClosed().subscribe();
  }

  private createAcceptAndDeclineDialogActions(quote: Quote): Action[] {
    const actions: Action[] = [];

    const confirmAction = {
      name: 'Confirm',
      action: (data: any) => {},
      disabled: false,
      color: 'confirm'
    } as Action;
    actions.push(confirmAction);

    const cancelAction = {
      name: 'Cancel',
      action: (data: any) => {},
      disabled: false,
      color: 'cancel'
    } as Action;
    actions.push(cancelAction);

    return actions;
  }

  private createCounterDialogActions(quote: Quote): Action[] {
    const actions: Action[] = [];

    const confirmAction = {
      name: 'Confirm',
      action: (counterOfferRate: string) => {},
      disabled: false,
      color: 'confirm'
    } as Action;
    actions.push(confirmAction);

    const cancelAction = {
      name: 'Cancel',
      action: (data: any) => {},
      disabled: false,
      color: 'cancel'
    } as Action;
    actions.push(cancelAction);

    return actions;
  }

  private acceptConfirmAction(
    dialogRef: MatDialogRef<AcceptQuoteConfirmationDialogComponent>,
    quote: Quote,
    confirmAction: Action,
    cancelAction: Action
  ): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;
    const offer = quote.primaryOffer;

    this.acceptQuote(quote.shipmentID, quote.id, offer)
      .pipe(
        first(),
        finalize(() => {
          dialogRef.close();
          confirmAction.disabled = false;
          cancelAction.disabled = false;
        })
      )
      .subscribe();
  }

  private declineConfirmAction(
    dialogRef: MatDialogRef<AcceptQuoteConfirmationDialogComponent>,
    quote: Quote,
    confirmAction: Action,
    cancelAction: Action
  ): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;

    this.declineQuote(quote.shipmentID, quote.id)
      .pipe(
        first(),
        finalize(() => {
          dialogRef.close();
          confirmAction.disabled = false;
          cancelAction.disabled = false;
        })
      )
      .subscribe();
  }

  private undoDeclineConfirmAction(
    dialogRef: MatDialogRef<AcceptQuoteConfirmationDialogComponent>,
    quote: Quote,
    confirmAction: Action,
    cancelAction: Action
  ): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;

    this.undoDeclineQuote(quote.shipmentID, quote.id)
      .pipe(
        first(),
        finalize(() => {
          dialogRef.close();
          confirmAction.disabled = false;
          cancelAction.disabled = false;
        })
      )
      .subscribe();
  }

  private counterConfirmAction(
    dialogRef: MatDialogRef<CounterOfferQuoteDialogComponent, any>,
    quote: Quote,
    confirmAction: Action,
    cancelAction: Action,
    counterOfferRate: number
  ): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;

    this.counterQuote(quote.shipmentID, quote.id, counterOfferRate)
      .pipe(
        first(),
        finalize(() => {
          dialogRef.close();
          confirmAction.disabled = false;
          cancelAction.disabled = false;
        })
      )
      .subscribe();
  }

  private dialogCancelAction(dialogRef: MatDialogRef<any>, confirmAction: Action, cancelAction: Action): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;

    dialogRef.close();
  }

  private acceptQuote(shipmentId: string, quoteId: string, offer: number): Observable<Bid> {
    return this.shipmentDataService.acceptBid(quoteId, offer).pipe(
      map(() => {
        this.updateMessage(null);
        this.toastService.showSuccess('Sucessfully accepted quote');
        this.navigateToShipmentDetails(shipmentId);
      }),
      catchError((error: any) => {
        this.updateMessage(ERROR_MESSAGE);
        return of(undefined);
      })
    );
  }

  private declineQuote(shipmentId: string, quoteId: string): Observable<Bid> {
    return this.shipmentDataService.declineBid(quoteId).pipe(
      map(() => {
        this.updateMessage(null);
        this.toastService.showSuccess('Sucessfully declined quote');
        this.fetchShipment(shipmentId);
      }),
      catchError((error: any) => {
        this.updateMessage(ERROR_MESSAGE);
        return of(undefined);
      })
    );
  }

  private undoDeclineQuote(shipmentId: string, quoteId: string): Observable<Bid> {
    return this.shipmentDataService.undeclineBid(quoteId).pipe(
      map(() => {
        this.updateMessage(null);
        this.toastService.showSuccess('Sucessfully undeclined quote');
        this.fetchShipment(shipmentId);
      }),
      catchError((error: any) => {
        this.updateMessage(ERROR_MESSAGE);
        return of(undefined);
      })
    );
  }

  private counterQuote(shipmentId: string, quoteId: string, offer: number): Observable<Bid> {
    return this.shipmentDataService.counterBid(quoteId, offer).pipe(
      map(() => {
        this.updateMessage(null);
        this.toastService.showSuccess('Sucessfully countered quote');
        this.fetchShipment(shipmentId);
      }),
      catchError((error: any) => {
        this.updateMessage(ERROR_MESSAGE);
        return of(undefined);
      })
    );
  }

  private navigateToShipmentDetails(shipmentId: string) {
    this.router.navigate([ROUTE_SHIPMENT_DETAILS, shipmentId]);
  }

  private addMockEventsToShipment(): Event[] {
    const mockEvents: Event[] = [
      { date: '2020-01-08', name: 'Shipment Created', location: 'Winnipeg' },
      { date: '2020-01-09', name: 'Shipment being delivered', location: 'Regina' }
    ];
    return mockEvents;
  }

  private addMockQuote(shipment: Shipment, actorType: string, bidActorType: string, quoteStatus: string): void {
    shipment.quotes.push({
      shipmentID: 'f8f4b991-f01d-458b-bf7b-2ef3b6902ae7',
      actorID: 'dc520136-c145-44b8-bf76-02b5cfca5155',
      actor: actorType,
      actorName: 'Wayne Industries',
      actorPicture:
        'https://3.bp.blogspot.com/-AlmFLPJqOeA/XWCruZxfoFI/AAAAAAAAA3k/wObayyR_2xUqEeDI1-D0cynX_BUl_kyFwCK4BGAYYCw/s1600/LEGO_Michael_Capner_Profile.jpg',
      id: 'cc5d4be8-cd91-49ea-b613-3c00774672e7',
      status: quoteStatus,
      primaryOffer: 1000,
      secondaryOffer: 0,
      bids: [
        {
          actorID: 'dc520136-c145-44b8-bf76-02b5cfca5155',
          actor: bidActorType,
          offer: 123,
          date: `2019-12-22T3:18:30Z`,
          event: 'countered'
        },
        {
          actorID: 'dc520136-c145-44b8-bf76-02b5cfca5155',
          actor: bidActorType,
          offer: 123,
          date: `2019-12-22T3:18:31Z`,
          event: 'countered'
        },
        {
          actorID: 'dc520136-c145-44b8-bf76-02b5cfca5155',
          actor: bidActorType,
          offer: 123,
          date: `2019-12-22T3:18:32Z`,
          event: 'accepted'
        }
      ]
    } as Quote);
  }
}
