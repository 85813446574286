import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  title: string;
  message: string;
}
@Component({
  selector: 'fleetoperate-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {
  htmlContent: string;

  constructor(
    public readonly dialogRef: MatDialogRef<InformationComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData
  ) {}

  ngOnInit() {}
}
