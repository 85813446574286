import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Shipper, ShipperDataService } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { first } from 'rxjs/internal/operators/first';
import { ShipperService } from '../../shared/shipper.service';
import { SubSink } from 'subsink';
import { Company } from 'libs/shared/shipper/data-access-shipper/src/lib/models/company';
import { ToastService } from '@fleetoperate/shared/util';

const RequiredFieldMessage = 'Required';
@Component({
  selector: 'fleetoperate-profile-user-info',
  templateUrl: './profile-user-info.component.html',
  styleUrls: ['./profile-user-info.component.scss']
})
export class ProfileUserInfoComponent implements OnInit, OnChanges {
  shipper: Shipper;
  userInfoMessage: string;

  userInfoForm: FormGroup;
  userInfoFormMessage: string;
  userInfoReadOnly: boolean;
  loading: boolean;
  shipperUpdated: boolean;
  private subSink: SubSink;

  constructor(
    private readonly fb: FormBuilder,
    private readonly shipperDataService: ShipperDataService,
    private readonly shipperService: ShipperService,
    private readonly toastService: ToastService
  ) {
    this.userInfoMessage = undefined;
    this.userInfoFormMessage = undefined;
    this.subSink = new SubSink();
    this.userInfoReadOnly = false;
    this.shipperUpdated = false;

    this.loading = false;
  }

  ngOnInit(): void {
    this.userInfoForm = this.createUserInfoForm();
    this.subSink.add(
      this.shipperService.shipper$.subscribe((shipper: Shipper) => {
        this.shipper = shipper;
        this.resetUserInfoForm(this.shipper, undefined);
      })
    );
  }

  ngOnChanges(): void {
    if (this.shipper) {
      this.resetUserInfoForm(this.shipper, undefined);
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  onUserInfoFormSubmit(userInfoFormRef: NgForm): void {
    if (!userInfoFormRef.valid) {
      return;
    }
    this.userInfoReadOnly = true;
    this.loading = true;

    const newShipper = this.prepareUserInfoSaveModel(this.shipper, this.userInfoForm);
    const newCompany = this.prepareUserCompanySaveModel(this.shipper.company, this.userInfoForm);

    this.shipperService
      .updateShipper(newShipper)
      .pipe(first())
      .subscribe(
        (shipper: Shipper) => {
          this.userInfoFormMessage = undefined;
          this.loading = false;
          this.userInfoReadOnly = false;

          this.shipperService
            .updateShipperCompany(this.shipper.id, newCompany)
            .pipe(first())
            .subscribe(
              (company: Company) => {
                this.userInfoFormMessage = undefined;
                this.loading = false;
                this.userInfoReadOnly = false;

                this.toastService.showSuccess('Successfully updated user information');
              },
              (error: any) => {
                this.userInfoFormMessage = error.message;
                this.loading = false;
              }
            );
        },
        (error: any) => {
          this.userInfoFormMessage = error.message;
          this.loading = false;
        }
      );
  }

  onUserInfoFormCancel(userInfoFormRef: NgForm): void {
    this.resetUserInfoForm(this.shipper, userInfoFormRef);
  }

  get firstName() {
    return this.userInfoForm.get('firstName');
  }
  get lastName() {
    return this.userInfoForm.get('lastName');
  }
  get phoneNumber() {
    return this.userInfoForm.get('phoneNumber');
  }
  get email() {
    return this.userInfoForm.get('email');
  }

  getFirstNameErrorMessage(): string {
    const errors = this.firstName.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getLastNameErrorMessage(): string {
    const errors = this.lastName.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getPhoneNumberErrorMessage(): string {
    const errors = this.phoneNumber.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  private createUserInfoForm(shipper?: Shipper): FormGroup {
    const userInfoFormModel = {
      firstName: shipper && shipper.firstName ? shipper.firstName : '',
      lastName: shipper && shipper.lastName ? shipper.lastName : '',
      email: shipper && shipper.email ? shipper.email : '',
      phoneNumber: shipper && shipper.phoneNumber ? shipper.phoneNumber : ''
    };

    const form = this.fb.group({
      firstName: [userInfoFormModel.firstName, Validators.required],
      lastName: [userInfoFormModel.lastName, Validators.required],
      email: [{ value: userInfoFormModel.email, disabled: true }],
      phoneNumber: [userInfoFormModel.phoneNumber, Validators.required]
    });

    return form;
  }
  private resetUserInfoForm(shipper: Shipper, userInfoFormRef: NgForm): void {
    if (userInfoFormRef) {
      userInfoFormRef.resetForm();
    }
    const userInfoFormModel = {
      firstName: shipper && shipper.firstName ? shipper.firstName : '',
      lastName: shipper && shipper.lastName ? shipper.lastName : '',
      email: shipper && shipper.email ? shipper.email : '',
      phoneNumber: shipper && shipper.phoneNumber ? shipper.phoneNumber : ''
    };
    this.userInfoForm.reset(userInfoFormModel);
  }

  private prepareUserInfoSaveModel(shipper: Shipper, userInfoForm: FormGroup): Shipper {
    const formModel = userInfoForm.value;
    const saveModel = Object.assign({}, shipper);
    saveModel.company = Object.assign({}, shipper.company);

    saveModel.firstName = formModel.firstName as string;
    saveModel.lastName = formModel.lastName as string;
    saveModel.email = shipper.email as string;
    saveModel.phoneNumber = formModel.phoneNumber as string;
    saveModel.company.phoneNumber = formModel.phoneNumber as string;
    return saveModel;
  }

  private prepareUserCompanySaveModel(company: Company, userInfoForm: FormGroup): Company {
    const formModel = userInfoForm.value;
    const saveModel = Object.assign({}, company);
    saveModel.phoneNumber = formModel.phoneNumber as string;
    return saveModel;
  }
}
