import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Shipment, ShipmentDataService } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { first } from 'rxjs/operators';
import { SubSink } from 'subsink';

const RequiredFieldMessage = 'Required';
const ERROR_MESSAGE = 'There was an error. Please try again.';
const ValidPositiveNumber = 'Please enter a valid number';
const NumbersOnlyWithUpToTwoDecimalsMessage = 'Numbers with up to two decimals only';
const PositiveNumberMessage = 'Must be greater or equal to zero';
@Component({
  selector: 'fleetoperate-edit-shipment-details',
  templateUrl: './edit-shipment-details.component.html',
  styleUrls: ['./edit-shipment-details.component.scss']
})
export class EditShipmentDetailsComponent implements OnInit, OnChanges {
  @Input() shipment: Shipment;
  @Output() update: EventEmitter<boolean> = new EventEmitter();

  @Output() valid = new EventEmitter();
  @Output() dirty = new EventEmitter();

  shipmentDetailsForm: FormGroup;
  shipmentDetailsFormMessage: string;
  shipmentDetailsFormReadonly: boolean;
  loading: boolean;

  private subs = new SubSink();

  constructor(private readonly fb: FormBuilder, private readonly shipmentDataService: ShipmentDataService) {
    this.shipmentDetailsForm = this.createShipmentDetailsForm();
    this.shipmentDetailsFormMessage = undefined;
    this.shipmentDetailsFormReadonly = true;

    this.subs.add(
      this.shipmentDetailsForm.valueChanges.subscribe((value: any) => {
        this.valid.emit(this.shipmentDetailsForm.valid);
        this.dirty.emit(this.shipmentDetailsForm.dirty);
      })
    );
    this.loading = false;
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.shipment) {
      this.resetShipmentDetailsForm(this.shipment, undefined);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onShipmentDetailsFormSubmit(shipmentDetailsFormRef: NgForm): void {
    if (!this.shipmentDetailsForm.valid) {
      return;
    }

    this.loading = true;

    const newShipment = this.prepareShipmentDetailsSaveModel(this.shipment, this.shipmentDetailsForm);

    this.shipmentDataService
      .updateShipment(newShipment)
      .pipe(first())
      .subscribe(
        (shipment: Shipment) => {
          this.shipmentDetailsFormMessage = undefined;
          this.loading = false;
          this.update.emit(null);
          this.shipmentDetailsFormReadonly = true;
        },
        (error: string) => {
          this.shipmentDetailsFormMessage = error || ERROR_MESSAGE;
          this.loading = false;
        }
      );
  }

  onShipmentDetailsFormEdit(): void {
    this.shipmentDetailsFormReadonly = false;
  }

  onShipmentDetailsFormCancel(shipmentDetailsFormRef: NgForm): void {
    this.shipmentDetailsFormReadonly = true;
    this.resetShipmentDetailsForm(this.shipment, shipmentDetailsFormRef);
  }

  get shipmentReferenceNumber() {
    return this.shipmentDetailsForm.get('shipmentReferenceNumber');
  }
  get length() {
    return this.shipmentDetailsForm.get('length');
  }
  get commodity() {
    return this.shipmentDetailsForm.get('commodity');
  }
  get declaredValue() {
    return this.shipmentDetailsForm.get('declaredValue');
  }
  get comment() {
    return this.shipmentDetailsForm.get('comment');
  }

  getShipmentReferenceNumberErrorMessage(): string {
    const errors = this.shipmentReferenceNumber.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getLengthErrorMessage(): string {
    const errors = this.length.errors;
    return errors.pattern ? ValidPositiveNumber : '';
  }

  getCommodityErrorMessage(): string {
    const errors = this.commodity.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getDeclaredValueErrorMessage(): string {
    const errors = this.declaredValue.errors;
    return errors.min ? PositiveNumberMessage : errors.pattern ? NumbersOnlyWithUpToTwoDecimalsMessage : '';
  }

  getCommentErrorMessage(): string {
    const errors = this.comment.errors;
    return '';
  }

  private createShipmentDetailsForm(shipment?: Shipment): FormGroup {
    const shipmentFormModel = {
      shipmentReferenceNumber: shipment && shipment.shipmentReferenceNumber ? shipment.shipmentReferenceNumber : '',
      length: shipment && shipment.length ? shipment.length : '',
      commodity: shipment && shipment.commodity ? shipment.commodity : '',
      declaredValue: shipment && shipment.declaredValue ? shipment.declaredValue : '',
      comment: shipment && shipment.comment ? shipment.comment : ''
    };

    const form = this.fb.group({
      shipmentReferenceNumber: [shipmentFormModel.shipmentReferenceNumber],
      length: [shipmentFormModel.length, Validators.pattern('^[0-9]*')],
      commodity: [shipmentFormModel.commodity, Validators.required],
      declaredValue: [shipmentFormModel.declaredValue, [Validators.pattern('^\\d+(\\.\\d{1,2})?$'), Validators.min(0)]],
      comment: [shipmentFormModel.comment]
    });

    return form;
  }
  private resetShipmentDetailsForm(shipment: Shipment, shipmentDetailsFormRef: NgForm): void {
    if (shipmentDetailsFormRef) {
      shipmentDetailsFormRef.resetForm();
    }
    const shipmentFormModel = {
      shipmentReferenceNumber: shipment && shipment.shipmentReferenceNumber ? shipment.shipmentReferenceNumber : '',
      length: shipment && shipment.length ? shipment.length : '',
      commodity: shipment && shipment.commodity ? shipment.commodity : '',
      declaredValue: shipment && shipment.declaredValue ? shipment.declaredValue : '',
      comment: shipment && shipment.comment ? shipment.comment : ''
    };
    this.shipmentDetailsForm.reset(shipmentFormModel);
  }

  private prepareShipmentDetailsSaveModel(shipment: Shipment, shipmentDetailsForm: FormGroup): Shipment {
    const formModel = shipmentDetailsForm.value;
    const saveModel = Object.assign({}, shipment);

    saveModel.shipmentReferenceNumber = (formModel.shipmentReferenceNumber as string) || undefined;
    saveModel.length = (formModel.length as string) || undefined;
    saveModel.commodity = (formModel.commodity as string) || undefined;
    saveModel.declaredValue = (formModel.declaredValue as string) || undefined;
    saveModel.comment = (formModel.comment as string) || undefined;

    return saveModel;
  }
}
