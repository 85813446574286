import { Component, OnInit, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { McDotOperateLocationModel } from './mc-dot-operate-location-model';
import { RegistrationService } from '../services/registration.service';
import { ActivatedRoute } from '@angular/router';

const RequiredFieldMessage = 'You must enter a value';
const NumbersOnlyMessage = 'Must use numbers only';

@Component({
  selector: 'app-mc-dot-operate-location',
  templateUrl: './mc-dot-operate-location.component.html',
  styleUrls: ['./mc-dot-operate-location.component.scss']
})
export class McDotOperateLocationComponent implements OnInit, OnChanges {
  step: number;
  numSteps: number;
  forwardRoute: string;
  form: FormGroup;

  constructor(
    private readonly route: ActivatedRoute,
    private fb: FormBuilder,
    private registrationService: RegistrationService
  ) {
    this.form = this.createForm();
  }

  ngOnInit() {
    this.step = this.route.snapshot.data.step;
    this.numSteps = this.route.snapshot.data.numSteps;
    this.forwardRoute = this.route.snapshot.data.forwardRoute;
    const formModel = this.getFormModel();
    this.populateForm(formModel);
  }

  ngOnChanges() {
    this.rebuildForm();
  }

  onNavigateToNextStep() {
    const model = this.prepareSaveModel();
    this.registrationService.saveMcDotOperateLocation(model.mcNumber, model.dotNumber, this.forwardRoute);
    this.rebuildForm();
  }

  onNavigateToLogin(): void {
    this.registrationService.navigateToSignIn();
  }

  get number() {
    return this.form.get('number');
  }

  getNumberErrorMessage() {
    const errors = this.number.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? NumbersOnlyMessage : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      type: ['dot'],
      number: ['', [Validators.required, Validators.pattern('^[0-9]*$')]]
    });

    return form;
  }

  private populateForm(model: McDotOperateLocationModel): void {
    this.form.setValue({
      type: model.mcNumber ? 'mc' : 'dot',
      number: model.mcNumber ? model.mcNumber : model.dotNumber ? model.dotNumber : ''
    });
    this.form.markAsDirty();
  }

  private rebuildForm() {
    const model = this.getFormModel();
    this.form.reset({
      type: model.mcNumber ? 'mc' : 'dot',
      number: model.mcNumber ? model.mcNumber : model.dotNumber ? model.dotNumber : ''
    });
  }

  private getFormModel(): McDotOperateLocationModel {
    return this.registrationService.getMcDotOperateLocation();
  }

  private prepareSaveModel(): McDotOperateLocationModel {
    const formModel = this.form.value;

    const saveModel: McDotOperateLocationModel = {
      mcNumber: formModel.type === 'mc' ? (formModel.number as number) : undefined,
      dotNumber: formModel.type === 'dot' ? (formModel.number as number) : undefined
    };

    return saveModel;
  }
}
