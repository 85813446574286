import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'fleetoperate-shipment-event',
  templateUrl: './shipment-event.component.html',
  styleUrls: ['./shipment-event.component.scss']
})
export class ShipmentEventComponent implements OnInit, OnChanges{

  @Input() date: string;
  @Input() name: string;
  @Input() location: string;
  formattedDate: string;

  constructor() {}
  
  ngOnChanges(){
    this.formattedDate = moment.tz(this.date, 'America/New_York').format('MMMM DD, YYYY h A z');
  }

  ngOnInit() {}
}
