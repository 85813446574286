import { Component, OnInit, Input } from '@angular/core';
import { Shipment } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';

@Component({
  selector: 'fleetoperate-shipment-events',
  templateUrl: './shipment-events.component.html',
  styleUrls: ['./shipment-events.component.scss']
})
export class ShipmentEventsComponent implements OnInit {
  @Input() shipment: Shipment;

  constructor() {}

  ngOnInit() {}
}
