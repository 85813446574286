import { Company } from './company';

export class Shipper {
  id: number;
  operateInUS: boolean;
  dotNumber: string;
  firstName: string;
  lastName: string;
  status: string;
  companyNumber: number;
  companyName: string;
  companyID: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  email: string;
  phoneNumber: string;
  company: Company; 
}
