import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUnsubscribeEmailFeatureUnsubscribeEmailRoutingModule } from './shared-unsubscribe-email-feature-unsubscribe-email.routing.module';
import { MatButtonModule } from '@angular/material/button';
import { SuccessfulUnsubscribeEmailComponent } from './sucessful-unsubscribe-email/successful-unsubscribe-email.component';
import { ConfirmEmailUnsubscribeComponent } from './confirm-email-unsubscribe/confirm-email-unsubscribe.component';
import { SharedUnsubscribeEmailDataAccessUnsubscribeEmailModule } from 'libs/shared/unsubscribe-email/data-access-unsubscribe-email/src/lib/shared-unsubscribe-email-data-access-unsubscribe-email.module';
import { UnsubscribeEmailDataService } from 'libs/shared/unsubscribe-email/data-access-unsubscribe-email/src/lib/unsubscribe-email-data/unsubscribe-email-data.service';
import { FailureUnsubscribeEmailComponent } from './failure-unsubscribe-email/failure-unsubscribe-email.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
            CommonModule, 
            SharedUnsubscribeEmailFeatureUnsubscribeEmailRoutingModule, 
            MatButtonModule, 
            SharedUnsubscribeEmailDataAccessUnsubscribeEmailModule,
            FlexLayoutModule
          ],
  declarations: [
            SuccessfulUnsubscribeEmailComponent, 
            FailureUnsubscribeEmailComponent,
            ConfirmEmailUnsubscribeComponent],
  providers: [
            UnsubscribeEmailDataService]
})
export class SharedUnsubscribeEmailFeatureUnsubscribeEmailModule {}
