import { Injectable } from '@angular/core';
import { Response } from '@fleetoperate/shared/data-access';
import { DocumentsDataModule } from './documents-data.module';
import { DocumentsApiService } from './documents-api.service';
import { DocumentType } from '../models/document-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SendDocumentResponse } from '../models/send-document-response';
import { SendDocumentRequest } from '../models/send-document-request';
import { HttpHeaders } from '@angular/common/http';
import { HttpOptions } from '@fleetoperate/shared/data-access';
import { DriverDocument } from '../models/driver-document';

@Injectable({
  providedIn: DocumentsDataModule
})
export class DocumentsDataService {
  constructor(private readonly documentsAPIService: DocumentsApiService) {}

  loadCarrierDocumentTypes(): Observable<DocumentType[]> {
    return this.documentsAPIService.getCarrierDocumentTypes().pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data.Carrier as DocumentType[];
        }

        return undefined;
      })
    );
  }

  sendCarrierDocument(carrierID: string, documentType: string, extension: string): Observable<SendDocumentResponse> {
    const sendDocumentRequest = { documentType, extension } as SendDocumentRequest;

    return this.documentsAPIService.sendCarrierDocument(carrierID, sendDocumentRequest).pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data as SendDocumentResponse;
        }
        return undefined;
      })
    );
  }

  uploadCarrierDocument(file: any, url: string, contentType: string): Observable<Response> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': contentType
      })
    } as HttpOptions;

    return this.documentsAPIService.uploadCarrierDocument(file, url, httpOptions);
  }

  deleteCarrierDocument(carrierId: string, documentId: string): Observable<Response> {
    return this.documentsAPIService.deleteCarrierDocument(carrierId, documentId);
  }

  loadDriverDocumentTypes(): Observable<DocumentType[]> {
    return this.documentsAPIService.getDriverDocumentTypes().pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data.Driver as DocumentType[];
        }

        return undefined;
      })
    );
  }

  sendDriverDocument(
    driverID: string,
    reportType: string,
    extension: string,
    source: string,
    generationDate: string,
    validToDate: string
  ): Observable<SendDocumentResponse> {
    const sendDocumentRequest = { reportType, extension, source, generationDate, validToDate } as SendDocumentRequest;

    return this.documentsAPIService.sendDriverDocument(driverID, sendDocumentRequest).pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data as SendDocumentResponse;
        }
        return undefined;
      })
    );
  }

  uploadDriverDocument(file: any, url: string, contentType: string): Observable<Response> {
    return this.documentsAPIService.uploadDriverDocument(file, url, contentType);
  }

  loadShipmentDocumentTypes(): Observable<DocumentType[]> {
    return this.documentsAPIService.getShipmentDocumentTypes().pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data.Shipment as DocumentType[];
        }

        return undefined;
      })
    );
  }

  sendShipmentDocument(shipmentID: string, documentType: string, extension: string): Observable<SendDocumentResponse> {
    const sendDocumentRequest = { documentType, extension } as SendDocumentRequest;

    return this.documentsAPIService.sendShipmentDocument(shipmentID, sendDocumentRequest).pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data as SendDocumentResponse;
        }

        return undefined;
      })
    );
  }

  uploadShipmentDocument(file: any, url: string, contentType: string): Observable<Response> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': contentType
      })
    } as HttpOptions;

    return this.documentsAPIService.uploadShipmentDocument(file, url, httpOptions);
  }

  deleteShipmentDocument(shipmentId: string, documentId: string): Observable<Response> {
    return this.documentsAPIService.deleteShipmentDocument(shipmentId, documentId);
  }

  getDriverDocument(driverID: string, documentId: string): Observable<DriverDocument> {
    return this.documentsAPIService
      .getDriverDocument(driverID, documentId)
      .pipe(map((response: any) => response.data as DriverDocument));
  }
}
