import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleAddressAutocompleteService } from './google-address-autocomplete.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [GoogleAddressAutocompleteService]
})
export class GoogleAddressAutocompleteModule { }
