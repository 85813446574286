<form [formGroup]="pickupForm" #pickupFormRef="ngForm" (ngSubmit)="onPickupFormSubmit(pickupFormRef)" fxLayout="column">
  <mat-expansion-panel [disabled]="!pickupFormReadonly">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Pickup
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-error class="padding-bottom-sm">{{pickupFormMessage}}</mat-error>
    <div fxLayout class="margin-bottom-md">
      <mat-form-field fxFlex class="margin-right-md">
        <input matInput [readonly]="pickupFormReadonly" placeholder="Business name" formControlName="businessName"
          required>
        <mat-error *ngIf="businessName.touched && businessName.invalid">
          {{getBusinessNameErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex class="margin-right-md">
        <input matInput [readonly]="pickupFormReadonly" placeholder="Contact name" formControlName="contactName"
          required>
        <mat-error *ngIf="contactName.touched && contactName.invalid">
          {{getContactNameErrorMessage()}}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout class="margin-bottom-md">
      <mat-form-field fxFlex class="margin-right-md">
        <input matInput [readonly]="pickupFormReadonly" placeholder="Contact email" formControlName="contactEmail"
          required>
        <mat-error *ngIf="contactEmail.touched && contactEmail.invalid">
          {{getContactEmailErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex class="margin-right-md">
        <input matInput [readonly]="pickupFormReadonly" placeholder="Contact phone" formControlName="contactPhone"
          required>
        <mat-error *ngIf="contactPhone.touched && contactPhone.invalid">
          {{getContactPhoneErrorMessage()}}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout class="margin-bottom-md">
      <mat-form-field fxFlex class="margin-right-md">
        <input matInput #addressText [readonly]="pickupFormReadonly"
          placeholder="Street address" formControlName="streetAddress" required>
        <mat-error *ngIf="streetAddress.touched && streetAddress.invalid">
          {{getStreetAddressErrorMessage()}}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="column" class="margin-bottom-md">
      <div fxFlex class="margin-right-md">
        <mat-checkbox [disabled]="pickupFormReadonly" formControlName="driverAssistedPickup">Driver assisted pickup
        </mat-checkbox>
        <mat-error *ngIf="driverAssistedPickup.touched && driverAssistedPickup.invalid">
          {{getDriverAssistedPickupErrorMessage()}}
        </mat-error>
      </div>
      <div fxFlex class="margin-right-md">
        <mat-checkbox [disabled]="pickupFormReadonly" formControlName="liftgatePickup">Liftgate pickup</mat-checkbox>
        <mat-error *ngIf="liftgatePickup.touched && liftgatePickup.invalid">
          {{getLiftgatePickupErrorMessage()}}
        </mat-error>
      </div>
      <div fxFlex class="margin-right-md">
        <mat-checkbox [disabled]="pickupFormReadonly" formControlName="tarpPickup">Tarp pickup</mat-checkbox>
        <mat-error *ngIf="tarpPickup.touched && tarpPickup.invalid">
          {{getTarpPickupErrorMessage()}}
        </mat-error>
      </div>
    </div>
    <div fxLayout class="margin-bottom-md">
      <mat-form-field fxFlex class="margin-right-md">
        <textarea matInput [readonly]="pickupFormReadonly" placeholder="Instructions"
          formControlName="instructions"></textarea>
        <mat-error *ngIf="instructions.touched && instructions.invalid">
          {{getInstructionsErrorMessage()}}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout class="margin-bottom-md">
      <div fxLayout="column" class="margin-right-md margin-bottom-md">
        <span>Pickup time</span>
        <mat-button-toggle-group [value]="pickupTimeGroupSelection" [disabled]="pickupFormReadonly"
          (change)="onPickupTimeGroupChange($event)" name="pickupTimeToggle" #pickupTimeGroup="matButtonToggleGroup">
          <mat-button-toggle value="between">Between</mat-button-toggle>
          <mat-button-toggle value="at">At</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div fxLayout="column" class="margin-bottom-md">
        <span>Time</span>
        <div fxLayout>
          <mat-form-field fxFlex class="margin-right-md">
            <mat-hint align="start">0000</mat-hint>
            <input matInput [readonly]="pickupFormReadonly" placeholder="" formControlName="pickupTimeFrom">
            <mat-error *ngIf="pickupTimeFrom.touched && pickupTimeFrom.invalid">
              {{getPickupTimeFromErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <span class="margin-right-sm">EDT</span>
          <div *ngIf="pickupTimeGroup.value == 'between'">
            <span class="margin-right-sm">to</span>
            <mat-form-field fxFlex class="margin-right-md">
              <mat-hint align="start">0000</mat-hint>
              <input matInput [readonly]="pickupFormReadonly" formControlName="pickupTimeTo" [errorStateMatcher]="errorMatcher">
              <mat-error *ngIf="(pickupForm.errors && pickupForm.errors.range) || (pickupTimeTo.dirty && pickupTimeTo.invalid)">
                {{getPickupTimeToErrorMessage()}}
              </mat-error>
            </mat-form-field>
            <span>EDT</span>
          </div>
        </div>
      </div>
    </div>

    <mat-action-row fxLayoutGap="20px">
      <button *ngIf="pickupFormReadonly" mat-raised-button type="button" [disabled]="loading"
        (click)="onPickupFormEdit()">Edit</button>
      <button *ngIf="!pickupFormReadonly" mat-raised-button type="submit"
        [disabled]="pickupForm.pristine || !pickupForm.valid || loading">Save</button>
      <button *ngIf="!pickupFormReadonly" mat-raised-button type="button" [disabled]="loading"
        (click)="onPickupFormCancel(pickupFormRef)">Cancel</button>
    </mat-action-row>
  </mat-expansion-panel>
</form>
