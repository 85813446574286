export const ROUTE_REGISTRATION = 'registration';
export const ROUTE_SIGNIN = 'signin';

export const ROUTE_COMPANY = 'company';
export const ROUTE_COMPANYADDRESS = 'companyAddress';
export const ROUTE_SUBMISSION = 'submission';
export const ROUTE_VERIFICATION = 'verification';
export const ROUTE_CONFIRMATION = 'confirmation';

export const ROUTE_REGISTRATION_COMPANY = `registration/${ROUTE_COMPANY}`;
export const ROUTE_REGISTRATION_COMPANYADDRESS = `registration/${ROUTE_COMPANYADDRESS}`;
export const ROUTE_REGISTRATION_SUBMISSION = `registration/${ROUTE_SUBMISSION}`;
export const ROUTE_REGISTRATION_VERIFICATION = `registration/${ROUTE_VERIFICATION}`;
export const ROUTE_REGISTRATION_CONFIRMATION = `registration/${ROUTE_CONFIRMATION}`;
