import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditShipmentComponent } from './edit-shipment/edit-shipment.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { EditShipmentDetailsComponent } from './edit-shipment-details/edit-shipment-details.component';
import { EditPickupComponent } from './edit-pickup/edit-pickup.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { EditDeliveryComponent } from './edit-delivery/edit-delivery.component';
import { SharedUiHeaderModule } from '@fleetoperate/shared/ui/header';
import { SharedUiErrorMessageModule } from '@fleetoperate/shared/ui/error-message';
import { SharedShipperUiShipperModule } from '@fleetoperate/shared/shipper/ui-shipper';
import { GoogleAddressAutocompleteModule } from '@fleetoperate/shared/util';
import { DeliveryShipperUiAppToolbarModule } from '@fleetoperate/delivery-shipper/ui-app-toolbar';
import { SharedUiFleetoperateToolbarModule } from '@fleetoperate/shared/ui/fleetoperate-toolbar';

@NgModule({
  declarations: [EditShipmentComponent, EditShipmentDetailsComponent, EditPickupComponent, EditDeliveryComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    GoogleAddressAutocompleteModule,
    SharedUiFleetoperateToolbarModule,
    DeliveryShipperUiAppToolbarModule,
    SharedUiHeaderModule,
    SharedUiErrorMessageModule,
    SharedShipperUiShipperModule
  ]
})
export class EditShipmentModule { }

export { EditShipmentComponent } from './edit-shipment/edit-shipment.component';
