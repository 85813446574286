import { Injectable } from '@angular/core';
import {
  ShipmentApiService,
  EVENT_TYPE_ACCEPTED,
  EVENT_TYPE_COUNTERED,
  EVENT_TYPE_DECLINED,
  EVENT_TYPE_UNDECLINED
} from './shipment-api.service';
import { Observable, of, throwError } from 'rxjs';
import { ShipmentDataModule } from './shipment-data.module';
import { Shipment } from '../models/shipment';
import { map } from 'rxjs/operators';
import { Bid } from '../models/bid';
import { Lookup } from '../models/lookup';
import { ShipmentDocument } from '../models/shipment-document';
import { Response } from '@fleetoperate/shared/data-access';
import { ShipmentLookup } from '../..';
@Injectable({
  providedIn: ShipmentDataModule
})
export class ShipmentDataService {
  constructor(private readonly shipmentApiService: ShipmentApiService) {}

  getShipmentsByCompany(page: number, perPage: number, companyId?: string): Observable<Shipment[]> {
    return this.shipmentApiService
      .getShipmentsByCompany(page, perPage, companyId)
      .pipe(map((response: any) => response.data as Shipment[]));
  }

  getShipmentsByStatus(page: number, perPage: number, statuses: string[]): Observable<Shipment[]> {
    return this.shipmentApiService
      .getShipmentsByStatus(page, perPage, statuses)
      .pipe(map((response: any) => response.data as Shipment[]));
  }

  getShipment(shipmentId?: string): Observable<Shipment> {
    return this.shipmentApiService.getShipment(shipmentId).pipe(map((response: any) => response.data as Shipment));
  }

  getShipmentLookups(lookupKeys: string[]): Observable<ShipmentLookup> {
    return this.shipmentApiService
      .getShipmentLookups(lookupKeys)
      .pipe(map((response: any) => response.data as ShipmentLookup));
  }

  getShipmentDocuments(shipmentId: string): Observable<ShipmentDocument[]> {
    return this.shipmentApiService
      .getShipmentDocuments(shipmentId)
      .pipe(map((response: any) => response.data as ShipmentDocument[]));
  }

  getShipmentDocument(shipmentId: string, documentId: string): Observable<ShipmentDocument> {
    return this.shipmentApiService
      .getShipmentDocument(shipmentId, documentId)
      .pipe(map((response: any) => response.data as ShipmentDocument));
  }

  createShipment(shipment: Shipment): Observable<Shipment> {
    return this.shipmentApiService.createShipment(shipment).pipe(map((response: any) => response.data as Shipment));
  }

  updateShipment(shipment: Shipment): Observable<Shipment> {
    return this.shipmentApiService.updateShipment(shipment).pipe(map((response: any) => response.data as Shipment));
  }

  deleteShipment(shipmentId: string): Observable<Response> {
    return this.shipmentApiService.deleteShipment(shipmentId);
  }

  approveLoadShipment(shipment: Shipment): Observable<Shipment> {
    return this.shipmentApiService
      .approveLoadShipment(shipment)
      .pipe(map((response: any) => response.data as Shipment));
  }

  cancelBookedShipment(shipmentID: string, cancellationReasonModel: any): Observable<Shipment> {
    return this.shipmentApiService
      .cancelBookedShipment(shipmentID, cancellationReasonModel)
      .pipe(map((response: any) => response.data as Shipment));
  }

  completeShipment(shipmentId: string): Observable<Shipment> {
    return this.shipmentApiService.completeShipment(shipmentId).pipe(map((response: any) => response.data as Shipment));
  }

  acceptBid(quoteId: string, bidOffer: number): Observable<Bid> {
    const bid = {
      offer: bidOffer,
      event: EVENT_TYPE_ACCEPTED
    } as Bid;

    return this.shipmentApiService.createBid(quoteId, bid).pipe(map((response: any) => response.data as Bid));
  }

  counterBid(quoteId: string, bidOffer: number): Observable<Bid> {
    const bid = {
      offer: bidOffer,
      event: EVENT_TYPE_COUNTERED
    } as Bid;

    return this.shipmentApiService.createBid(quoteId, bid).pipe(map((response: any) => response.data as Bid));
  }

  declineBid(quoteId: string): Observable<Bid> {
    const bid = {
      event: EVENT_TYPE_DECLINED
    } as Bid;

    return this.shipmentApiService.createBid(quoteId, bid).pipe(map((response: any) => response.data as Bid));
  }

  undeclineBid(quoteId: string): Observable<Bid> {
    const bid = {
      event: EVENT_TYPE_UNDECLINED
    } as Bid;

    return this.shipmentApiService.createBid(quoteId, bid).pipe(map((response: any) => response.data as Bid));
  }

  getShipmentsMock(page: number, perPage: number, companyId?: string): Observable<any> {
    return this.shipmentApiService.getShipmentsMock(page, perPage);
  }

  getShipmentByIdMock(id: string) {
    return this.getShipmentByIdMock(id);
  }

  getShipmentById(id: string): Observable<Shipment> {
    // TODO: Complete this
    return of({} as Shipment);
  }
}
