import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../services/registration.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.scss']
})
export class SubmissionComponent implements OnInit {
  step: number;
  numSteps: number;
  forwardRoute: string;
  email: string;

  constructor(private readonly route: ActivatedRoute, private readonly registrationService: RegistrationService) {}

  ngOnInit() {
    this.step = this.route.snapshot.data.step;
    this.numSteps = this.route.snapshot.data.numSteps;
    this.forwardRoute = this.route.snapshot.data.forwardRoute;
    this.email = this.registrationService.getCompany().email;
    this.registrationService.clearRegistrationInformation();
  }

  onNavigateToNextStep() {
    this.registrationService.navigateToNextStep(this.forwardRoute);
  }
}
