import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  NgZone
} from '@angular/core';
import { Shipment, ShipmentDataService } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import {
  FormGroup,
  FormBuilder,
  NgForm,
  Validators,
  FormControl,
  FormGroupDirective,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { SubSink } from 'subsink';
import { ErrorStateMatcher } from '@angular/material/core';
import * as moment from 'moment-timezone';
import { GoogleAddressAutocompleteService } from '@fleetoperate/shared/util';

const RequiredFieldMessage = 'Required';
const ValidTimeFormatMessage = 'Must be 0000 format';
const ERROR_MESSAGE = 'There was an error. Please try again.';
const PICKUP_TIME_SELECTION_BETWEEN = 'between';
const PICKUP_TIME_SELECTION_AT = 'at';
const ValidPickupToMessage = 'Please enter a valid time that occurs after the pickup from time.';
const ValidContactPhoneFormat =
  'Please enter a valid 11 digit phone number with no hyphens or spaces that begins with 1';
const MaximumNumberMessage = `Please enter a valid time. Must be less than 2359 characters`;
const validAddressMessage = 'Please enter a full valid address';
const maxTime = 2359;

class AddressModel {
  fullAddress: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}

@Component({
  selector: 'fleetoperate-edit-pickup',
  templateUrl: './edit-pickup.component.html',
  styleUrls: ['./edit-pickup.component.scss']
})
export class EditPickupComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() shipment: Shipment;
  @Output() update: EventEmitter<boolean> = new EventEmitter();

  @Output() valid = new EventEmitter();
  @Output() dirty = new EventEmitter();

  @Input() autocompleteInput: string;
  @ViewChild('addressText', { static: false }) addressText: any;

  errorMatcher = new TimeToInvalidErrorMatcher();

  addressModel: AddressModel;

  private subs = new SubSink();

  pickupForm: FormGroup;
  pickupFormMessage: string;
  pickupFormReadonly: boolean;
  pickupTimeGroupSelection: string;
  loading: boolean;

  constructor(
    private readonly fb: FormBuilder,
    private readonly googleAddressAutocompleteService: GoogleAddressAutocompleteService,
    private readonly shipmentDataService: ShipmentDataService
  ) {
    this.pickupForm = this.createPickupForm();
    this.pickupFormMessage = undefined;
    this.pickupFormReadonly = true;

    this.loading = false;

    this.subs.add(
      this.pickupForm.valueChanges.subscribe((value: any) => {
        this.valid.emit(this.pickupForm.valid);
        this.dirty.emit(this.pickupForm.dirty);
      })
    );
  }

  ngOnInit(): void {
    this.addressModel = new AddressModel();
  }

  ngOnChanges(): void {
    if (this.shipment) {
      this.resetPickupForm(this.shipment, undefined);
      this.googleAddressAutocompleteService.parseAddress(this.shipment.origin);
    }
  }

  ngAfterViewInit(): void {
    this.googleAddressAutocompleteService.getPlaceAutocomplete(this.pickupForm, this.addressText, 'streetAddress');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onPickupFormSubmit(pickupFormRef: NgForm): void {
    if (!this.pickupForm.valid) {
      return;
    }

    this.loading = true;

    const newShipment = this.preparePickupSaveModel(this.shipment, this.pickupForm);

    this.shipmentDataService
      .updateShipment(newShipment)
      .pipe(first())
      .subscribe(
        (shipment: Shipment) => {
          this.pickupFormMessage = undefined;
          this.loading = false;
          this.update.emit(null);
          this.pickupFormReadonly = true;
        },
        (error: any) => {
          this.pickupFormMessage = error.message || ERROR_MESSAGE;
          this.loading = false;
        }
      );
  }

  onPickupFormEdit(): void {
    this.pickupFormReadonly = false;
  }

  onPickupFormCancel(pickupFormRef: NgForm): void {
    this.pickupFormReadonly = true;
    this.resetPickupForm(this.shipment, pickupFormRef);
  }

  onPickupTimeGroupChange($event: MatButtonToggleChange): void {
    this.pickupTimeGroupSelection = $event.value;
  }

  get businessName() {
    return this.pickupForm.get('businessName');
  }
  get contactName() {
    return this.pickupForm.get('contactName');
  }
  get contactEmail() {
    return this.pickupForm.get('contactEmail');
  }
  get contactPhone() {
    return this.pickupForm.get('contactPhone');
  }
  get streetAddress() {
    return this.pickupForm.get('streetAddress');
  }
  get driverAssistedPickup() {
    return this.pickupForm.get('driverAssistedPickup');
  }
  get liftgatePickup() {
    return this.pickupForm.get('liftgatePickup');
  }
  get tarpPickup() {
    return this.pickupForm.get('tarpPickup');
  }
  get instructions() {
    return this.pickupForm.get('instructions');
  }
  get pickupTimeFrom() {
    return this.pickupForm.get('pickupTimeFrom');
  }
  get pickupTimeTo() {
    return this.pickupForm.get('pickupTimeTo');
  }

  getBusinessNameErrorMessage(): string {
    const errors = this.businessName.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getContactNameErrorMessage(): string {
    const errors = this.contactName.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getContactEmailErrorMessage(): string {
    const errors = this.contactEmail.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getContactPhoneErrorMessage(): string {
    const errors = this.contactPhone.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? ValidContactPhoneFormat : '';
  }

  getStreetAddressErrorMessage(): string {
    const errors = this.streetAddress.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? validAddressMessage : '';
  }

  getDriverAssistedPickupErrorMessage(): string {
    const errors = this.driverAssistedPickup.errors;
    return '';
  }

  getLiftgatePickupErrorMessage(): string {
    const errors = this.liftgatePickup.errors;
    return '';
  }

  getTarpPickupErrorMessage(): string {
    const errors = this.tarpPickup.errors;
    return '';
  }

  getInstructionsErrorMessage(): string {
    const errors = this.instructions.errors;
    return '';
  }

  getPickupTimeFromErrorMessage(): string {
    const errors = this.pickupTimeFrom.errors;
    return errors.max ? MaximumNumberMessage : errors.pattern ? ValidTimeFormatMessage : '';
  }

  getPickupTimeToErrorMessage(): string {
    const errors = this.pickupTimeTo.errors;
    return errors && errors.pattern
      ? ValidTimeFormatMessage
      : errors && errors.max
      ? MaximumNumberMessage
      : this.pickupForm && this.pickupForm.errors && this.pickupForm.errors.range
      ? ValidPickupToMessage
      : '';
  }

  private createPickupForm(shipment?: Shipment): FormGroup {
    const formModel = {
      businessName: shipment && shipment.pickup.businessName ? shipment.pickup.businessName : '',
      contactName: shipment && shipment.pickup.contactName ? shipment.pickup.contactName : '',
      contactEmail: shipment && shipment.pickup.contactEmail ? shipment.pickup.contactEmail : '',
      contactPhone: shipment && shipment.pickup.contactPhone ? shipment.pickup.contactPhone : '',
      streetAddress: shipment && shipment.origin ? shipment.origin : '',
      city: shipment && shipment.pickup.city ? shipment.pickup.city : '',
      state: shipment && shipment.pickup.state ? shipment.pickup.state : '',
      zipcode: shipment && shipment.pickup.zipcode ? shipment.pickup.zipcode : '',
      driverAssistedPickup:
        shipment && shipment.pickup.driverAssistedPickup ? shipment.pickup.driverAssistedPickup : false,
      liftgatePickup: shipment && shipment.pickup.liftgatePickup ? shipment.pickup.liftgatePickup : false,
      tarpPickup: shipment && shipment.pickup.tarpPickup ? shipment.pickup.tarpPickup : false,
      instructions: shipment && shipment.pickup.instructions ? shipment.pickup.instructions : '',
      pickupTimeFrom: shipment && shipment.pickup.pickupDateFrom ? this.getTime(shipment.pickup.pickupDateFrom) : '',
      pickupTimeTo: shipment && shipment.pickup.pickupDateTo ? this.getTime(shipment.pickup.pickupDateTo) : ''
    };

    const form = this.fb.group(
      {
        businessName: [formModel.businessName, Validators.required],
        contactName: [formModel.contactName, Validators.required],
        contactEmail: [formModel.contactEmail, Validators.required],
        contactPhone: [formModel.contactPhone, [Validators.required, Validators.pattern('^[0-9]{11}$')]],
        streetAddress: [
          formModel.streetAddress,
          [
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*,[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*, [A-Z]{2} [A-Z0-9 ]*, [A-Za-z]+$'
            )
          ]
        ],
        city: [formModel.city],
        state: [formModel.state],
        zipcode: [formModel.zipcode],
        driverAssistedPickup: [formModel.driverAssistedPickup],
        liftgatePickup: [formModel.liftgatePickup],
        tarpPickup: [formModel.tarpPickup],
        instructions: [formModel.instructions],
        pickupTimeFrom: [formModel.pickupTimeFrom, [Validators.pattern('^[0-9]{4}$'), Validators.max(maxTime)]],
        pickupTimeTo: [formModel.pickupTimeTo, [Validators.pattern('^[0-9]{4}$'), Validators.max(maxTime)]]
      },
      {
        validator: this.rangeIsNotValid()
      }
    );

    this.pickupTimeGroupSelection = formModel.pickupTimeTo ? PICKUP_TIME_SELECTION_BETWEEN : PICKUP_TIME_SELECTION_AT;

    return form;
  }
  private resetPickupForm(shipment: Shipment, pickupFormRef: NgForm): void {
    if (pickupFormRef) {
      pickupFormRef.resetForm();
    }
    const formModel = {
      businessName: shipment && shipment.pickup.businessName ? shipment.pickup.businessName : '',
      contactName: shipment && shipment.pickup.contactName ? shipment.pickup.contactName : '',
      contactEmail: shipment && shipment.pickup.contactEmail ? shipment.pickup.contactEmail : '',
      contactPhone: shipment && shipment.pickup.contactPhone ? shipment.pickup.contactPhone : '',
      streetAddress: shipment && shipment.origin ? shipment.origin : '',
      driverAssistedPickup:
        shipment && shipment.pickup.driverAssistedPickup ? shipment.pickup.driverAssistedPickup : false,
      liftgatePickup: shipment && shipment.pickup.liftgatePickup ? shipment.pickup.liftgatePickup : false,
      tarpPickup: shipment && shipment.pickup.tarpPickup ? shipment.pickup.tarpPickup : false,
      instructions: shipment && shipment.pickup.instructions ? shipment.pickup.instructions : '',
      pickupTimeFrom: shipment && shipment.pickup.pickupDateFrom ? this.getTime(shipment.pickup.pickupDateFrom) : '',
      pickupTimeTo: shipment && shipment.pickup.pickupDateTo ? this.getTime(shipment.pickup.pickupDateTo) : ''
    };
    this.pickupForm.reset(formModel);
    this.pickupTimeGroupSelection = formModel.pickupTimeTo ? PICKUP_TIME_SELECTION_BETWEEN : PICKUP_TIME_SELECTION_AT;
  }

  private preparePickupSaveModel(shipment: Shipment, pickupForm: FormGroup): Shipment {
    const formModel = pickupForm.value;
    const saveModel = Object.assign({}, shipment);

    this.addressModel = this.googleAddressAutocompleteService.parseAddress(this.streetAddress.value);

    saveModel.origin = this.addressModel.fullAddress;
    saveModel.pickup.businessName = formModel.businessName as string;
    saveModel.pickup.contactName = formModel.contactName as string;
    saveModel.pickup.contactEmail = formModel.contactEmail as string;
    saveModel.pickup.contactPhone = formModel.contactPhone as string;
    saveModel.pickup.streetAddress = this.addressModel.streetAddress as string;

    const dateTimeFrom = this.addDateTime(this.shipment.pickup.pickupDateFrom, formModel.pickupTimeFrom as string);
    const dateTimeTo = this.addDateTime(this.shipment.pickup.pickupDateFrom, formModel.pickupTimeTo as string);

    const dateTimeFromUTC = this.convertToUTC(dateTimeFrom);
    const dateTimeToUTC = this.convertToUTC(dateTimeTo);

    saveModel.pickup.city = this.addressModel.city;
    saveModel.pickup.state = this.addressModel.state;
    saveModel.pickup.zipcode = this.addressModel.zipcode;
    saveModel.pickup.country = this.addressModel.country;
    saveModel.pickup.driverAssistedPickup = formModel.driverAssistedPickup as boolean;
    saveModel.pickup.liftgatePickup = formModel.liftgatePickup as boolean;
    saveModel.pickup.tarpPickup = formModel.tarpPickup as boolean;
    saveModel.pickup.instructions = (formModel.instructions as string) || undefined;
    saveModel.pickup.pickupDateFrom = dateTimeFromUTC;
    saveModel.pickup.pickupDateTo = dateTimeToUTC || undefined;

    saveModel.pickup.pickupDateTo =
      this.pickupTimeGroupSelection === PICKUP_TIME_SELECTION_BETWEEN ? saveModel.pickup.pickupDateTo : undefined;

    return saveModel;
  }

  private getTime(dateTime: string): string {
    return moment
      .utc(dateTime)
      .tz('America/New_York')
      .format('HHmm');
  }

  private addDateTime(date: string, time: string): string {
    const momentDate = moment(date).format('YYYY-MM-DDT');
    const momentTime = moment(time, 'HHmm').format('HH:mm:ss');

    return momentDate + momentTime;
  }

  private convertToUTC(dateTime: string): string {
    const formDateTime = moment(dateTime).format('YYYY-MM-DDTHH:mm:ss');
    const dateTimeUTC = moment
      .tz(formDateTime, 'America/New_York')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ssZ');

    return dateTimeUTC === 'Invalid date' ? undefined : dateTimeUTC;
  }

  rangeIsNotValid(): ValidatorFn {
    return (form: FormGroup): ValidationErrors => {
      const condition =
        form.get('pickupTimeTo').value && form.get('pickupTimeFrom').value > form.get('pickupTimeTo').value;
      return condition ? { range: true } : null;
    };
  }
}
class TimeToInvalidErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.errors) || (form && form.errors && form.errors.range);
  }
}
