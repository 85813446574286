import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryShipperAppToolbarComponent } from './delivery-shipper-app-toolbar/delivery-shipper-app-toolbar.component';
import { SharedUiFleetoperateToolbarModule } from '@fleetoperate/shared/ui/fleetoperate-toolbar';

@NgModule({
  imports: [CommonModule, SharedUiFleetoperateToolbarModule],
  declarations: [DeliveryShipperAppToolbarComponent],
  exports: [DeliveryShipperAppToolbarComponent]
})
export class DeliveryShipperUiAppToolbarModule {}
