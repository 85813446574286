import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@fleetoperate/shared/authentication/feature-authentication';
import { ShipperGuardService } from '@fleetoperate/delivery-shipper/feature-shell';
import { ShipmentListComponent } from './shipment-list/shipment-list.module';
import {
  ROUTE_SHIPMENT_SEARCH,
  ROUTE_EDIT_SHIPMENT,
  ROUTE_SHIPMENT_DETAILS,
  ROUTE_SHIPMENT_QUOTES
} from './shared/routes';
import { EditShipmentComponent } from './edit-shipment/edit-shipment.module';
import { ShipmentDetailsComponent } from './shipment-details/shipment-details.module';
import { ShipmentQuotesComponent } from './shipment-quotes/shipment-quotes/shipment-quotes.component';

const routes: Routes = [
  {
    path: ROUTE_SHIPMENT_SEARCH,
    component: ShipmentListComponent,
    canActivate: [AuthGuardService, ShipperGuardService]
  },
  {
    path: `${ROUTE_EDIT_SHIPMENT}/:id`,
    component: EditShipmentComponent,
    canActivate: [AuthGuardService, ShipperGuardService]
  },
  {
    path: `${ROUTE_SHIPMENT_DETAILS}/:id`,
    component: ShipmentDetailsComponent,
    canActivate: [AuthGuardService, ShipperGuardService]
  },
  {
    path: `${ROUTE_SHIPMENT_QUOTES}/:id`,
    component: ShipmentQuotesComponent,
    canActivate: [AuthGuardService, ShipperGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeliveryShipperFeatureShipmentRoutingModule {}
