import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcceptQuoteConfirmationDialogComponent} from './accept-quote-confirmation-dialog.component'
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CurrencyFormatModule } from '@fleetoperate/shared/util';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ AcceptQuoteConfirmationDialogComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    FlexLayoutModule,
    CurrencyFormatModule,
    MatIconModule
  ],
  exports:[AcceptQuoteConfirmationDialogComponent]
})
export class AcceptQuoteConfirmationDialogModule { }
