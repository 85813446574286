import { Injectable } from '@angular/core';
import { ShipperDataService, Shipper } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { ShipperProfileModule } from '../shipper-profile/shipper-profile.module';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { Company } from 'libs/shared/shipper/data-access-shipper/src/lib/models/company';

@Injectable({
  providedIn: ShipperProfileModule
})
export class ShipperService {
  shipper: Shipper;
  shipperServiceMessage: string;
  shipper$: Observable<Shipper>;

  constructor(private readonly shipperDataService: ShipperDataService) {
    this.shipperServiceMessage = undefined;
    this.shipper$ = this.shipperDataService.shipper$;
  }

  loadShipper(): Observable<void> {
    return this.shipperDataService.loadShipper().pipe(
      map(() => {
        return;
      })
    );
  }

  updateShipper(shipper: Shipper): Observable<Shipper> {
    return this.shipperDataService.updateShipper(shipper);
  }

  updateShipperCompany(shipperID: number, company: Company) {
    return this.shipperDataService.updateShipperCompany(shipperID, company);
  }
}
