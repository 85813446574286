import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ROUTE_SIGNIN } from './shared/routes';
import { SignInComponent } from './sign-in/sign-in.component';
import { ROUTE_FORGOT_PASSWORD } from './forgot-password/shared/routes';
import { ForgotPasswordComponent } from './forgot-password/forgot-password/forgot-password.component';

export const routes: Routes = [
    { path: ROUTE_SIGNIN, component: SignInComponent},
    { path: ROUTE_FORGOT_PASSWORD, component : ForgotPasswordComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedAuthenticationFeatureAuthenticationRoutingModule { }
