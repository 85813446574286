<fleetoperate-delivery-shipper-app-toolbar
  [data]="user"
  [profilePath]="shipperProfilePath"
  (logout)="onLogout()"
></fleetoperate-delivery-shipper-app-toolbar>
<div class="container" fxLayout="column">
  <fleetoperate-header [title]="title" [actions]="actions"></fleetoperate-header>
  <form class="shipment-summary-filters" fxLayout>
    <mat-form-field fxFlexAlign="center">
      <input matInput placeholder="Search" [formControl]="idCntrl" (input)="onSearchInput()" #idInput />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    <mat-form-field fxFlex>
      <span matPrefix>Status filter:&nbsp;</span>
      <mat-chip-list #chipList aria-label="Status selection">
        <mat-chip *ngFor="let status of currentStatus" (removed)="remove(status)">
          {{ status }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          #statusInput
          [formControl]="statusCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (blur)="clearInput()"
        />
      </mat-chip-list>

      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let status of (filteredStatus | async)" [value]="status">
          {{ status }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <button mat-raised-button type="button" fxFlexAlign="center" (click)="clearFilters()">Clear</button>
    <button mat-raised-button class="mat-icons" type="button" fxFlexAlign="center" (click)="refreshShipments()">
      <mat-icon>refresh</mat-icon>
    </button>
  </form>

  <div class="table-container margin-top-md margin-bottom-md">
    <table mat-table matSort (matSortChange)="sortShipments($event)" [dataSource]="filteredShipments">
      <ng-container matColumnDef="shipmentId">
        <th mat-header-cell *matHeaderCellDef>SHIPMENT ID</th>
        <td mat-cell *matCellDef="let element">{{ element.shipmentID }}</td>
      </ng-container>

      <ng-container matColumnDef="mode">
        <th mat-header-cell *matHeaderCellDef>MODE</th>
        <td mat-cell *matCellDef="let element">{{ element.mode }}</td>
      </ng-container>

      <ng-container matColumnDef="shipmentReference">
        <th mat-header-cell *matHeaderCellDef>SHIP. REF#</th>
        <td mat-cell *matCellDef="let element">{{ element.shipmentReferenceNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>PRICE</th>
        <td mat-cell *matCellDef="let element">{{ element.formattedOfferRate }}</td>
      </ng-container>

      <ng-container matColumnDef="origin">
        <th mat-header-cell *matHeaderCellDef>ORIGIN</th>
        <td mat-cell *matCellDef="let element">{{ element.origin }}</td>
      </ng-container>

      <ng-container matColumnDef="pickup">
        <th mat-header-cell *matHeaderCellDef>ESTIMATED PICKUP</th>
        <td mat-cell *matCellDef="let element">{{ element.formattedPickupDateAndTime }}</td>
      </ng-container>

      <ng-container matColumnDef="destination">
        <th mat-header-cell *matHeaderCellDef>DESTINATION</th>
        <td mat-cell *matCellDef="let element">{{ element.destination }}</td>
      </ng-container>

      <ng-container matColumnDef="delivery">
        <th mat-header-cell *matHeaderCellDef>ESTIMATED DELIVERY</th>
        <td mat-cell *matCellDef="let element">{{ element.formattedDeliveryDateAndTime }}</td>
      </ng-container>

      <ng-container matColumnDef="currentLocation">
        <th mat-header-cell *matHeaderCellDef>CURRENT LOCATION</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.currentLocation">
            {{ element.currentLocation.address }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>STATUS</th>
        <td mat-cell *matCellDef="let element">
          <div class="shipment-chip {{ element.color }}">
            {{ element.status }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>ACTION</th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout fxLayoutGap="10px">
            <button
              *ngFor="let action of element.actions"
              mat-raised-button
              [disabled]="action.disabled"
              (click)="action.action()"
              class="action"
            >
              <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
              {{ action.name }}
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div class="table-message">
      <mat-spinner class="margin-top-md padding-bottom-md" *ngIf="loading"></mat-spinner>
      <p *ngIf="(!filteredShipments || filteredShipments.length <= 0) && !loading">No shipments found.</p>
    </div>
  </div>
  <!-- <mat-paginator [length]="length" [pageSize]="pageSizeOptions[0]" [pageSizeOptions]="pageSizeOptions"
    (page)="handlePage($event)">
  </mat-paginator> -->
</div>
