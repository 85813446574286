import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IsLoadingService } from '@service-work/is-loading';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CheckForUpdateService } from '@fleetoperate/shared/util';
import { PushMessagingService } from '@fleetoperate/shared/messaging/data-access-messaging';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoading: Observable<boolean>;
  isProd: boolean;
  ribbonText: string;
  ribbonHoverText: string;
  ribbonColour: string;

  constructor(
    private readonly isLoadingService: IsLoadingService,
    private readonly router: Router,
    private readonly checkForUpdateService: CheckForUpdateService,
    private readonly pushMessagingService: PushMessagingService,
    @Inject('environmentData') environmentData: any
  ) {
    this.isProd = environmentData.production;
    if (!environmentData.production) {
      this.ribbonText = environmentData.ribbonText;
      this.ribbonColour = environmentData.ribbonColour;
      this.ribbonHoverText = environmentData.apiUrl;
    }
  }

  ngOnInit() {
    // Note, because `IsLoadingService#isLoading$()` returns
    // a new observable each time it is called, it shouldn't
    // be called directly inside a component template.
    this.isLoading = this.isLoadingService.isLoading$();

    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe(event => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
      });
  }
}
