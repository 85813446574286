import { Injectable, Inject } from '@angular/core';
import { FleetoperateApiService, Response } from '@fleetoperate/shared/data-access';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { NotificationSubscription } from './models/notification-subscription';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SharedMessagingDataAccessMessagingModule } from './shared-messaging-data-access-messaging.module';

const PATH_SUBSCRIBE = '/notification-bindings/{user_id}/subscriptions';

@Injectable({
  providedIn: SharedMessagingDataAccessMessagingModule
})
export class PushMessagingApiService {
  constructor(
    private readonly api: FleetoperateApiService,
    private readonly authService: AuthService,
    @Inject('apiUrl') private apiUrl: string
  ) {}

  subscribeToNotifications(subscription: NotificationSubscription): Observable<Response> {
    const path = PATH_SUBSCRIBE.replace('{user_id}', `${this.authService.userID}`);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, subscription, this.authService.token)));
  }
}
