
<form
  [formGroup]="userInfoForm"
  class="default-background padding-top-lg"
  *ngIf="shipper"
  #userInfoFormRef="ngForm"
  (ngSubmit)="onUserInfoFormSubmit(userInfoFormRef)"
  fxLayout="column"
>
  <span class="padding-bottom-sm">{{userInfoFormMessage}}</span>
  <div class="margin-bottom-md margin-left-sm">
    <mat-form-field fxFlex class="margin-right-md">
      <input
        matInput
        [readonly]="userInfoReadOnly"
        placeholder="First Name"
        formControlName="firstName"
        required
      />
      <mat-error *ngIf="firstName.touched && firstName.invalid">
        {{getFirstNameErrorMessage()}}
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex class="margin-right-md margin-left-sm">
      <input
        matInput
        [readonly]="userInfoReadOnly"
        placeholder="Last Name"
        formControlName="lastName"
        required
      />
      <mat-error *ngIf="lastName.touched && lastName.invalid">
        {{getLastNameErrorMessage()}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="margin-bottom-md">
    <mat-form-field fxFlex class="margin-right-md margin-left-sm">
      <input matInput placeholder="Email" formControlName="email" required />
    </mat-form-field>
    <mat-form-field fxFlex class="margin-right-md margin-left-sm">
      <input
        matInput
        [readonly]="userInfoReadOnly"
        placeholder="Phone number"
        formControlName="phoneNumber"
        required
      />
      <mat-error *ngIf="phoneNumber.touched && phoneNumber.invalid">
        {{getPhoneNumberErrorMessage()}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class=" margin-top-lg margin-right-lg margin-bottom-lg">
    <button
      mat-raised-button
      type="submit"
      class="formButtons"
      [disabled]="userInfoForm.pristine || !userInfoForm.valid || loading"
    >
      Save
    </button>
  </div>
</form>

