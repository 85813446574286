import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FleetoperateToolbarComponent } from './fleetoperate-toolbar/fleetoperate-toolbar.component';

@NgModule({
  declarations: [FleetoperateToolbarComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatMenuModule],
  exports: [FleetoperateToolbarComponent]
})
export class SharedUiFleetoperateToolbarModule {}
