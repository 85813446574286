import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ForgotPasswordModel } from '../shared/forgot-password-model';
import { ForgotPasswordService } from '../forgot-password.service';
import { ROUTE_NOTIFICATION_SENT } from '../shared/routes';
import { LoggerService } from '@fleetoperate/shared/util';
import { ROUTE_SIGNIN } from '../../shared/routes';
import { first } from 'rxjs/operators';

const RequiredFieldMessage = 'You must enter a value';
const InvalidEmailAddress = 'Invalid email address';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  loading: boolean;
  message: string;
  errorMessage: string;
  isDeliveryShipper: boolean;

  constructor(private readonly fb: FormBuilder,
              private readonly router: Router,
              @Inject('appName') private readonly appName: string,
              private readonly forgotPasswordService: ForgotPasswordService,
              private readonly logger: LoggerService) {
    this.loading = false;
    this.message = `Please specify your email address to receive instructions for resetting it.
     If an account exists by that email, we will send a password reset.`;

    this.isDeliveryShipper = this.appName === 'delivery-shipper' ? true : false;

    this.errorMessage = undefined;

    this.form = this.createForm();
  }

  ngOnInit() {
  }

  onSubmit(forgotPasswordForm: NgForm) {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const model = this.prepareSaveModel();

    this.forgotPasswordService.sendForgotPasswordNotice(model).pipe(first()).subscribe((result: ForgotPasswordModel) => {
      this.errorMessage = undefined;
      this.logger.log('Reset form');
      this.form.reset();
      forgotPasswordForm.resetForm();
      this.loading = false;
      this.navigateToNotificationSent();
    }, (error: any) => {
      this.errorMessage = error.message;
      this.loading = false;
    });
  }

  onNavigateToSignIn(): void {
    this.router.navigate([`/${ROUTE_SIGNIN}`]);
  }

  get email() { return this.form.get('email'); }

  getEmailErrorMessage() {
    const errors = this.email.errors;
    return errors.required ? RequiredFieldMessage :
      errors.pattern ? InvalidEmailAddress : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]]
    });

    return form;
  }

  private prepareSaveModel(): ForgotPasswordModel {
    const formModel = this.form.value;

    const model = {
      username: formModel.email as string,
    } as ForgotPasswordModel;

    return model;
  }

  navigateToNotificationSent(): void {
    this.router.navigate([`/${ROUTE_NOTIFICATION_SENT}`]);
  }

}
