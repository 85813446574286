import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsubscribeEmailDataService } from './unsubscribe-email-data/unsubscribe-email-data.service';
import { UnsubscribeEmailApiService } from './unsubscribe-email-data/unsubscribe-email-api.service';

@NgModule({
  imports: [CommonModule],
  providers: [UnsubscribeEmailDataService, UnsubscribeEmailApiService]
})
export class SharedUnsubscribeEmailDataAccessUnsubscribeEmailModule {}
