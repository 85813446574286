import { Bid } from './bid';
import { Action } from './action';

export class Quote {
  id: string;
  shipmentID: string;
  actorID: string;
  actor: string;
  actorName: string;
  actorPicture: string;
  status: string;
  primaryOffer: number;
  secondaryOffer: number;
  message: string;
  bids: Bid[];
  actions: Action[];
}
