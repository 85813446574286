import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrencyFormatService } from '@fleetoperate/shared/util';

class Action {
  name: string;
  action: () => any;
  disabled: boolean;
  color: string;
}

export interface DialogData {
  actorName: string;
  actorPicture: string;
  primaryOfferRate: number;
  isAcceptedDialog: boolean;
  actions: Action[];
}

@Component({
  selector: 'fleetoperate-accept-quote-confirmation-dialog',
  templateUrl: './accept-quote-confirmation-dialog.component.html',
  styleUrls: ['./accept-quote-confirmation-dialog.component.scss']
})

export class AcceptQuoteConfirmationDialogComponent implements OnInit {

  acceptedQuoteMessage = "The driver will be notified";
  declinedQuoteMessage = "Are you sure you want to decline?";
  constructor(
    private dialogRef: MatDialogRef<AcceptQuoteConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly currencyFormatService: CurrencyFormatService) { }

  ngOnInit() { }

  formatCurrency(offerRate: number) {
    return this.currencyFormatService.convertToCurrency(offerRate);
  }
}
