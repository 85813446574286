import { Component, OnInit, Input } from '@angular/core';
import { Shipment } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';

@Component({
  selector: 'fleetoperate-shipment-map',
  templateUrl: './shipment-map.component.html',
  styleUrls: ['./shipment-map.component.scss']
})
export class ShipmentMapComponent implements OnInit {

  @Input() shipment: Shipment;

  renderOptions = {
    suppressMarkers: true
  };

  markerOptions = {
    origin: {
      icon: '/../../../assets/img/Origin.svg'
    },
    destination: {
      icon: '/../../../assets/img/Destination.svg'
    },
  };

  origin: any;
  destination: any;

  constructor() { }

  ngOnInit() {
    this.setDirection(this.shipment.origin, this.shipment.destination);
  }

  setDirection(origin, destination) {
    this.origin = origin;
    this.destination = destination;
  }
}
