import { Selector, State, Action, StateContext } from '@ngxs/store';
import { SetShipper, ClearShipper, SetShipperCompany } from './shipper-data-actions';
import { Company } from '../../models/company';
import { Shipper } from '../../..';

const shipperDefault = {
  id: undefined,
  operateInUS: undefined,
  status: undefined,
  dotNumber: undefined,
  firstName: undefined,
  lastName: undefined,
  companyNumber: undefined,
  companyName: undefined,
  companyID: undefined,
  address: undefined,
  city: undefined,
  state: undefined,
  zipCode: undefined,
  country: undefined,
  email: undefined,
  phoneNumber: undefined,
  company: undefined
} as ShipperStateModel;

export interface ShipperStateModel {
  id: number;
  operateInUS: boolean;
  status: string;
  dotNumber: string;
  firstName: string;
  lastName: string;
  companyNumber: number;
  companyName: string;
  companyID: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  email: string;
  phoneNumber: string;
  company: Company;
}

export interface ShipperDataStateModel {
  shipper: ShipperStateModel;
}

@State<ShipperDataStateModel>({
  name: 'shipperdata',
  defaults: {
    shipper: shipperDefault
  }
})
export class ShipperDataState {

  constructor() { }

  @Selector() static shipper(state: ShipperDataStateModel) {
    return state.shipper;
  }

  @Action(SetShipper)
  setShipper(ctx: StateContext<ShipperDataStateModel>, { payload }: SetShipper) {
      const shipperDataStateModel = ctx.getState();

      let company: Company;
      if (shipperDataStateModel && shipperDataStateModel.shipper) {
        company = Object.assign({}, shipperDataStateModel.shipper.company);
      }

      let shipper;
      if (payload) {
        shipper = Object.assign({}, payload);
      }

      shipper.company = company;

      ctx.patchState({ shipper: shipper });
  }

  @Action(SetShipperCompany)
  setShipperCompany(ctx: StateContext<ShipperDataStateModel>, { payload }: SetShipperCompany) {
    const shipperDataStateModel = ctx.getState();
    
    let shipper: Shipper;
    if (shipperDataStateModel && shipperDataStateModel.shipper) {
      shipper = Object.assign({}, shipperDataStateModel.shipper, {company: undefined});
    }

    shipper.company = payload;

    ctx.patchState({ shipper: shipper });
  }

  @Action(ClearShipper)
  clearShipper(ctx: StateContext<ShipperDataStateModel>, action: ClearShipper) {
      ctx.patchState({ shipper: undefined });
  }
}
