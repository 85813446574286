
<div fxLayout fxFlex fxLayoutAlign="center center">
  <ng-container *ngFor="let indicator of indicators; let last = last">
    <div *ngIf="indicator <= activeIndicator" class="circle-active">
      <div class="inner"></div>
    </div>
    <div *ngIf="indicator > activeIndicator" class="circle-inactive"></div>
    <span *ngIf="!last" fxFlex class="line" [class.line-active]="indicator < activeIndicator"></span>
  </ng-container>
</div>
