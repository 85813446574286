import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { User, AppAction } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { SettingsService } from '@fleetoperate/delivery-shipper/feature-settings';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fleetoperate-delivery-shipper-app-toolbar',
  templateUrl: './delivery-shipper-app-toolbar.component.html',
  styleUrls: ['./delivery-shipper-app-toolbar.component.scss']
})
export class DeliveryShipperAppToolbarComponent implements OnInit, OnDestroy {
  @Input() data: User;
  @Input() profilePath: string;
  @Output() logout: EventEmitter<any> = new EventEmitter();

  actions: AppAction[];

  private subscription: Subscription;

  constructor(private readonly settingsService: SettingsService) {
    this.subscription = new Subscription();
  }

  ngOnInit() {
    // this.subscription.add(
    //   this.settingsService.createActions().subscribe((appActions: AppAction[]) => {
    //     this.actions = appActions;
    //   })
    // );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onLogout(): void {
    this.logout.emit();
  }
}
