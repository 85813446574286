<form [formGroup]="deliveryForm" #deliveryFormRef="ngForm" (ngSubmit)="onDeliveryFormSubmit(deliveryFormRef)" fxLayout="column">
  <mat-expansion-panel [disabled]="!deliveryFormReadonly">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Delivery
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-error class="padding-bottom-sm">{{deliveryFormMessage}}</mat-error>
    <div fxLayout class="margin-bottom-md">
      <mat-form-field fxFlex class="margin-right-md">
        <input matInput [readonly]="deliveryFormReadonly" placeholder="Business name" formControlName="businessName" required>
        <mat-error *ngIf="businessName.touched && businessName.invalid">
          {{getBusinessNameErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex class="margin-right-md">
        <input matInput [readonly]="deliveryFormReadonly" placeholder="Contact name" formControlName="contactName" required>
        <mat-error *ngIf="contactName.touched && contactName.invalid">
          {{getContactNameErrorMessage()}}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout class="margin-bottom-md">
      <mat-form-field fxFlex class="margin-right-md">
        <input matInput [readonly]="deliveryFormReadonly" placeholder="Contact email" formControlName="contactEmail" required>
        <mat-error *ngIf="contactEmail.touched && contactEmail.invalid">
          {{getContactEmailErrorMessage()}}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex class="margin-right-md">
        <input matInput [readonly]="deliveryFormReadonly" placeholder="Contact phone" formControlName="contactPhone" required>
        <mat-error *ngIf="contactPhone.touched && contactPhone.invalid">
          {{getContactPhoneErrorMessage()}}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout class="margin-bottom-md">
      <mat-form-field fxFlex class="margin-right-md">
        <input matInput [(ngModel)]="autocompleteInput" #addressText [readonly]="deliveryFormReadonly" placeholder="Street address" formControlName="streetAddress" required>
        <mat-error *ngIf="streetAddress.touched && streetAddress.invalid">
          {{getStreetAddressErrorMessage()}}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="column" class="margin-bottom-md">
      <div fxFlex class="margin-right-md">
        <mat-checkbox [disabled]="deliveryFormReadonly" formControlName="driverAssistedDelivery">Driver assisted delivery</mat-checkbox>
        <mat-error *ngIf="driverAssistedDelivery.touched && driverAssistedDelivery.invalid">
          {{getDriverAssistedDeliveryErrorMessage()}}
        </mat-error>
      </div>
    </div>
    <div fxLayout class="margin-bottom-md">
      <mat-form-field fxFlex class="margin-right-md">
        <textarea matInput [readonly]="deliveryFormReadonly" placeholder="Instructions" formControlName="instructions"></textarea>
        <mat-error *ngIf="instructions.touched && instructions.invalid">
          {{getInstructionsErrorMessage()}}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout class="margin-bottom-md">
      <div fxLayout="column" class="margin-right-md margin-bottom-md">
        <span>Delivery time</span>
        <mat-button-toggle-group [value]="deliveryTimeGroupSelection"
                                 [disabled]="deliveryFormReadonly"
                                 (change)="onDeliveryTimeGroupChange($event)"
                                 name="deliveryTimeToggle"
                                 #deliveryTimeGroup="matButtonToggleGroup">
          <mat-button-toggle value="between">Between</mat-button-toggle>
          <mat-button-toggle value="at">At</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div fxLayout="column" class="margin-bottom-md">
        <span>Time</span>
        <div fxLayout>
          <mat-form-field fxFlex class="margin-right-md">
            <mat-hint align="start">0000</mat-hint>
            <input matInput [readonly]="deliveryFormReadonly" placeholder="" formControlName="deliveryTimeFrom">
            <mat-error *ngIf="deliveryTimeFrom.touched && deliveryTimeFrom.invalid">
              {{getDeliveryTimeFromErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <span class="margin-right-sm">EDT</span>
          <div *ngIf="deliveryTimeGroup.value == 'between'">
            <span class="margin-right-sm">to</span>
            <mat-form-field fxFlex class="margin-right-md">
              <mat-hint align="start">0000</mat-hint>
              <input matInput [readonly]="deliveryFormReadonly" placeholder="" formControlName="deliveryTimeTo" [errorStateMatcher]="errorMatcher">
              <mat-error *ngIf="(deliveryForm.errors && deliveryForm.errors.range) || (deliveryTimeTo.dirty && deliveryTimeTo.invalid)">
                {{getDeliveryTimeToErrorMessage()}}
              </mat-error>
            </mat-form-field>
            <span>EDT</span>
          </div>
        </div>
      </div>
    </div>

    <mat-action-row fxLayoutGap="20px">
      <button *ngIf="deliveryFormReadonly"
              mat-raised-button
              type="button"
              [disabled]="loading"
              (click)="onDeliveryFormEdit()">Edit</button>
      <button *ngIf="!deliveryFormReadonly"
              mat-raised-button
              type="submit"
              [disabled]="deliveryForm.pristine || !deliveryForm.valid || loading">Save</button>
      <button *ngIf="!deliveryFormReadonly"
              mat-raised-button
              type="button"
              [disabled]="loading"
              (click)="onDeliveryFormCancel(deliveryFormRef)">Cancel</button>
    </mat-action-row>
  </mat-expansion-panel>
</form>

