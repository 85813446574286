import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {
  Shipment,
  ShipperDataService,
  Quote,
  Bid,
  EVENT_TYPE_DECLINED
} from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { LoggerService } from '@fleetoperate/shared/util';
import { ROUTE_SHIPMENT_SEARCH, ROUTE_SHIPMENT_DETAILS } from '../../shared/routes';
import { ShipmentService, QuotesModel } from '../../shared/shipment.service';
import { User } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { ROUTE_SHIPPER_PROFILE } from 'libs/shipper/feature-profile/src/lib/shared/routes';
import { TabActionLink } from '@fleetoperate/shared/ui/header';
import { OverlayService, OverlayModalRef } from '@fleetoperate/shared/ui/overlay';
import { CreateLoadComponent } from '../../create-load/create-load.module';
import { COMPONENT_DATA_PASSED } from 'libs/shared/ui/overlay/src/lib/overlay/overlay.tokens';
@Component({
  selector: 'fleetoperate-shipment-quotes',
  templateUrl: './shipment-quotes.component.html',
  styleUrls: ['./shipment-quotes.component.scss']
})
export class ShipmentQuotesComponent implements OnInit, OnDestroy {
  shipperProfilePath = ROUTE_SHIPPER_PROFILE;
  shipment: Shipment;
  carrierQuotes: Quote[];
  driverQuotes: Quote[];
  noCarrierQuotes: boolean;
  noDriverQuotes: boolean;
  errorMessage: string;
  isAcceptConfirmed: boolean;
  isCountered: boolean;
  user: User;
  title: string;
  actions: Array<TabActionLink> = [];
  backAction: Function;

  private subSink: SubSink;
  private overlayRef: OverlayModalRef;

  constructor(
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly shipperDataService: ShipperDataService,
    private readonly shipmentService: ShipmentService,
    private readonly overlayService: OverlayService,
    private readonly quotesOverlayRef: OverlayModalRef,
    @Inject(COMPONENT_DATA_PASSED) private readonly dataPassed: any
  ) {
    this.subSink = new SubSink();
    this.shipment = {} as Shipment;
    this.backAction = () => this.quotesOverlayRef.close();
  }

  ngOnInit(): void {
    this.subSink.add(this.shipmentService.$message.subscribe((message: string) => (this.errorMessage = message)));
    this.subSink.add(
      this.shipmentService.$shipment.subscribe((shipment: Shipment) => {
        this.shipment = shipment;
        this.title = 'Offers for ' + shipment.shipmentID;
      })
    );
    this.subSink.add(
      this.shipmentService.$quotes.subscribe((quotes: QuotesModel) => {
        this.driverQuotes = quotes.driverQuotes;
        this.carrierQuotes = quotes.carrierQuotes;
        if (this.carrierQuotes.length === 0) {
          this.noCarrierQuotes = true;
        }
        if (this.driverQuotes.length === 0) {
          this.noDriverQuotes = true;
        }
      })
    );

    const shipmentId = this.dataPassed.shipmentId;
    if (shipmentId) {
      this.loadShipment(shipmentId);
    } else {
      this.logger.error(`Could not load shipment with id '${shipmentId}'.`);
    }
    this.user = {
      name: `${this.shipperDataService.getShipper().firstName} ${this.shipperDataService.getShipper().lastName}`
    };
    this.actions.push({
      title: 'CREATE NEW LOAD',
      clickHandler: () => this.onCreateLoad()
    } as TabActionLink);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.close();
    }
  }

  onCreateLoad(): void {
    this.overlayRef = this.overlayService.showDetailPanel({ data: CreateLoadComponent, panelClass: 'detail-panel' });
  }

  onMyShipments(): void {
    this.router.navigate([ROUTE_SHIPMENT_SEARCH]);
  }

  onLogout(): void {
    this.shipperDataService.logout();
  }

  isDeclined(bid: Bid): boolean {
    if (!bid || !bid.event) {
      return false;
    }

    return bid.event === EVENT_TYPE_DECLINED;
  }

  private loadShipment(id: string): void {
    this.errorMessage = undefined;
    this.shipmentService.loadShipment(id);
  }

  onNavigateToShipmentDetails(): void {
    this.router.navigate([ROUTE_SHIPMENT_DETAILS, this.shipment.id]);
  }
}
