import { Injectable } from '@angular/core';
import { SharedUnsubscribeEmailDataAccessUnsubscribeEmailModule } from '../shared-unsubscribe-email-data-access-unsubscribe-email.module';
import { UnsubscribeEmailApiService } from './unsubscribe-email-api.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { EmailModel } from '../model/email-model';
@Injectable({
  providedIn: SharedUnsubscribeEmailDataAccessUnsubscribeEmailModule
})
export class UnsubscribeEmailDataService {

  constructor(private readonly unsubscribeEmailApiService: UnsubscribeEmailApiService) { }

  updateEmailToUnsubscribe(model: EmailModel): Observable <Response> {
    return this.unsubscribeEmailApiService.updateEmailToUnsubscribe(model).pipe(
      map((response: any) => response.data)
    );
  }
}
