import { Injectable } from '@angular/core';
import { StorageModule } from './storage.module';
import { Storage } from './storage';

@Injectable({
  providedIn: StorageModule
})
export class StorageService {

  constructor() { }

  store(key: string, object: Object) {
    const storage = new Storage(key, object);
    sessionStorage.setItem(key, JSON.stringify(storage));
  }

  find(key: string): Object {
    const item = sessionStorage.getItem(key);
    const storage = JSON.parse(item) as Storage;
    return storage ? storage.object : undefined;
  }

  remove(key: string): void {
    sessionStorage.removeItem(key);
  }

  clearAll(): void {
    sessionStorage.clear();
  }
}
