<div fxLayout="column" fxFlex>
  <progress-bar [count]="numSteps" [activeIndicator]="step" class="padding-bottom-md"></progress-bar>
  <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex>
    <span class="mat-headline padding-bottom">All done! Verify your email</span>
    <span class="mat-subheading-2 padding-bottom-md">We have sent you a verification e-mail at {{ email }}</span>
    <div class="actions full-width padding-top-xlg">
      <button
        class="margin-bottom"
        [ngClass.lt-md]="'full-width'"
        [ngClass.gt-sm]="'half-width'"
        mat-raised-button
        color="primary"
        type="button"
        (click)="onNavigateToNextStep()"
      >
        Sign in
      </button>
    </div>
  </div>
</div>
