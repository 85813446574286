import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipperProfileComponent } from './shipper-profile/shipper-profile.component';
import { ProfileUserInfoComponent } from './profile-user-info/profile-user-info.component';
import { ProfilePasswordComponent } from './profile-password/profile-password.component';
import { ProfileCompanyInfoComponent } from './profile-company-info/profile-company-info.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedUiHeaderModule } from '@fleetoperate/shared/ui/header';
import { ProfileLoadSettingsComponent } from './profile-load-settings/profile-load-settings.component';
import { DeliveryShipperUiAppToolbarModule } from '@fleetoperate/delivery-shipper/ui-app-toolbar';

@NgModule({
  declarations: [
    ProfileUserInfoComponent,
    ProfileCompanyInfoComponent,
    ProfilePasswordComponent,
    ShipperProfileComponent,
    ProfileLoadSettingsComponent
  ],
  imports: [
    CommonModule,
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatNativeDateModule,
    MatButtonModule,
    MatInputModule,
    MatExpansionModule,
    MatIconModule,
    MatTabsModule,
    RouterModule,
    DeliveryShipperUiAppToolbarModule,
    SharedUiHeaderModule
  ]
})
export class ShipperProfileModule {}
