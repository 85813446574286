import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipperFeatureProfileRoutingModule } from './shipper-feature-profile-routing.module';
import { ShipperProfileModule } from './shipper-profile/shipper-profile.module';

@NgModule({
  imports: [
    CommonModule,
    ShipperFeatureProfileRoutingModule,
    ShipperProfileModule,
  ],
  declarations: []
})
export class ShipperFeatureProfileModule {}
