import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipmentQuotesComponent } from './shipment-quotes/shipment-quotes.component';
import { ShipmentQuoteComponent } from './shipment-quote/shipment-quote.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { AcceptQuoteConfirmationDialogModule } from '../shared/accept-quote-confirmation-dialog/accept-quote-confirmation-dialog.module';
import { AcceptQuoteConfirmationDialogComponent } from '../shared/accept-quote-confirmation-dialog/accept-quote-confirmation-dialog.component';
import { CounterOfferQuoteDialogModule } from '../shared/counter-offer-quote-dialog/counter-offer-quote-dialog.module';
import { CounterOfferQuoteDialogComponent } from '../shared/counter-offer-quote-dialog/counter-offer-quote-dialog.component';
import { SharedShipperUiShipperModule } from '@fleetoperate/shared/shipper/ui-shipper';
import { ShipmentService } from '../shared/shipment.service';
import { ToastModule, CurrencyFormatModule } from '@fleetoperate/shared/util';
import { SharedUiFleetoperateToolbarModule } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { SharedUiActionsModule } from '@fleetoperate/shared/ui/actions';
import { SharedUiHeaderModule } from '@fleetoperate/shared/ui/header';
import { CreateLoadComponent } from '../create-load/create-load.module';
import { ErrorDataModule } from '@fleetoperate/shared/data-access-error';
@NgModule({
  declarations: [ShipmentQuotesComponent, ShipmentQuoteComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatDialogModule,
    FlexLayoutModule,
    AcceptQuoteConfirmationDialogModule,
    CounterOfferQuoteDialogModule,
    SharedShipperUiShipperModule,
    ToastModule,
    CurrencyFormatModule,
    SharedUiFleetoperateToolbarModule,
    SharedUiHeaderModule,
    SharedUiActionsModule,
    ErrorDataModule
  ],
  entryComponents: [AcceptQuoteConfirmationDialogComponent, CounterOfferQuoteDialogComponent, CreateLoadComponent],
  providers: [ShipmentService]
})
export class ShipmentQuotesModule {}
