import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  NgZone
} from '@angular/core';
import { Shipment, ShipmentDataService } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import {
  FormGroup,
  FormBuilder,
  NgForm,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  FormControl,
  FormGroupDirective
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { SubSink } from 'subsink';
import { ErrorStateMatcher } from '@angular/material/core';
import * as moment from 'moment-timezone';
import { GoogleAddressAutocompleteService } from '@fleetoperate/shared/util';

const RequiredFieldMessage = 'Required';
const ValidTimeFormatMessage = 'Must be 0000 format';
const ERROR_MESSAGE = 'There was an error. Please try again.';
const PICKUP_TIME_SELECTION_BETWEEN = 'between';
const PICKUP_TIME_SELECTION_AT = 'at';
const ValidDeliveryToMessage = 'Please enter a valid time that occurs after the delivery from time.';
const MaximumNumberMessage = `Please enter a valid time. Must be less than 2359 characters`;
const validAddressMessage = 'Please enter a full valid address';
const ValidContactPhoneFormat =
  'Please enter a valid 11 digit phone number with no hyphens or spaces that begins with 1';
const maxTime = 2359;

class AddressModel {
  fullAddress: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}

@Component({
  selector: 'fleetoperate-edit-delivery',
  templateUrl: './edit-delivery.component.html',
  styleUrls: ['./edit-delivery.component.scss']
})
export class EditDeliveryComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() shipment: Shipment;
  @Output() update: EventEmitter<boolean> = new EventEmitter();

  @Output() valid = new EventEmitter();
  @Output() dirty = new EventEmitter();

  deliveryForm: FormGroup;
  deliveryFormMessage: string;
  deliveryFormReadonly: boolean;
  deliveryTimeGroupSelection: string;
  loading: boolean;

  @Input() autocompleteInput: string;
  @ViewChild('addressText', { static: false }) addressText: any;

  errorMatcher = new TimeToInvalidErrorMatcher();

  addressModel: AddressModel;

  private subs = new SubSink();

  constructor(
    private readonly fb: FormBuilder,
    private googleAddressAutocompleteService: GoogleAddressAutocompleteService,
    private readonly shipmentDataService: ShipmentDataService
  ) {
    this.deliveryForm = this.createDeliveryForm();
    this.deliveryFormMessage = undefined;
    this.deliveryFormReadonly = true;

    this.subs.add(
      this.deliveryForm.valueChanges.subscribe((value: any) => {
        this.valid.emit(this.deliveryForm.valid);
        this.dirty.emit(this.deliveryForm.dirty);
      })
    );

    this.loading = false;
  }

  ngOnInit(): void {
    this.addressModel = new AddressModel();
  }

  ngOnChanges(): void {
    if (this.shipment) {
      this.resetDeliveryForm(this.shipment, undefined);
      this.googleAddressAutocompleteService.parseAddress(this.shipment.destination);
    }
  }

  ngAfterViewInit(): void {
    this.googleAddressAutocompleteService.getPlaceAutocomplete(this.deliveryForm, this.addressText, 'streetAddress');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onDeliveryFormSubmit(deliveryFormRef: NgForm): void {
    if (!this.deliveryForm.valid) {
      return;
    }

    this.loading = true;

    const newShipment = this.prepareDeliverySaveModel(this.shipment, this.deliveryForm);

    this.shipmentDataService
      .updateShipment(newShipment)
      .pipe(first())
      .subscribe(
        (shipment: Shipment) => {
          this.deliveryFormMessage = undefined;
          this.loading = false;
          this.update.emit(null);
          this.deliveryFormReadonly = true;
        },
        (error: any) => {
          this.deliveryFormMessage = error.message || ERROR_MESSAGE;
          this.loading = false;
        }
      );
  }

  onDeliveryFormEdit(): void {
    this.deliveryFormReadonly = false;
  }

  onDeliveryFormCancel(deliveryFormRef: NgForm): void {
    this.deliveryFormReadonly = true;
    this.resetDeliveryForm(this.shipment, deliveryFormRef);
  }

  onDeliveryTimeGroupChange($event: MatButtonToggleChange): void {
    this.deliveryTimeGroupSelection = $event.value;
  }

  get businessName() {
    return this.deliveryForm.get('businessName');
  }
  get contactName() {
    return this.deliveryForm.get('contactName');
  }
  get contactEmail() {
    return this.deliveryForm.get('contactEmail');
  }
  get contactPhone() {
    return this.deliveryForm.get('contactPhone');
  }
  get streetAddress() {
    return this.deliveryForm.get('streetAddress');
  }
  get driverAssistedDelivery() {
    return this.deliveryForm.get('driverAssistedDelivery');
  }
  get instructions() {
    return this.deliveryForm.get('instructions');
  }
  get deliveryTimeFrom() {
    return this.deliveryForm.get('deliveryTimeFrom');
  }
  get deliveryTimeTo() {
    return this.deliveryForm.get('deliveryTimeTo');
  }

  getBusinessNameErrorMessage(): string {
    const errors = this.businessName.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getContactNameErrorMessage(): string {
    const errors = this.contactName.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getContactEmailErrorMessage(): string {
    const errors = this.contactEmail.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getContactPhoneErrorMessage(): string {
    const errors = this.contactPhone.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? ValidContactPhoneFormat : '';
  }

  getStreetAddressErrorMessage(): string {
    const errors = this.streetAddress.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? validAddressMessage : '';
  }

  getDriverAssistedDeliveryErrorMessage(): string {
    const errors = this.driverAssistedDelivery.errors;
    return '';
  }

  getInstructionsErrorMessage(): string {
    const errors = this.instructions.errors;
    return '';
  }

  getDeliveryTimeFromErrorMessage(): string {
    const errors = this.deliveryTimeFrom.errors;
    return errors.max ? MaximumNumberMessage : errors.pattern ? ValidTimeFormatMessage : '';
  }

  getDeliveryTimeToErrorMessage(): string {
    const errors = this.deliveryTimeTo.errors;
    return errors && errors.pattern
      ? ValidTimeFormatMessage
      : errors && errors.max
      ? MaximumNumberMessage
      : this.deliveryForm && this.deliveryForm.errors && this.deliveryForm.errors.range
      ? ValidDeliveryToMessage
      : '';
  }
  private createDeliveryForm(shipment?: Shipment): FormGroup {
    const formModel = {
      businessName: shipment && shipment.delivery.businessName ? shipment.delivery.businessName : '',
      contactName: shipment && shipment.delivery.contactName ? shipment.delivery.contactName : '',
      contactEmail: shipment && shipment.delivery.contactEmail ? shipment.delivery.contactEmail : '',
      contactPhone: shipment && shipment.delivery.contactPhone ? shipment.delivery.contactPhone : '',
      streetAddress: shipment && shipment.destination ? shipment.destination : '',
      city: shipment && shipment.delivery.city ? shipment.delivery.city : '',
      state: shipment && shipment.delivery.state ? shipment.delivery.state : '',
      zipcode: shipment && shipment.delivery.zipcode ? shipment.delivery.zipcode : '',
      driverAssistedDelivery:
        shipment && shipment.delivery.driverAssistedDelivery ? shipment.delivery.driverAssistedDelivery : false,
      instructions: shipment && shipment.delivery.instructions ? shipment.delivery.instructions : '',
      deliveryTimeFrom:
        shipment && shipment.delivery.deliveryDateFrom ? this.getTime(shipment.delivery.deliveryDateFrom) : '',
      deliveryTimeTo: shipment && shipment.delivery.deliveryDateTo ? this.getTime(shipment.delivery.deliveryDateTo) : ''
    };

    const form = this.fb.group(
      {
        businessName: [formModel.businessName, Validators.required],
        contactName: [formModel.contactName, Validators.required],
        contactEmail: [formModel.contactEmail, Validators.required],
        contactPhone: [formModel.contactPhone, Validators.required],
        streetAddress: [
          formModel.streetAddress,
          [
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*,[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*, [A-Z]{2} [A-Z0-9 ]*, [A-Za-z]+$'
            )
          ]
        ],
        city: [formModel.city],
        state: [formModel.state],
        zipcode: [formModel.zipcode],
        driverAssistedDelivery: [formModel.driverAssistedDelivery],
        instructions: [formModel.instructions],
        deliveryTimeFrom: [formModel.deliveryTimeFrom, [Validators.pattern('^[0-9]{4}$'), Validators.max(maxTime)]],
        deliveryTimeTo: [formModel.deliveryTimeTo, [Validators.pattern('^[0-9]{4}$'), Validators.max(maxTime)]]
      },
      {
        validator: this.rangeIsNotValid()
      }
    );

    this.deliveryTimeGroupSelection = formModel.deliveryTimeTo
      ? PICKUP_TIME_SELECTION_BETWEEN
      : PICKUP_TIME_SELECTION_AT;

    return form;
  }
  private resetDeliveryForm(shipment: Shipment, deliveryFormRef: NgForm): void {
    if (deliveryFormRef) {
      deliveryFormRef.resetForm();
    }

    const formModel = {
      businessName: shipment && shipment.delivery.businessName ? shipment.delivery.businessName : '',
      contactName: shipment && shipment.delivery.contactName ? shipment.delivery.contactName : '',
      contactEmail: shipment && shipment.delivery.contactEmail ? shipment.delivery.contactEmail : '',
      contactPhone: shipment && shipment.delivery.contactPhone ? shipment.delivery.contactPhone : '',
      streetAddress: shipment && shipment.destination ? shipment.destination : '',
      driverAssistedDelivery:
        shipment && shipment.delivery.driverAssistedDelivery ? shipment.delivery.driverAssistedDelivery : false,
      instructions: shipment && shipment.delivery.instructions ? shipment.delivery.instructions : '',
      deliveryTimeFrom:
        shipment && shipment.delivery.deliveryDateFrom ? this.getTime(shipment.delivery.deliveryDateFrom) : '',
      deliveryTimeTo: shipment && shipment.delivery.deliveryDateTo ? this.getTime(shipment.delivery.deliveryDateTo) : ''
    };
    this.deliveryForm.reset(formModel);
    this.deliveryTimeGroupSelection = formModel.deliveryTimeTo
      ? PICKUP_TIME_SELECTION_BETWEEN
      : PICKUP_TIME_SELECTION_AT;
  }

  private prepareDeliverySaveModel(shipment: Shipment, deliveryForm: FormGroup): Shipment {
    const formModel = deliveryForm.value;
    const saveModel = Object.assign({}, shipment);

    const dateTimeFrom = this.addDateTime(
      this.shipment.delivery.deliveryDateFrom,
      formModel.deliveryTimeFrom as string
    );
    const dateTimeTo = this.addDateTime(this.shipment.delivery.deliveryDateFrom, formModel.deliveryTimeTo as string);

    const dateTimeFromUTC = this.convertToUTC(dateTimeFrom);
    const dateTimeToUTC = this.convertToUTC(dateTimeTo);
    this.addressModel = this.googleAddressAutocompleteService.parseAddress(this.streetAddress.value);

    saveModel.destination = this.addressModel.fullAddress;
    saveModel.delivery.businessName = formModel.businessName as string;
    saveModel.delivery.contactName = formModel.contactName as string;
    saveModel.delivery.contactEmail = formModel.contactEmail as string;
    saveModel.delivery.contactPhone = formModel.contactPhone as string;
    saveModel.delivery.streetAddress = this.addressModel.streetAddress;
    saveModel.delivery.city = this.addressModel.city;
    saveModel.delivery.state = this.addressModel.state;
    saveModel.delivery.zipcode = this.addressModel.zipcode;
    saveModel.delivery.country = this.addressModel.country;
    saveModel.delivery.driverAssistedDelivery = formModel.driverAssistedDelivery as boolean;
    saveModel.delivery.instructions = (formModel.instructions as string) || undefined;
    saveModel.delivery.deliveryDateFrom = dateTimeFromUTC;
    saveModel.delivery.deliveryDateTo = dateTimeToUTC || undefined;

    saveModel.delivery.deliveryDateTo =
      this.deliveryTimeGroupSelection === PICKUP_TIME_SELECTION_BETWEEN ? saveModel.delivery.deliveryDateTo : undefined;

    return saveModel;
  }

  private getTime(dateTime: string): string {
    return moment
      .utc(dateTime)
      .tz('America/New_York')
      .format('HHmm');
  }

  private addDateTime(date: string, time: string): string {
    const momentDate = moment(date).format('YYYY-MM-DDT');
    const momentTime = moment(time, 'HHmm').format('HH:mm:ss');

    return momentDate + momentTime;
  }

  private convertToUTC(dateTime: string): string {
    const formDateTime = moment(dateTime).format('YYYY-MM-DDTHH:mm:ss');
    const dateTimeUTC = moment
      .tz(formDateTime, 'America/New_York')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ssZ');

    return dateTimeUTC === 'Invalid date' ? undefined : dateTimeUTC;
  }

  rangeIsNotValid(): ValidatorFn {
    return (form: FormGroup): ValidationErrors => {
      const condition =
        form.get('deliveryTimeTo').value && form.get('deliveryTimeFrom').value > form.get('deliveryTimeTo').value;
      return condition ? { range: true } : null;
    };
  }
}

class TimeToInvalidErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.errors) || (form && form.errors && form.errors.range);
  }
}
