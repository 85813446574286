import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TabActionLink } from '../models/tab-action-link.model';

@Component({
  selector: 'fleetoperate-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() backAction: Function;
  @Input() title: string;
  @Input() actions: Array<TabActionLink> = [];

  constructor(private readonly router: Router) {}

  ngOnInit() {}
}
