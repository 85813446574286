export const QUOTE_STATUS_ACCEPTED = 'accepted';
export const QUOTE_STATUS_DRIVER_ACCEPTED = 'driver accepted';
export const QUOTE_STATUS_OWNER_ACCEPTED = 'owner accepted';
export const QUOTE_STATUS_NOT_ACCEPTED = 'not accepted';
export const QUOTE_STATUS_DECLINED_DISABLED = 'declined disabled';
export const QUOTE_STATUS_UNDO_DECLINED_DISABLED = 'undo declined disabled';
export const QUOTE_STATUS_CONFIRMED = 'confirmed';
export const QUOTE_STATUS_CANCELLED = 'cancelled';
export const QUOTE_STATUS_COUNTERED = 'countered';
export const QUOTE_STATUS_OWNER_COUNTERED = 'owner countered';
export const QUOTE_STATUS_PASSED = 'passed';
export const QUOTE_STATUS_OWNER_DECLINED = 'owner declined';
