import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FleetoperateApiService, Response } from '@fleetoperate/shared/data-access';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { ShipperDataModule } from './shipper-data.module';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Shipper } from '../models/shipper';
import { Company } from '../models/company';

const PATH_SHIPPERS_ID = '/shippers/{id}';

@Injectable({
  providedIn: ShipperDataModule
})
export class ShipperApiService {
  constructor(
    private readonly api: FleetoperateApiService,
    private readonly authService: AuthService,
    @Inject('apiUrl') private apiUrl: string,
    private readonly http: HttpClient
  ) {}

  getShippers(): Observable<Response> {
    /*
                return this.authService.isAuthenticated().pipe(
                  mergeMap(() => this.api.get(`${this.apiUrl}${PATH_SHIPPERS}?include=company`, {}, this.authService.token))
                );
                */
    const shippers = [
      {
        id: 2345835202834098,
        operateInUS: true,
        dotNumber: 'dotNumber',
        firstName: 'Marcus',
        lastName: 'Aurelius',
        status: 'Pending',
        companyNumber: 50045,
        companyName: 'Equifax Holdings',
        companyID: 'T0445x08',
        address: '505 Northridge Cres.',
        city: 'Wilmette',
        state: 'Illinois',
        zipCode: '604776',
        country: 'US',
        email: 'Marc.A@gmail.com',
        phoneNumber: '227-918-7068',
        company: {
          id: '573249xtuend30513sd84',
          companyName: 'Equifax Holdings',
          email: 'Marc.A@gmail.com',
          phoneNumber: '227-918-7068',
          address: '505 Northridge Cres.',
          city: 'Wilmette',
          state: 'Illinois',
          zipCode: '604776',
          country: 'US'
        } as Company
      },
      {
        id: 2345835202834098,
        operateInUS: true,
        dotNumber: 'dotNumber',
        firstName: 'Marcus',
        lastName: 'Aurelius',
        status: 'Pending',
        companyNumber: 50045,
        companyName: 'Equifax Holdings',
        companyID: 'T0445x08',
        address: '505 Northridge Cres.',
        city: 'Wilmette',
        state: 'Illinois',
        zipCode: '604776',
        country: 'US',
        email: 'Marc.A@gmail.com',
        phoneNumber: '227-918-7068',
        company: {
          id: '573249xtuend30513sd84',
          companyName: 'Equifax Holdings',
          email: 'Marc.A@gmail.com',
          phoneNumber: '227-918-7068',
          address: '505 Northridge Cres.',
          city: 'Wilmette',
          state: 'Illinois',
          zipCode: '604776',
          country: 'US'
        } as Company
      },
      {
        id: 2345835202834098,
        operateInUS: true,
        dotNumber: 'dotNumber',
        firstName: 'Marcus',
        lastName: 'Aurelius',
        status: 'Pending',
        companyNumber: 50045,
        companyName: 'Equifax Holdings',
        companyID: 'T0445x08',
        address: '505 Northridge Cres.',
        city: 'Wilmette',
        state: 'Illinois',
        zipCode: '604776',
        country: 'US',
        email: 'Marc.A@gmail.com',
        phoneNumber: '227-918-7068',
        company: {
          id: '573249xtuend30513sd84',
          companyName: 'Equifax Holdings',
          email: 'Marc.A@gmail.com',
          phoneNumber: '227-918-7068',
          address: '505 Northridge Cres.',
          city: 'Wilmette',
          state: 'Illinois',
          zipCode: '604776',
          country: 'US'
        } as Company
      },
      {
        id: 2345835202834098,
        operateInUS: true,
        dotNumber: 'dotNumber',
        firstName: 'Marcus',
        lastName: 'Aurelius',
        status: 'Pending',
        companyNumber: 50045,
        companyName: 'Equifax Holdings',
        companyID: 'T0445x08',
        address: '505 Northridge Cres.',
        city: 'Wilmette',
        state: 'Illinois',
        zipCode: '604776',
        country: 'US',
        email: 'Marc.A@gmail.com',
        phoneNumber: '227-918-7068',
        company: {
          id: '573249xtuend30513sd84',
          companyName: 'Equifax Holdings',
          email: 'Marc.A@gmail.com',
          phoneNumber: '227-918-7068',
          address: '505 Northridge Cres.',
          city: 'Wilmette',
          state: 'Illinois',
          zipCode: '604776',
          country: 'US'
        } as Company
      }
    ] as Shipper[];

    const response = {
      status: 200,
      data: shippers
    } as Response;

    return of(response);
  }

  getShipper(): Observable<Response> {
    const path = PATH_SHIPPERS_ID.replace('{id}', this.authService.userID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}?include=company`, {}, this.authService.token)));
  }

  updateShipper(shipper: Shipper): Observable<Response> {
    const path = PATH_SHIPPERS_ID.replace('{id}', `${shipper.id}`);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, shipper, this.authService.token)));
  }

  updateShipperCompany(shipperID: number, company: Company): Observable<Response> {
    const path = PATH_SHIPPERS_ID.replace('{id}', `${shipperID}/company/${company.id}`);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, company, this.authService.token)));
  }
}
