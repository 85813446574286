export class DriverDocument {
  id: string;
  status: string;
  type: string;
  name: string;
  verifiedDate: string;
  icon: string;
  iconColor: string;
  fileUrl: string;
}
