<mat-card class="example-card" fxLayout="column" fxLayoutAlign="center center">
  <div class="card-header margin-bottom-md" fxLayout="row">
    <mat-icon class="margin-right-sm" *ngIf="!data.actorPicture">person</mat-icon>
    <img *ngIf="data.actorPicture" class="margin-right-sm" [src]="data.actorPicture" width="24px" height="24px">
    <mat-card-title class="actorName">{{data.actorName}}</mat-card-title>
  </div>
  <div class="card-content" fxLayout="column" fxLayoutAlign="center center">
    <mat-card-content>
      <h2 class="offer-rate">{{formatCurrency(data.primaryOfferRate)}}</h2>
      <p *ngIf="data.isAcceptedDialog; else showDeclineMessage">
        {{acceptedQuoteMessage}}
      </p>
      <ng-template #showDeclineMessage>
        <p>
          {{declinedQuoteMessage}}
        </p>
      </ng-template>
    </mat-card-content>
  </div>
  <div class="card-buttons" fxLayout="column" fxLayoutAlign="center center">
    <mat-card-actions>
      <button *ngFor="let action of data.actions" mat-raised-button [disabled]="action.disabled" [color]="action.color"
        (click)="action.action()">{{action.name}}</button>
    </mat-card-actions>
  </div>
</mat-card>