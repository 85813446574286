import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { IsLoadingService } from '@service-work/is-loading';
import { Response } from './models/response';
import { HttpOptions } from './models/http-options';
import { SharedDataAccessModule } from './shared-data-access.module';
import { Token } from '@fleetoperate/shared/authentication/data-access';

@Injectable({
  providedIn: SharedDataAccessModule
})
export class FleetoperateApiService {

  constructor(private readonly http: HttpClient,
              private isLoadingService: IsLoadingService) { }

  post(path: string, object: any, token?: Token): Observable<Response> {

    const headers = {
      'Content-Type': 'application/json'
    };

    if (token) {
      Object.assign(headers, { Authorization: token.accessToken });
    }

    const httpOptions = {
      headers: new HttpHeaders(headers)
    };

    const loadingSubject = new Subject();
    const call = this.http.post<Response>(path, object, httpOptions)
    .pipe(
      catchError(this.handleError),
      finalize(() => loadingSubject.complete())
    );

    this.isLoadingService.add(loadingSubject.asObservable());

    return call;
  }

  put(path: string, object: any, token?: Token, httpOptions?: HttpOptions): Observable<Response> {

    const defaultContentType = {
      'Content-Type': 'application/json'
    };

    if (!httpOptions) {
      httpOptions = {
        headers: new HttpHeaders(defaultContentType)
      };
    } else if (httpOptions && !httpOptions.headers) {
      httpOptions.headers = new HttpHeaders(defaultContentType);
    } else if (httpOptions && httpOptions.headers && !httpOptions.headers.get('Content-Type')) {
      httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
    }

    if (token) {
      httpOptions.headers = httpOptions.headers.append('Authorization', token.accessToken);
    }

    const loadingSubject = new Subject();
    const call = this.http.put<Response>(path, object, httpOptions)
      .pipe(
        catchError(this.handleError),
        finalize(() => loadingSubject.complete())
      );

    this.isLoadingService.add(loadingSubject.asObservable());

    return call;
  }

  delete(path: string, token?: Token): Observable<Response> {

    const headers = {
      'Content-Type': 'application/json'
    };

    if (token) {
      Object.assign(headers, { Authorization: token.accessToken });
    }

    const httpOptions = {
      headers: new HttpHeaders(headers)
    };

    const loadingSubject = new Subject();
    const call = this.http.delete<Response>(path, httpOptions)
      .pipe(
        catchError(this.handleError),
        finalize(() => loadingSubject.complete())
      );

    this.isLoadingService.add(loadingSubject.asObservable());
    return call;
  }

  get(path: string, object: any, token?: Token): Observable<Response> {

    const headers = {
      'Content-Type': 'application/json'
    };

    if (token) {
      Object.assign(headers, { Authorization: token.accessToken });
    }

    const httpOptions = {
      headers: new HttpHeaders(headers)
    };

    const loadingSubject = new Subject();
    const call = this.http.get<Response>(path, httpOptions)
      .pipe(
        catchError(this.handleError),
        finalize(() => loadingSubject.complete())
      );

    this.isLoadingService.add(loadingSubject.asObservable());
    return call;
  }

  private handleError(error: HttpErrorResponse) {
    let message = 'There was an error; please try again later.';

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      message = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      message = error.error;
    }
    // return an observable with a user-facing error message
    return throwError(message);
  }
}
