import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [ErrorMessageComponent],
  exports: [ErrorMessageComponent]
})
export class SharedUiErrorMessageModule {}
