import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DocumentsDataService, DocumentType } from '@fleetoperate/shared/documents/data-access-documents';
import { Response } from '@fleetoperate/shared/data-access';
import { ShipmentDocumentsModule } from './shipment-documents.module';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { SendDocumentResponse } from '@fleetoperate/shared/documents/data-access-documents';
import { ToastService } from '@fleetoperate/shared/util';
import * as mime from 'mime';

const ERROR_MESSAGE = 'There was an error. Please try again.';
const ERROR_DELETE_DOCUMENT_MESSAGE = 'There was an error deleting the document. Please try again.';

@Injectable({
  providedIn: ShipmentDocumentsModule
})
export class ShipmentDocumentsService {

  $errorMessage: Observable<string>;
  $documentTypes: Observable<DocumentType[]>;

  private errorMessageSubject: Subject<string>;
  private documentTypesSubject: Subject<DocumentType[]>;

  constructor(private readonly documentsDataService: DocumentsDataService,
              private readonly toastService: ToastService) {
                
    this.errorMessageSubject = new Subject<string>();
    this.$errorMessage = this.errorMessageSubject.asObservable();

    this.documentTypesSubject = new Subject<DocumentType[]>();
    this.$documentTypes = this.documentTypesSubject.asObservable();
  }

  loadShipmentDocumentTypes(): Observable<DocumentType[]> {
    return this.documentsDataService.loadShipmentDocumentTypes().pipe(
      tap((documentTypes: DocumentType[]) => {
        this.documentTypesSubject.next(documentTypes);
      }),
      catchError((error: string) => {
        throw error;
      })
    );
  }

  sendDocument(shipmentId: string, documentFile: any, documentType: string, extension: string): Observable<Response> {
    return this.documentsDataService.sendShipmentDocument(shipmentId, documentType, extension).pipe(
      switchMap((sendDocumentResponse: SendDocumentResponse) => {
        const url = sendDocumentResponse.url;
        const newFileName = sendDocumentResponse.file;

        const file = documentFile.value;
        const renamedFile: File = new File([file], newFileName, { type: file.type });

        const contentType= mime.getType(extension)
    
        return this.documentsDataService.uploadShipmentDocument(renamedFile, url, contentType);
      }),
      tap(() => {
        this.toastService.showSuccess("Sucessfully uploaded shipment document");
        this.setErrorMessage(undefined);
      }),
      catchError((error: string) => {
        this.setErrorMessage(ERROR_MESSAGE);
        throw error;
      })
    );
  }

  deleteDocument(shipmentId, documentId: string): Observable<Response> {
    this.setErrorMessage(undefined);
    return this.documentsDataService.deleteShipmentDocument(shipmentId, documentId).pipe(
      tap((response: Response) => {
        this.setErrorMessage(undefined);
        const message = response && response.message ? response.message : undefined;
        this.toastService.showSuccess("Sucessfully deleted document");
      }),
      catchError((error: string) => {
        this.setErrorMessage(ERROR_DELETE_DOCUMENT_MESSAGE);
        throw error;
      })
    );
  }

  private setErrorMessage(msg: string): void {
    this.errorMessageSubject.next(msg || null);
  }
}
