import { Injectable, Inject } from '@angular/core';
import { FleetoperateApiService, Response } from '@fleetoperate/shared/data-access';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { ErrorDataModule } from './error-data.module';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Error } from '../models/error.model';
import { DateTimeService } from '@fleetoperate/shared/util';

const PATH_ALERTS = '/alerts';

@Injectable({
  providedIn: ErrorDataModule
})
export class ErrorApiService {
  constructor(
    private readonly api: FleetoperateApiService,
    private readonly authService: AuthService,
    private readonly dateTimeService: DateTimeService,
    @Inject('apiUrl') private apiUrl: string
  ) {}

  getErrors(capturedTime: string): Observable<Response> {
    const path = PATH_ALERTS.replace('{capturedTime}', capturedTime);
    const queryString = capturedTime ? `?capturedTime=${capturedTime}` : '';
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}${queryString}`, {}, this.authService.token)));
  }

  getMockErrors(capturedTime: string): Observable<Response> {
    const oneHour = 1000 * 60 * 60;
    const oneDay = 1000 * 60 * 60 * 24;
    const threeDays = 1000 * 60 * 60 * 24 * 3;
    const sevenDays = 1000 * 60 * 60 * 24 * 7;

    const errors = [
      {
        affectedUser: '123-456-789',
        attemptedAction: 'create-load',
        capturedTime: this.dateTimeService.getUTCDateFormatted(),
        error: {
          status: 500,
          code: 50001,
          message: 'Could not create load.',
          requestId: '1123582347-234u234-234u23-234823',
          developerMessage: 'Error in database as missing required fields.'
        }
      },
      {
        affectedUser: '123-456-789',
        attemptedAction: 'create-load',
        capturedTime: this.dateTimeService.subtractMilliseconds(this.dateTimeService.getUTCDate(), oneHour + 1000),
        error: {
          status: 500,
          code: 50001,
          message: 'Could not create load.',
          requestId: '1123582347-234u234-234u23-234823',
          developerMessage: 'Error in database as missing required fields.'
        }
      },
      {
        affectedUser: '123-456-789',
        attemptedAction: 'create-load',
        capturedTime: this.dateTimeService.subtractMilliseconds(this.dateTimeService.getUTCDate(), oneDay + 1000),
        error: {
          status: 500,
          code: 50001,
          message: 'Could not create load.',
          requestId: '1123582347-234u234-234u23-234823',
          developerMessage: 'Error in database as missing required fields.'
        }
      },
      {
        affectedUser: '123-456-789',
        attemptedAction: 'create-load',
        capturedTime: this.dateTimeService.subtractMilliseconds(this.dateTimeService.getUTCDate(), threeDays + 1000),
        error: {
          status: 500,
          code: 50001,
          message: 'Could not create load.',
          requestId: '1123582347-234u234-234u23-234823',
          developerMessage: 'Error in database as missing required fields.'
        }
      }
    ] as Error[];

    let maxTime;
    switch (capturedTime) {
      case '60m':
        maxTime = oneHour;
        break;
      case '24h':
        maxTime = oneDay;
        break;
      case '3d':
        maxTime = threeDays;
        break;
      case '7d':
        maxTime = sevenDays;
        break;
    }

    const response = {
      status: 200,
      data: errors.filter(
        error => this.dateTimeService.subtractDates(error.capturedTime, this.dateTimeService.getUTCDateFormatted()) <= maxTime
      )
    } as Response;

    return of(response);
  }

  createError(error: Error): Observable<Response> {
    const path = PATH_ALERTS;
    return this.authService.isAuthenticated().pipe(
      mergeMap(() => {
        return this.api.post(`${this.apiUrl}${path}`, error, this.authService.token);
      })
    );
  }
}
