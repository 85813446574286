import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ShipperDataService } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { ROUTE_SIGNIN } from '@fleetoperate/shared/authentication/feature-authentication';
import { DeliveryShipperFeatureShellModule } from '../delivery-shipper-feature-shell.module';

@Injectable({
  providedIn: DeliveryShipperFeatureShellModule
})
export class ShipperGuardService implements CanActivate {
  constructor(private readonly router: Router, private readonly shipperDataService: ShipperDataService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const shipper = this.shipperDataService.getShipper();

    if (shipper) {
      return true;
    }

    this.shipperDataService.logout();
    this.navigateToSignIn();
    return false;
  }

  private navigateToSignIn(): void {
    this.router.navigate([ROUTE_SIGNIN]);
  }
}
