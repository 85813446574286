import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayComponent } from './overlay/overlay.component';

@NgModule({
  imports: [CommonModule],
  declarations: [OverlayComponent],
  entryComponents: [OverlayComponent]
})
export class SharedUiOverlayModule {}
