import { Component, OnInit, OnDestroy, OnChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { VerificationModel } from './verification-model';
import { RegistrationService } from '../services/registration.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('f', { static: false }) formRef;
  email$: Observable<string>;
  form: FormGroup;
  errorMessage: string;
  step: number;
  numSteps: number;
  forwardRoute: string;

  private errorMessageSubscription: Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private store: Store,
    private fb: FormBuilder,
    private registrationService: RegistrationService
  ) {
    this.form = this.createForm();
  }

  ngOnInit() {
    this.step = this.route.snapshot.data.step;
    this.numSteps = this.route.snapshot.data.numSteps;
    this.forwardRoute = this.route.snapshot.data.forwardRoute;

    this.email$ = this.store.select(state => state.registration.company.email);
    const formModel = this.getFormModel();
    this.populateForm(formModel);

    this.errorMessageSubscription = this.registrationService.verificationErrorObservable.subscribe(
      (errorMessage: string) => {
        this.errorMessage = errorMessage;
      }
    );
  }

  ngOnDestroy() {
    if (this.errorMessageSubscription) {
      this.errorMessageSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    this.rebuildForm();
  }

  onNavigateToNextStep() {
    this.errorMessage = undefined;

    if (!this.form.valid) {
      return;
    }

    const model = this.prepareSaveModel();
    this.registrationService.sendVerificationCode(model, this.forwardRoute);
    this.rebuildForm();
  }

  onNoCodeReceived(): void {
    this.registrationService.resendVerificationCode();
  }

  get verificationCode() {
    return this.form.get('verificationCode');
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      verificationCode: ['', Validators.required]
    });

    return form;
  }

  private populateForm(model: VerificationModel): void {
    this.form.setValue({
      verificationCode: model.verificationCode
    });
  }

  private rebuildForm() {
    this.formRef.resetForm();
  }

  private getFormModel(): VerificationModel {
    return new VerificationModel();
  }

  private prepareSaveModel(): VerificationModel {
    const formModel = this.form.value;

    const saveModel = {
      verificationCode: formModel.verificationCode
    } as VerificationModel;

    return saveModel;
  }
}
