import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationComponent } from './information/information.component';
import { DialogService } from './dialog.service';
import { MatCardModule } from '@angular/material/card';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule, MatDialogModule, FlexLayoutModule],
  declarations: [InformationComponent, ConfirmationComponent],
  entryComponents: [InformationComponent, ConfirmationComponent],
  providers: [DialogService]
})
export class SharedUiDialogModule {}
