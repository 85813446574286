import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorDataModule } from './error-data/error-data.module';
@NgModule({
  imports: [
    CommonModule,
    ErrorDataModule
  ]
})
export class SharedDataAccessErrorModule {}
