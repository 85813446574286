export const environment = {
  production: false,
  serviceWorker: true,
  apiUrl: 'https://dev-api.delivery.fleetoperate.com',
  registrationUri: '/shippers',
  ribbonText: 'Delivery - Dev',
  ribbonColour: 'green',
  signinSuccessPath: '/',
  contactUsUrl: 'http://dev.fleetoperate.com/index.php/contacts',
  fleetOperateUrl: 'https://fleetoperate.com/',
  loggingEnabled: true,
  firebase: {
    apiKey: 'AIzaSyBgiT_2Izp9CXbxhN8ZxiQWokHoKs6-XSI',
    authDomain: 'fleetoperate-testing-account.firebaseapp.com',
    databaseURL: 'https://fleetoperate-testing-account.firebaseio.com',
    projectId: 'fleetoperate-testing-account',
    storageBucket: 'fleetoperate-testing-account.appspot.com',
    messagingSenderId: '480376444459',
    appId: '1:480376444459:web:9239f80e5bb2bc0551fc6e',
    measurementId: 'G-KCG08Z5L3Q'
  }
};
