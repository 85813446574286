<form [formGroup]="companyInfoForm" *ngIf="shipper" class="default-background padding-top-lg"
  #companyInfoFormRef="ngForm" (ngSubmit)="onCompanyInfoFormSubmit(companyInfoFormRef)" fxLayout="column">
  <span class="padding-bottom-sm">{{companyInfoFormMessage}}</span>
  <div fxLayout class="margin-bottom-md">
    <mat-form-field fxFlex class="margin-right-md margin-left-md">
      <input matInput placeholder="Company Name" formControlName="companyName" required>
      <mat-error *ngIf="companyName.touched && companyName.invalid">
        {{getCompanyNameErrorMessage()}}
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex class="margin-right-md">
      <input matInput placeholder="Address" formControlName="address" required>
      <mat-error *ngIf="address.touched && address.invalid">
        {{getAddressErrorMessage()}}
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout class="margin-bottom-md">
    <mat-form-field fxFlex class="margin-right-md margin-left-md">
      <input matInput [readonly]="companyInfoReadOnly" placeholder="City" formControlName="city" required>
      <mat-error *ngIf="city.touched && city.invalid">
        {{getCityErrorMessage()}}
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex class="margin-right-md">
      <input matInput [readonly]="companyInfoReadOnly" placeholder="State" formControlName="state" required>
      <mat-error *ngIf="state.touched && state.invalid">
        {{getStateErrorMessage()}}
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout class="margin-bottom-md">
    <mat-form-field [ngClass.gt-sm]="'form-width'" class="margin-right-md margin-left-md">
      <input matInput [readonly]="companyInfoReadOnly" placeholder="zipCode" formControlName="zipCode" required>
      <mat-error *ngIf="zipCode.touched && zipCode.invalid">
        {{getZipCodeErrorMessage()}}
      </mat-error>
    </mat-form-field>
  </div>

  <div class=" margin-top-lg margin-right-lg margin-bottom-lg">
    <button mat-raised-button type="submit" class="formButtons"
      [disabled]="companyInfoForm.pristine || !companyInfoForm.valid || loading">Save</button>
  </div>
</form>