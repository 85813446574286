import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CounterOfferQuoteDialogComponent } from './counter-offer-quote-dialog.component';
import { MatButtonModule} from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { CurrencyFormatModule } from '@fleetoperate/shared/util';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [CounterOfferQuoteDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    FlexLayoutModule,
    FormsModule,
    CurrencyFormatModule,
    MatIconModule
  ],
  exports:[CounterOfferQuoteDialogComponent]
})
export class CounterOfferQuoteDialogModule { }
