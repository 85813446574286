import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipmentConfirmationPendingViewComponent } from './shipment-confirmation-pending-view/shipment-confirmation-pending-view.component';
import { ShipmentConfirmationPendingViewsComponent } from './shipment-confirmation-pending-views.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ShipmentConfirmationPendingViewComponent, ShipmentConfirmationPendingViewsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule
  ],
  exports: [ShipmentConfirmationPendingViewsComponent]
})
export class ShipmentConfirmationPendingViewsModule { }
