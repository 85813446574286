import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipmentListComponent } from './shipment-list/shipment-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CurrencyFormatModule } from '@fleetoperate/shared/util';
import { DeliveryShipperUiAppToolbarModule } from '@fleetoperate/delivery-shipper/ui-app-toolbar';
import { SharedUiHeaderModule } from '@fleetoperate/shared/ui/header';
import { SharedUiOverlayModule } from '@fleetoperate/shared/ui/overlay';
import { CreateLoadComponent } from '../create-load/create-load.module';
import { MatProgressSpinnerModule } from '@angular/material';
import { MatSortModule } from '@angular/material/sort';
import { DeliveryShipperFeatureSettingsModule } from '@fleetoperate/delivery-shipper/feature-settings';

@NgModule({
  declarations: [ShipmentListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FlexLayoutModule,
    CurrencyFormatModule,
    MatProgressSpinnerModule,
    SharedUiHeaderModule,
    SharedUiOverlayModule,
    DeliveryShipperFeatureSettingsModule,
    DeliveryShipperUiAppToolbarModule
  ],
  entryComponents: [CreateLoadComponent]
})
export class ShipmentListModule {}

export { ShipmentListComponent } from './shipment-list/shipment-list.component';
