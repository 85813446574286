import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetOperateLocation, SetCompany, UpdateCompany, ClearCompany, ClearRegistration, SetMcDotOperateLocation } from './registration.actions';

export interface RegistrationOperateLocationStateModel {
    operateInUS: boolean;
    dotNumber: string;
}

const registrationOperateLocationStateModelDefault = {
    operateInUS: undefined,
    dotNumber: undefined
} as RegistrationOperateLocationStateModel;

export interface RegistrationMcDotOperateLocationStateModel {
  mcNumber: number;
  dotNumber: number;
}

const registrationMcDotOperateLocationStateModelDefault = {
  mcNumber: undefined,
  dotNumber: undefined
} as RegistrationMcDotOperateLocationStateModel;

export interface RegistrationCompanyStateModel {
    id: number;
    firstName: string;
    lastName: string;
    companyNumber: number;
    companyName: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    email: string;
    phoneNumber: string;
    password: string;
}

const registrationCompanyStateModelDefault = {
    id: undefined,
    firstName: '',
    lastName: '',
    companyNumber: undefined,
    companyName: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    email: '',
    phoneNumber: '',
    password: ''
} as RegistrationCompanyStateModel;

export interface RegistrationStateModel {
    operateLocation: RegistrationOperateLocationStateModel;
    mcDotOperateLocation: RegistrationMcDotOperateLocationStateModel;
    company: RegistrationCompanyStateModel;
}

@State<RegistrationStateModel>({
    name: 'registration',
    defaults: {
        operateLocation: registrationOperateLocationStateModelDefault,
        mcDotOperateLocation: registrationMcDotOperateLocationStateModelDefault,
        company: registrationCompanyStateModelDefault
    }
})
export class RegistrationState {

    @Selector() static operateLocation(state: RegistrationStateModel) {
      return state.operateLocation;
    }

    @Selector() static mcDotOperateLocation(state: RegistrationStateModel) {
      return state.mcDotOperateLocation;
    }

    @Selector() static company(state: RegistrationStateModel) {
      return state.company;
    }

    @Action(ClearRegistration)
    clearRegistration({ setState }: StateContext<RegistrationStateModel>, {}: ClearRegistration) {
        const model = {
          operateLocation: registrationOperateLocationStateModelDefault,
          mcDotOperateLocation: registrationMcDotOperateLocationStateModelDefault,
          company: registrationCompanyStateModelDefault
        } as RegistrationStateModel;

        setState(model);
    }

    @Action(SetOperateLocation)
    setOperateLocation({ patchState }: StateContext<RegistrationStateModel>, { operateInUS, dotNumber }: SetOperateLocation) {
        const model = {
            operateLocation: {
                operateInUS,
                dotNumber
            } as RegistrationOperateLocationStateModel
        };

        patchState(model);
    }

    @Action(SetMcDotOperateLocation)
    setMcDotOperateLocation({ patchState }: StateContext<RegistrationStateModel>, { mcNumber, dotNumber }: SetMcDotOperateLocation) {
        const model = {
            mcDotOperateLocation: {
                mcNumber,
                dotNumber
            } as RegistrationMcDotOperateLocationStateModel
        };

        patchState(model);
    }

    @Action(SetCompany)
    setCompany({ getState, patchState }: StateContext<RegistrationStateModel>, { payload }: SetCompany) {
        const updatedCompany = {} as RegistrationCompanyStateModel;
        Object.assign(updatedCompany, getState().company, payload);
        patchState({ company: updatedCompany });
    }

    @Action(UpdateCompany)
    updateCompany({ getState, patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateCompany) {
        const updatedCompany = {} as RegistrationCompanyStateModel;
        Object.assign(updatedCompany, getState().company, payload);
        patchState({ company: updatedCompany });
    }

    @Action(ClearCompany)
    clearCompany({ patchState }: StateContext<RegistrationStateModel>, action: ClearCompany) {
        patchState({ company: undefined });
    }
}
