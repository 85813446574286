<div fxFlexFill fxLayout fxLayoutAlign="space-evenly">
  <div
    fxFlex
    fxLayout="column"
    fxShow
    fxHide.lt-md="true"
    [ngStyle]="{ 'background-color': backgroundColor }"
    class="padding-5x registration-content"
  >
    <div fxFlex fxLayout="column" fxLayoutAlign="space-evenly" class="padding-sides-2x">
      <div fxFlex>
        <div fxLayout fxShow fxHide.lt-md class="brand-title">
          <img src="{{ logoLink }}" />
          <div class="fleetoperate-logo-light" fxFlexAlign="center" fxLayout="column">
            <span class="fleetoperate-logo-top"></span>
            <span class="fleetoperate-logo-bottom"></span>
          </div>
        </div>
      </div>
      <div fxFlex fxLayout="column">
        <span class="title">{{ registrationTitle }}</span>
        <span class="sub-text">{{ registrationSubtext1 }}</span>
        <span class="margin-top-sm">{{ registrationSubtext2 }}</span>
      </div>
    </div>
    <span class="copy-right">&copy; Copyright {{ copyrightYear }} FleetOperate</span>
  </div>
  <router-outlet></router-outlet>
</div>
