<mat-card class="example-card" fxLayout="column">
  <div class="margin-bottom-md" fxLayout="row">
    <mat-card-title>{{data.title}}</mat-card-title>
  </div>
  <div class="card-content" fxLayout="column">
    <mat-card-content>
      {{data.message}}
    </mat-card-content>
  </div>
  <div class="margin-top-lg" fxLayout="column">
    <mat-card-actions>
      <button mat-button (click)="dialogRef.close(false)" cdkFocusInitial>Cancel</button>
      <button mat-button class="confirm-button" (click)="dialogRef.close(true)">Confirm</button>
    </mat-card-actions>
  </div>
</mat-card>