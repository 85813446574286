import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { SubSink } from 'subsink';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DriverSearchService } from '../driver-search.service';
import { ShipperDataService } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { User } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
const ERROR_MESSAGE = 'There was an error. Please try again.';
// const QUOTE_TITLE = 'Quote';
const DRIVER_TITLE = 'Driver';
const DRIVER_BUTTON_TEXT = 'Find drivers';

@Component({
  selector: 'app-driver-search',
  templateUrl: './driver-search.component.html',
  styleUrls: ['./driver-search.component.scss']
})
export class DriverSearchComponent implements OnInit {
  form: FormGroup;
  loading: boolean;
  showResults: boolean = false;
  driverList: any[] = [];
  errorMessage: string;
  isQuoteSearch: boolean = true;
  formTitle: string = DRIVER_TITLE;
  submitButtonText: string = DRIVER_BUTTON_TEXT;
  quoteList: any[] = [];
  fromStates: Observable<string[]>;
  toStates: Observable<string[]>;
  allStates: string[] = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ];
  user: User;

  private subs = new SubSink();

  constructor(
    private readonly fb: FormBuilder,
    private readonly driverSearchService: DriverSearchService,
    private readonly shipperDataService: ShipperDataService
  ) {
    this.form = this.createForm();
    this.formTitle = DRIVER_TITLE;
  }

  ngOnInit() {
    // this.setFormTitleValues();
    this.fromStates = this.from.valueChanges.pipe(
      startWith(''),
      map(value => this.filterStates(value))
    );
    this.toStates = this.to.valueChanges.pipe(
      startWith(''),
      map(value => this.filterStates(value))
    );
    this.user = {
      name: `${this.shipperDataService.getShipper().firstName} ${this.shipperDataService.getShipper().lastName}`
    };
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSubmit() {
    this.showResults = true;
    if (!this.isQuoteSearch) {
      this.getDrivers();
    } /* else {
      this.getQuotes();
    }*/
  }

  onClearResults(driverSearchForm: NgForm) {
    this.form.reset();
    driverSearchForm.resetForm();
    this.showResults = false;
    this.driverList = [];
  }

  // onOwnerOperatorSelected($event) {
  //   this.isQuoteSearch = !$event.checked;
  //   this.setFormTitleValues();
  // }

  get from() {
    return this.form.get('from');
  }
  get to() {
    return this.form.get('to');
  }
  get truckType() {
    return this.form.get('truckType');
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      from: [''],
      to: ['', [Validators.required]],
      truckType: ['', [Validators.required]]
    });

    return form;
  }

  // private setFormTitleValues() {
  //   this.formTitle = this.isQuoteSearch ? QUOTE_TITLE : DRIVER_TITLE;
  //   this.submitButtonText = this.isQuoteSearch ? QUOTE_TITLE : DRIVER_BUTTON_TEXT;
  // }

  private getDrivers() {
    this.subs.add(
      this.driverSearchService.findDrivers().subscribe(
        (result: any) => {
          this.errorMessage = undefined;
          this.loading = false;
          const driverList = result.data;
          this.driverList = driverList;
        },
        (error: string) => {
          this.errorMessage = ERROR_MESSAGE;
          this.loading = false;
        }
      )
    );
  }

  // private getQuotes() {
  //   this.subs.add(this.shipperService.getQuotes().subscribe((result: any) => {
  //     this.errorMessage = undefined;
  //     this.loading = false;
  //     const quoteList = result.data;
  //     this.quoteList = quoteList;
  //   }, (error: string) => {
  //     this.errorMessage = ERROR_MESSAGE;
  //     this.loading = false;
  //   }));
  // }

  private filterStates(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allStates.filter(state => state.toLowerCase().includes(filterValue));
  }

  onLogout(): void {
    this.shipperDataService.logout();
  }
}
