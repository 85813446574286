import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@fleetoperate/shared/authentication/feature-authentication';
import { ShipperGuardService } from '@fleetoperate/delivery-shipper/feature-shell';
import { ProfileUserInfoComponent } from './shipper-profile/profile-user-info/profile-user-info.component';
import { ShipperProfileComponent } from './shipper-profile/shipper-profile/shipper-profile.component';
import { ProfilePasswordComponent } from './shipper-profile/profile-password/profile-password.component';
import { ProfileCompanyInfoComponent } from './shipper-profile/profile-company-info/profile-company-info.component';
import { ProfileLoadSettingsComponent } from './shipper-profile/profile-load-settings/profile-load-settings.component';
import {
  ROUTE_PROFILE_USER_INFO,
  ROUTE_SHIPPER_PROFILE,
  ROUTE_PROFILE_COMPANY_INFO,
  ROUTE_PROFILE_PASSWORD_RESET,
  ROUTE_PROFILE_LOAD_SETTINGS
} from './shared/routes';

const routes: Routes = [
  {
    path: ROUTE_SHIPPER_PROFILE,
    component: ShipperProfileComponent,
    children: [
      {
        path: ROUTE_PROFILE_USER_INFO,
        component: ProfileUserInfoComponent,
        canActivate: [AuthGuardService, ShipperGuardService]
      },
      {
        path: ROUTE_PROFILE_COMPANY_INFO,
        component: ProfileCompanyInfoComponent,
        canActivate: [AuthGuardService, ShipperGuardService]
      },
      {
        path: ROUTE_PROFILE_PASSWORD_RESET,
        component: ProfilePasswordComponent,
        canActivate: [AuthGuardService, ShipperGuardService]
      },
      {
        path: ROUTE_PROFILE_LOAD_SETTINGS,
        component: ProfileLoadSettingsComponent,
        canActivate: [AuthGuardService, ShipperGuardService]
      }
    ],
    canActivate: [AuthGuardService, ShipperGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeliveryShipperFeatureProfileRoutingModule {}
