<div class="table-container mat-elevation-z8">
  <table mat-table [dataSource]="[shipment]">
    <ng-container matColumnDef="shipmentId">
      <th mat-header-cell *matHeaderCellDef class="column-header-text">SHIPMENT ID</th>
      <td mat-cell data-test="shipment-ID-field" *matCellDef="let element">{{ element.shipmentID }}</td>
    </ng-container>

    <ng-container matColumnDef="mode">
      <th mat-header-cell *matHeaderCellDef class="column-header-text">MODE</th>
      <td mat-cell *matCellDef="let element">{{ element.mode }}</td>
    </ng-container>

    <ng-container matColumnDef="shipmentReference">
      <th mat-header-cell *matHeaderCellDef class="column-header-text">SHIP. REF#</th>
      <td mat-cell *matCellDef="let element">{{ element.shipmentReferenceNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef class="column-header-text">PRICE</th>
      <td mat-cell data-test="rate-field" *matCellDef="let element">{{ element.formattedOfferRate }}</td>
    </ng-container>

    <ng-container matColumnDef="carrier/driver">
      <th mat-header-cell *matHeaderCellDef class="column-header-text">CARRIER/DRIVER</th>
      <td mat-cell *matCellDef="let element">{{ element.actorName }}</td>
    </ng-container>

    <ng-container matColumnDef="origin">
      <th mat-header-cell *matHeaderCellDef class="column-header-text">ORIGIN</th>
      <td mat-cell *matCellDef="let element">{{ origin }}</td>
    </ng-container>

    <ng-container matColumnDef="pickup">
      <th mat-header-cell *matHeaderCellDef>ESTIMATED PICKUP</th>
      <td mat-cell *matCellDef="let element">{{ element.formattedPickupDateAndTime }}</td>
    </ng-container>

    <ng-container matColumnDef="destination">
      <th mat-header-cell *matHeaderCellDef class="column-header-text">DESTINATION</th>
      <td mat-cell *matCellDef="let element">{{ destination }}</td>
    </ng-container>

    <ng-container matColumnDef="delivery">
      <th mat-header-cell *matHeaderCellDef>ESTIMATED DELIVERY</th>
      <td mat-cell *matCellDef="let element">{{ element.formattedDeliveryDateAndTime }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>STATUS</th>
      <td mat-cell *matCellDef="let element">
        <div class="shipment-chip {{ element.color }}" data-test="shipment-status">
          {{ element.status }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="refresh">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button class="mat-icons" type="button" fxFlexAlign="center" (click)="refreshAction()">
          <mat-icon>refresh</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns.length > 0 ? displayedColumns : columns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns.length > 0 ? displayedColumns : columns"></tr>
  </table>
</div>
