export { SharedRegistrationFeatureRegistrationModule } from './lib/shared-registration-feature-registration.module';
export { RegistrationComponent } from './lib/registration/registration.component';
export { Navigate } from './lib/registration-router.state';
export {
  SharedRegistrationFeatureRegistrationShipperRoutingModule,
  ROUTE_REGISTRATION
} from './lib/shared-registration-feature-registration-shipper-routing.module';
export {
  SharedRegistrationFeatureRegistrationDeliveryShipperRoutingModule
} from './lib/shared-registration-feature-registration-delivery-shipper-routing.module';
export {
  SharedRegistrationFeatureRegistrationCarrierRoutingModule
} from './lib/shared-registration-feature-registration-carrier-routing.module';
export { RegistrationService } from './lib/services/registration.service';
