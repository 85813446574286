import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_SHIPMENT_SEARCH } from '../../shared/routes';
import {
  ShipperDataService,
  ShipmentDataService,
  Shipment,
  Action
} from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { first } from 'rxjs/operators';
import { User } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { ROUTE_SHIPPER_PROFILE } from 'libs/shipper/feature-profile/src/lib/shared/routes';
import { TabActionLink } from '@fleetoperate/shared/ui/header';
import { MatDialogRef } from '@angular/material/dialog';
import { SHIPMENT_STATUS_DRAFT } from '@fleetoperate/shipper/feature-shipment';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService } from '@fleetoperate/shared/ui/dialog';
import { ToastService } from '@fleetoperate/shared/util';

const DeleteTitle = 'Delete';
const ApproveLoadTitle = 'Approve Load';

@Component({
  selector: 'fleetoperate-edit-shipment',
  templateUrl: './edit-shipment.component.html',
  styleUrls: ['./edit-shipment.component.scss']
})
export class EditShipmentComponent implements OnInit {
  shipment: Shipment;
  private shipmentID: string;
  user: User;

  errorMessage: string;
  shipmentDetailsFormValid: boolean;
  pickupFormValid: boolean;
  deliveryFormValid: boolean;

  shipperProfilePath = ROUTE_SHIPPER_PROFILE;
  shipmentDetailsFormDirty: boolean;
  pickupFormDirty: boolean;
  deliveryFormDirty: boolean;
  loading: boolean;
  backAction: Function;
  title: string;
  actions: Array<TabActionLink> = [];
  deleteDialogMessage: string = this.sanitizer.bypassSecurityTrustHtml(
    `<h2>This load will be deleted. Please confirm.</h2>`
  ) as string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly sanitizer: DomSanitizer,
    private readonly dialogService: DialogService,
    private readonly shipperDataService: ShipperDataService,
    private readonly toastService: ToastService,
    private readonly shipmentDataService: ShipmentDataService
  ) {
    this.errorMessage = undefined;
    this.backAction = () => this.router.navigate([ROUTE_SHIPMENT_SEARCH]);
    this.title = 'Edit Shipment';
  }

  ngOnInit(): void {
    this.shipmentID = this.activatedRoute.snapshot.params.id;
    this.loadShipment(this.shipmentID);
    this.shipmentDetailsFormValid = false;
    this.pickupFormValid = false;
    this.deliveryFormValid = false;
    this.loading = false;
    this.user = {
      name: `${this.shipperDataService.getShipper().firstName} ${this.shipperDataService.getShipper().lastName}`
    };
  }

  private deleteDisabled(): boolean {
    return this.loading;
  }

  private approveLoadDisabled(): boolean {
    return (
      this.loading ||
      !this.shipmentDetailsFormValid ||
      !this.pickupFormValid ||
      !this.deliveryFormValid ||
      !this.shipmentDetailsFormDirty ||
      !this.deliveryFormDirty ||
      !this.pickupFormDirty
    );
  }

  onShipmentDetailsFormValid(isValid: boolean): void {
    this.shipmentDetailsFormValid = isValid;
    this.updateActionApproveLoad();
  }

  onDeliveryFormValid(isValid: boolean): void {
    this.deliveryFormValid = isValid;
    this.updateActionApproveLoad();
  }

  onPickupFormValid(isValid: boolean): void {
    this.pickupFormValid = isValid;
    this.updateActionApproveLoad();
  }

  onShipmentDetailsIsDirty(isDirty: boolean): void {
    this.shipmentDetailsFormDirty = !isDirty;
    this.updateActionApproveLoad();
  }

  onPickupIsDirty(isDirty: boolean): void {
    this.pickupFormDirty = !isDirty;
    this.updateActionApproveLoad();
  }

  onDeliveryIsDirty(isDirty: boolean): void {
    this.deliveryFormDirty = !isDirty;
    this.updateActionApproveLoad();
  }

  onMyShipments(): void {
    this.router.navigate([ROUTE_SHIPMENT_SEARCH]);
  }

  onLogout(): void {
    this.shipperDataService.logout();
  }

  onEditShipmentDetailsUpdate(): void {
    this.loadShipment(this.shipmentID);
  }

  onEditPickupUpdate(): void {
    this.loadShipment(this.shipmentID);
  }

  onEditDeliveryUpdate(): void {
    this.loadShipment(this.shipmentID);
  }

  private loadShipment(shipmentID: string): void {
    this.shipmentDataService
      .getShipment(shipmentID)
      .pipe(first())
      .subscribe((shipment: Shipment) => {
        this.shipment = shipment;
        if (this.shipment && this.shipment.status === SHIPMENT_STATUS_DRAFT) {
          if (this.actions && this.actions.length > 0) {
            this.actions = [];
          }
          this.actions.push({
            title: DeleteTitle,
            disabled: this.deleteDisabled(),
            color: 'delete',
            clickHandler: () => this.onDelete(this.shipment.id)
          } as TabActionLink);

          this.actions.push({
            title: ApproveLoadTitle,
            disabled: this.approveLoadDisabled(),
            clickHandler: () => this.onSubmit()
          } as TabActionLink);
        }
      });
  }

  onDelete(shipmentId: string): void {
    const actions = this.createDeleteDirtyPromptActions();
    const dialogRef = this.dialogService.openConfirmationDialog(
      actions,
      undefined,
      undefined,
      this.deleteDialogMessage
    );

    const cancelAction = actions[0];
    const confirmAction = actions[1];

    confirmAction.action = () => this.dialogDeleteShipment(dialogRef, shipmentId, confirmAction, cancelAction);
    cancelAction.action = () => this.dialogCancelAction(dialogRef, confirmAction, cancelAction);

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe();
  }

  onSubmit(): void {
    this.loading = true;
    this.updateActionApproveLoad();
    this.updateActionDeleteShipment();
    this.shipmentDataService
      .approveLoadShipment(this.shipment)
      .pipe(first())
      .subscribe(
        (shipment: Shipment) => {
          this.shipment = shipment;
          this.loading = false;
          this.updateActionApproveLoad();
          this.updateActionDeleteShipment();
          this.actions = [];
          this.errorMessage = undefined;
        },
        (error: any) => {
          this.errorMessage = error.message;
          this.loading = false;
          this.updateActionApproveLoad();
          this.updateActionDeleteShipment();
        }
      );
  }

  private updateActionApproveLoad() {
    const approveLoadAction = this.determineActionNeededForUpdateAction(ApproveLoadTitle);
    if (approveLoadAction && 'disabled' in approveLoadAction) {
      approveLoadAction.disabled = this.approveLoadDisabled();
    }
  }

  private updateActionDeleteShipment() {
    const deleteShipmentAction = this.determineActionNeededForUpdateAction(DeleteTitle);
    if (deleteShipmentAction && 'disabled' in deleteShipmentAction) {
      deleteShipmentAction.disabled = this.deleteDisabled();
    }
  }

  private determineActionNeededForUpdateAction(actionTitle: string): TabActionLink {
    let neededAction: TabActionLink;
    this.actions.forEach((action: TabActionLink) => {
      if (!action) {
        return;
      }
      if (action.title === actionTitle) {
        neededAction = action;
      }
    });
    return neededAction;
  }

  private createDeleteDirtyPromptActions(): Action[] {
    const actions: Action[] = [];

    const cancelAction = {
      name: 'Cancel',
      action: (data: any) => {},
      disabled: false,
      color: 'cancel'
    } as Action;
    actions.push(cancelAction);

    const confirmAction = {
      name: 'Confirm',
      action: (data: any) => {},
      disabled: false,
      color: 'delete'
    } as Action;
    actions.push(confirmAction);

    return actions;
  }

  private dialogDeleteShipment(
    dialogRef: MatDialogRef<any>,
    shipmentId: string,
    confirmAction: Action,
    cancelAction: Action
  ): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;

    this.shipmentDataService
      .deleteShipment(shipmentId)
      .pipe(first())
      .subscribe(() => {
        dialogRef.close();
        confirmAction.disabled = false;
        cancelAction.disabled = false;
        this.toastService.showSuccess('Sucessfully deleted shipment');
        this.router.navigate([ROUTE_SHIPMENT_SEARCH]);
      });
  }

  private dialogCancelAction(dialogRef: MatDialogRef<any>, confirmAction: Action, cancelAction: Action): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;

    dialogRef.close();
  }
}
