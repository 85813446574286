import { Injectable, Inject } from '@angular/core';
import { FleetoperateApiService, Response } from '@fleetoperate/shared/data-access';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { DocumentsDataModule } from './documents-data.module';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SendDocumentRequest } from '../models/send-document-request';
import { HttpHeaders } from '@angular/common/http';
import { HttpOptions } from '@fleetoperate/shared/data-access';

const PATH_DOCUMENTS_TYPES = '/documents';
const PATH_DOCUMENTS_TYPES_CARRIER = PATH_DOCUMENTS_TYPES + '/carrier';
const PATH_DOCUMENTS_TYPES_DRIVER = PATH_DOCUMENTS_TYPES + '/driver';
const PATH_CARRIERS_DOCUMENTS = '/carriers/{id}/documents';
const PATH_DRIVERS_DOCUMENT = '/drivers/{id}/documents';
const PATH_DOCUMENTS_TYPES_SHIPMENT = PATH_DOCUMENTS_TYPES + '/shipment';
const PATH_SHIPMENTS_DOCUMENTS = '/shipments/{id}/documents';
const PATH_SHIPMENTS_DOCUMENT = '/shipments/{id}/documents/{documentId}';
const PATH_CARRIERS_DOCUMENT = '/carriers/{id}/documents/{documentId}';
const PATH_DRIVER_DOCUMENT = '/drivers/{id}/documents/{documentId}';

@Injectable({
  providedIn: DocumentsDataModule
})
export class DocumentsApiService {
  constructor(
    private readonly api: FleetoperateApiService,
    private readonly authService: AuthService,
    @Inject('apiUrl') private apiUrl: string
  ) {}

  getCarrierDocumentTypes(): Observable<Response> {
    const path = PATH_DOCUMENTS_TYPES_CARRIER;
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token)));
  }

  sendCarrierDocument(carrierID: string, sendDocumentRequest: SendDocumentRequest): Observable<Response> {
    const path = PATH_CARRIERS_DOCUMENTS.replace('{id}', carrierID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, sendDocumentRequest, this.authService.token)));
  }

  uploadCarrierDocument(file: object, url: string, httpOptions?: HttpOptions): Observable<Response> {
    const defaultContentType = {
      'Content-Type': 'application/json'
    };

    if (!httpOptions) {
      httpOptions = {
        headers: new HttpHeaders(defaultContentType)
      };
    } else if (httpOptions && !httpOptions.headers) {
      httpOptions.headers = new HttpHeaders(defaultContentType);
    } else if (httpOptions && httpOptions.headers && !httpOptions.headers.get('Content-Type')) {
      httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
    }

    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${url}`, file, undefined, httpOptions)));
  }

  deleteCarrierDocument(carrierId: string, documentId: string): Observable<Response> {
    const path = PATH_CARRIERS_DOCUMENT.replace('{id}', carrierId).replace('{documentId}', documentId);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.delete(`${this.apiUrl}${path}`, this.authService.token)));
  }

  getDriverDocumentTypes(): Observable<Response> {
    const path = PATH_DOCUMENTS_TYPES_DRIVER;
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token)));
  }

  sendDriverDocument(driverID: string, sendDocumentRequest: SendDocumentRequest): Observable<Response> {
    const path = PATH_DRIVERS_DOCUMENT.replace('{id}', driverID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, sendDocumentRequest, this.authService.token)));
  }

  uploadDriverDocument(file: object, url: string, contentType: string): Observable<Response> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': contentType
      })
    } as HttpOptions;

    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${url}`, file, undefined, httpOptions)));
  }

  getShipmentDocumentTypes(): Observable<Response> {
    const path = PATH_DOCUMENTS_TYPES_SHIPMENT;
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token)));
  }

  sendShipmentDocument(shipmentID: string, sendDocumentRequest: SendDocumentRequest): Observable<Response> {
    const path = PATH_SHIPMENTS_DOCUMENTS.replace('{id}', shipmentID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, sendDocumentRequest, this.authService.token)));
  }

  uploadShipmentDocument(file: object, url: string, httpOptions?: HttpOptions): Observable<Response> {
    const defaultContentType = {
      'Content-Type': 'application/json'
    };

    if (!httpOptions) {
      httpOptions = {
        headers: new HttpHeaders(defaultContentType)
      };
    } else if (httpOptions && !httpOptions.headers) {
      httpOptions.headers = new HttpHeaders(defaultContentType);
    } else if (httpOptions && httpOptions.headers && !httpOptions.headers.get('Content-Type')) {
      httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
    }

    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${url}`, file, undefined, httpOptions)));
  }

  deleteShipmentDocument(shipmentId: string, documentId: string): Observable<Response> {
    const path = PATH_SHIPMENTS_DOCUMENT.replace('{id}', shipmentId).replace('{documentId}', documentId);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.delete(`${this.apiUrl}${path}`, this.authService.token)));
  }

  getDriverDocument(driverID: string, documentId: string): Observable<Response> {
    const path = PATH_DRIVER_DOCUMENT.replace('{id}', driverID).replace('{documentId}', documentId);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token)));
  }
}
