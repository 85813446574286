import { Injectable, Inject } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { SwPush } from '@angular/service-worker';
import { map, first } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { PushMessagingApiService } from './push-messaging-api.service';
import { NotificationSubscription } from './models/notification-subscription';
import { SharedMessagingDataAccessMessagingModule } from './shared-messaging-data-access-messaging.module';
import { LoggerService } from '@fleetoperate/shared/util';

@Injectable({
  providedIn: SharedMessagingDataAccessMessagingModule
})
export class PushMessagingService {
  private displayToken: string;

  constructor(
    private readonly swPush: SwPush,
    private readonly logger: LoggerService,
    @Inject('firebase') private readonly firebaseConfig: any,
    private readonly pushMessagingApiService: PushMessagingApiService
  ) {
    if (!firebase.apps.length && firebase.messaging.isSupported()) {
      firebase.initializeApp(firebaseConfig);
      navigator.serviceWorker.getRegistration().then((swr: any) => {
        firebase.messaging().useServiceWorker(swr);
        // this.registerBackgroundNotifications(firebase.messaging());
      });
    }

    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging.onTokenRefresh(() => {
        messaging
          .getToken()
          .then(refreshedToken => {
            this.logger.log('Token refreshed.');
            // Indicate that the new Instance ID token has not yet been sent to the
            // app server.
            // setTokenSentToServer(false);
            // Send Instance ID token to app server.
            // sendTokenToServer(refreshedToken);
            // ...
            this.sendTokenToAPI(refreshedToken);
          })
          .catch(err => {
            this.logger.log(`Unable to retrieve refreshed token ${err}`);
            // showToken('Unable to retrieve refreshed token ', err);
          });
      });

      if (this.swPush.isEnabled) {
        this.swPush.messages.subscribe(msg => this.logger.log(`[messages] push message ${JSON.stringify(msg)}`));
        this.swPush.subscription.subscribe((sub: any) => {
          this.logger.log(`[subscription] Sub on launch: ${JSON.stringify(sub)}`);
        });
        // swPush.notificationClicks.subscribe(click => this.logger.log('notification click', click));
        // .... The rest Firebase initialization stuff in step 2 above.
      }

      if (this.swPush.isEnabled) {
        this.swPush.notificationClicks.subscribe(event => {
          this.logger.log(`[notificationClicks] Received notification: ${JSON.stringify(event)}`);
          // const url = event.notification.data.url;
          // window.open(url, '_blank');
        });
      }
    }
  }

  isPushEnabled(): Observable<boolean> {
    if (!firebase.messaging.isSupported()) {
      this.logger.log(`[firebase] messaging not supported`);
      return of(false);
    }

    if (!this.swPush.isEnabled) {
      this.logger.log(`[isPushEnabled] swPush not enabled`);
      return of(false);
    }

    return this.swPush.subscription.pipe(
      map((sub: any) => {
        this.logger.log(`Current subscription: ${JSON.stringify(sub)}`);
        return !!sub;
      })
    );
  }

  permitToNotify(): Promise<string> {
    if (!firebase.messaging.isSupported()) {
      this.logger.log(`[firebase] messaging not supported`);
      return Promise.reject();
    }

    const messaging = firebase.messaging();
    return messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((token: string) => {
        this.displayToken = token;
        this.logger.log(`Display token: ${this.displayToken}`);
        this.sendTokenToAPI(token);

        return token;
      })
      .catch(err => {
        this.logger.log(`Unable to get permission to notify. ${err}`);
        return undefined;
      });
  }

  unsubscribeFromNotify(): Promise<boolean> {
    if (!firebase.messaging.isSupported()) {
      this.logger.log(`[firebase] messaging not supported`);
      return Promise.reject();
    }

    const messaging = firebase.messaging();
    return messaging
      .getToken()
      .then(token => {
        this.displayToken = token;
        this.logger.log(`Display token: ${this.displayToken}`);
        this.logger.log(`[deleteToken] delete token: ${token}`);
        return messaging.deleteToken(token);
      })
      .then((deleteResult: any) => {
        this.logger.log(`[deleteToken] delete token result: ${deleteResult}`);
        return true;
      })
      .catch((error: any) => {
        this.logger.log(`[deleteToken] Unable to delete token: ${error}`);
        return false;
      });
  }

  // private registerBackgroundNotifications(firebaseMessaging: any): void {
  //   if (firebase.apps.length) {
  //     // const messaging = firebase.messaging();
  //     const messaging = firebaseMessaging;

  //     // TODO: Not sure how this works. This is not showing in the logs.
  //     messaging.onMessage((payload: any) => {
  //       this.logger.log(`[onMessage] Received message ${payload}`);
  //     });

  //     // TODO: Not sure how this works
  //     messaging.setBackgroundMessageHandler((payload: any) => {
  //       this.logger.log(`[setBackgroundMessageHandler] Received background message: ${payload}`);
  //       // Customize notification here
  //       const notificationTitle = 'Attention';
  //       const notificationOptions = {
  //         body: 'New information available',
  //         icon: '/assets/img/app-icons/icon-144x144.png'
  //       };

  //       return navigator.serviceWorker.getRegistration().then((swr: any) => {
  //         this.logger.log(`[getRegistration] swr: ${swr}`);
  //         swr.showNotification(notificationTitle, notificationOptions);
  //       });
  //     });
  //   }
  // }

  private sendTokenToAPI(token: string): void {
    const subscription = {
      token: token
      // platform: '',
      // device: ''
    } as NotificationSubscription;
    this.pushMessagingApiService
      .subscribeToNotifications(subscription)
      .pipe(first())
      .subscribe();
  }
}
