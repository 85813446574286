import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiKitMaterialModule } from '@fleetoperate/ui-kit-material';
import { SharedAuthenticationFeatureAuthenticationModule } from '@fleetoperate/shared/authentication/feature-authentication';
import {
  SharedRegistrationFeatureRegistrationModule,
  SharedRegistrationFeatureRegistrationDeliveryShipperRoutingModule
} from '@fleetoperate/shared/registration/feature-registration';
import { DeliveryShipperFeatureShipmentModule } from '@fleetoperate/delivery-shipper/feature-shipment';
import { DeliveryShipperFeatureProfileModule } from '@fleetoperate/delivery-shipper/feature-profile';
import { ShellModule } from './shell/shell.module';
import { DeliveryShipperFeatureShellRoutingModule } from './delivery-shipper-feature-shell-routing.module';
import { DeliveryShipperFeatureDriverModule } from '@fleetoperate/delivery-shipper/feature-driver';
import { SharedUnsubscribeEmailFeatureUnsubscribeEmailModule } from '@fleetoperate/shared/unsubscribe-email/feature-unsubscribe-email';
@NgModule({
  imports: [
    CommonModule,
    UiKitMaterialModule,
    ShellModule,
    SharedAuthenticationFeatureAuthenticationModule,
    SharedRegistrationFeatureRegistrationModule,
    SharedRegistrationFeatureRegistrationDeliveryShipperRoutingModule,
    DeliveryShipperFeatureShipmentModule,
    DeliveryShipperFeatureDriverModule,
    DeliveryShipperFeatureProfileModule,
    SharedUnsubscribeEmailFeatureUnsubscribeEmailModule,
    DeliveryShipperFeatureShellRoutingModule
  ]
})
export class DeliveryShipperFeatureShellModule {}
