import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipperDataModule } from './shipper/shipper-data.module';
import { ShipmentDataModule } from './shipment-data/shipment-data.module';

@NgModule({
  imports: [
    CommonModule,
    ShipperDataModule,
    ShipmentDataModule
  ]
})
export class SharedShipperDataAccessShipperModule {}
