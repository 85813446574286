  <form [formGroup]="shipmentDetailsForm" #shipmentDetailsFormRef="ngForm" (ngSubmit)="onShipmentDetailsFormSubmit(shipmentDetailsFormRef)" fxLayout="column">
    <mat-expansion-panel [disabled]="!shipmentDetailsFormReadonly">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Shipment details
        </mat-panel-title>
      </mat-expansion-panel-header>

      <span class="padding-bottom-sm">{{shipmentDetailsFormMessage}}</span>
      <div fxLayout class="margin-bottom-md">
        <mat-form-field fxFlex class="margin-right-md">
          <input matInput [readonly]="shipmentDetailsFormReadonly" placeholder="Shipment reference #" formControlName="shipmentReferenceNumber">
          <mat-error *ngIf="shipmentReferenceNumber.touched && shipmentReferenceNumber.invalid">
            {{getShipmentReferenceNumberErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex class="margin-right-md">
          <input matInput [readonly]="shipmentDetailsFormReadonly" placeholder="Length" formControlName="length">
          <mat-error *ngIf="length.touched && length.invalid">
            {{getLengthErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex class="margin-right-md">
          <input matInput [readonly]="shipmentDetailsFormReadonly" placeholder="Commodity" formControlName="commodity" required>
          <mat-error *ngIf="commodity.touched && commodity.invalid">
            {{getCommodityErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput [readonly]="shipmentDetailsFormReadonly" min="0" placeholder="Declared value" formControlName="declaredValue">
          <mat-error *ngIf="declaredValue.touched && declaredValue.invalid">
            {{getDeclaredValueErrorMessage()}}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout class="margin-bottom-md">
        <mat-form-field fxFlex>
          <textarea matInput [readonly]="shipmentDetailsFormReadonly" placeholder="Comments" formControlName="comment"></textarea>
          <mat-error *ngIf="comment.touched && comment.invalid">
            {{getCommentErrorMessage()}}
          </mat-error>
        </mat-form-field>
      </div>

      <mat-action-row fxLayoutGap="20px">
        <button *ngIf="shipmentDetailsFormReadonly"
                mat-raised-button
                type="button"
                [disabled]="loading"
                (click)="onShipmentDetailsFormEdit()">Edit</button>
        <button *ngIf="!shipmentDetailsFormReadonly"
                mat-raised-button
                type="submit"
                [disabled]="shipmentDetailsForm.pristine || !shipmentDetailsForm.valid || loading">Save</button>
        <button *ngIf="!shipmentDetailsFormReadonly"
                mat-raised-button
                type="button"
                [disabled]="loading"
                (click)="onShipmentDetailsFormCancel(shipmentDetailsFormRef)">Cancel</button>
      </mat-action-row>
    </mat-expansion-panel>
  </form>
