import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_SIGNIN } from '../shared/routes';


@Component({
  selector: 'app-notification-sent',
  templateUrl: './notification-sent.component.html',
  styleUrls: ['./notification-sent.component.scss']
})
export class NotificationSentComponent implements OnInit {

  message: string;
  isDeliveryShipper: boolean;

  constructor(private readonly router: Router,
              @Inject('appName') private readonly appName: string) {
    this.message = `We've e-mailed you instructions for setting your password to the e-mail address you submitted.
     You should be receiving it shortly.`;
    this.isDeliveryShipper = this.appName === 'delivery-shipper' ? true : false;
  }

  ngOnInit() {
  }

  onNavigateToSignIn(): void {
    this.router.navigate([`/${ROUTE_SIGNIN}`]);
  }
}
