import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, NgForm, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { LoggerService, ToastService } from '@fleetoperate/shared/util';
import { ShipperService } from '../../shared/shipper.service';
import { Shipper } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { ProfilePasswordReset } from './profile-password-reset/profile-password-reset.service';
import { ProfilePasswordResetModel } from './profile-password-reset/profile-password-reset.model';

const RequiredFieldMessage = 'You must enter a value';
const PasswordsDoNotMatchMessage = 'Passwords do not match';
const MinimumLengthFieldMessage = (value: number) => `Must be atleast ${value} characters`;

@Component({
  selector: 'fleetoperate-profile-password',
  templateUrl: './profile-password.component.html',
  styleUrls: ['./profile-password.component.scss']
})
export class ProfilePasswordComponent implements OnInit, OnChanges {
  form: FormGroup;
  loading: boolean;
  hideOldPassword: boolean;
  hideNewPassword: boolean;
  errorMessage: string;
  shipper: Shipper;

  private subs = new SubSink();

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly shipperService: ShipperService,
    private readonly toastService: ToastService,
    private readonly resetPasswordService: ProfilePasswordReset,
    private readonly logger: LoggerService
  ) {
    this.loading = false;
    this.errorMessage = undefined;
    this.hideOldPassword = true;
    this.hideNewPassword = true;
    this.form = this.createForm();
  }
  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.form = this.createForm();
    this.shipperService.shipper$.subscribe((shipper: Shipper) => {
      this.shipper = shipper;
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSubmit(forgotPasswordForm: NgForm) {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const model = this.prepareSaveModel();

    this.subs.add(
      this.resetPasswordService.resetPassword(model).subscribe(
        (result: ProfilePasswordReset) => {
          this.errorMessage = undefined;
          this.toastService.showSuccess('Successfully reset password');
          this.logger.log('Reset form');
          this.form.reset();
          forgotPasswordForm.resetForm();
          this.loading = false;
        },
        (error: any) => {
          this.errorMessage = error.message;
          this.loading = false;
        }
      )
    );
  }

  get oldPassword() {
    return this.form.get('oldPassword');
  }
  get newPassword() {
    return this.form.get('newPassword');
  }

  getOldPasswordErrorMessage() {
    const errors = this.oldPassword.errors;

    return errors.required
      ? RequiredFieldMessage
      : errors.minlength
      ? MinimumLengthFieldMessage(errors.minlength.requiredLength)
      : '';
  }

  getNewPasswordErrorMessage(): string {
    const errors = this.newPassword.errors;

    return errors.required
      ? RequiredFieldMessage
      : errors.minlength
      ? MinimumLengthFieldMessage(errors.minlength.requiredLength)
      : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      oldPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]]
    });

    return form;
  }

  private prepareSaveModel(): ProfilePasswordResetModel {
    const formModel = this.form.value;
    const model = {
      username: this.shipper.email,
      oldPassword: formModel.oldPassword as string,
      newPassword: formModel.newPassword as string
    } as ProfilePasswordResetModel;

    return model;
  }
}
