import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { FleetoperateApiService, Response } from '@fleetoperate/shared/data-access';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { ShipmentDataModule } from './shipment-data.module';
import { Shipment } from '../models/shipment';
import { Bid } from '../models/bid';

const PATH_SHIPMENTS = '/shipments';
const PATH_SHIPMENTS_ID = `${PATH_SHIPMENTS}/{id}`;
const PATH_BID = `/quotes/{id}/bids`;
const PATH_SHIPMENTS_DOCUMENTS = `${PATH_SHIPMENTS_ID}/documents`;
const PATH_SHIPMENTS_DOCUMENT = `${PATH_SHIPMENTS_DOCUMENTS}/{documentId}`;
const PATH_COMPLETE_SHIPMENT = `${PATH_SHIPMENTS_ID}/completed`;
const PATH_APPROVE_LOAD_SHIPMENT = `${PATH_SHIPMENTS_ID}/approved`;
const PATH_CANCEL_BOOKED_SHIPMENT = `${PATH_SHIPMENTS_ID}/cancelled`;

export const SHIPMENT_LOOKUPS_MODES = 'modes';
export const EVENT_TYPE_ACCEPTED = 'accepted';
export const EVENT_TYPE_COUNTERED = 'countered';
export const EVENT_TYPE_DECLINED = 'declined';
export const EVENT_TYPE_UNDECLINED = 'undeclined';

export const SHIPMENT_STATUS_CONFIRMATION_PENDING = 'confirmation pending';

@Injectable({
  providedIn: ShipmentDataModule
})
export class ShipmentApiService {
  constructor(
    private readonly api: FleetoperateApiService,
    private readonly authService: AuthService,
    @Inject('apiUrl') private apiUrl: string,
    private readonly http: HttpClient
  ) {}

  getShipmentsByCompany(page: number, perPage: number, companyId?: string): Observable<Response> {
    const path = PATH_SHIPMENTS;
    const queryString = companyId ? `?companyid=${companyId}` : '';
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}${queryString}`, {}, this.authService.token)));
  }

  getShipmentsByStatus(page: number, perPage: number, statuses?: string[]): Observable<Response> {
    const path = PATH_SHIPMENTS;
    const queryString = `?status=${statuses.join('&')}`;
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}${queryString}`, {}, this.authService.token)));
  }

  getShipment(shipmentId: string): Observable<Response> {
    const path = PATH_SHIPMENTS_ID.replace('{id}', shipmentId);
    let queryString = `?include=quote`;
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}${queryString}`, {}, this.authService.token)));
  }

  getShipmentDocuments(shipmentId: string): Observable<Response> {
    const path = PATH_SHIPMENTS_DOCUMENTS.replace('{id}', shipmentId);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token)));
  }

  getShipmentDocument(shipmentId: string, documentId: string): Observable<Response> {
    const path = PATH_SHIPMENTS_DOCUMENT.replace('{id}', shipmentId).replace('{documentId}', documentId);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token)));
  }

  createShipment(shipment: Shipment): Observable<Response> {
    const path = PATH_SHIPMENTS;
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, shipment, this.authService.token)));
  }

  getShipmentLookups(lookupKeys: string[]): Observable<Response> {
    const path = PATH_SHIPMENTS;
    let queryString = '';

    if (lookupKeys && lookupKeys.length > 0) {
      const lookupString = '/lookups?include=';
      let filteredLookupKeys = [];
      filteredLookupKeys = lookupKeys.filter((lookupKey: string) => !!lookupKey);
      queryString = lookupString + filteredLookupKeys.join('&include=');
    }
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}${queryString}`, {}, this.authService.token)));
  }

  updateShipment(shipment: Shipment): Observable<Response> {
    const path = PATH_SHIPMENTS_ID.replace('{id}', `${shipment.id}`);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, shipment, this.authService.token)));
  }

  deleteShipment(shipmentId: string): Observable<Response> {
    const path = PATH_SHIPMENTS_ID.replace('{id}', shipmentId);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.delete(`${this.apiUrl}${path}`, this.authService.token)));
  }

  approveLoadShipment(shipment: Shipment): Observable<Response> {
    const path = PATH_APPROVE_LOAD_SHIPMENT.replace('{id}', `${shipment.id}`);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, shipment, this.authService.token)));
  }

  cancelBookedShipment(shipmentID: string, cancellationReasonModel: any): Observable<Response> {
    const path = PATH_CANCEL_BOOKED_SHIPMENT.replace('{id}', `${shipmentID}`);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, cancellationReasonModel, this.authService.token)));
  }

  completeShipment(shipmentId: string): Observable<Response> {
    const path = PATH_COMPLETE_SHIPMENT.replace('{id}', `${shipmentId}`);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, {}, this.authService.token)));
  }

  createBid(quoteId: string, bid: Bid): Observable<Response> {
    const path = PATH_BID.replace('{id}', quoteId);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.post(`${this.apiUrl}${path}`, bid, this.authService.token)));
  }

  getShipmentsMock(page: number, perPage: number): Observable<any> {
    return this.http.get('../../../assets/data/shipment-list.json').pipe(
      map((response: Response) => {
        const shipments = response.data;
        const to = perPage;
        const from = page * perPage - perPage;
        const length = shipments.length;
        const list = shipments.splice(from, to);

        return {
          shipments: list,
          count: length
        };
      })
    );
  }

  getShipmentByIdMock(id: string) {
    return this.http.get('../../../assets/data/shipment-list.json').pipe(
      map((response: Response) => {
        const shipments = response.data;
        return shipments.find(shipment => {
          return shipment.shipmentId === id;
        });
      })
    );
  }
}
