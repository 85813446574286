<div fxLayout="column" fxFlex>
  <progress-bar [count]="numSteps" [activeIndicator]="step" class="padding-bottom-md"></progress-bar>
  <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex>
    <!--fxFlex=".8 0 auto"-->
    <span class="mat-subheading-1">Step {{ step }}</span>
    <span class="mat-headline padding-bottom">Company Admin Info</span>
    <span class="mat-subheading-2 padding-bottom-md">Please confirm your details</span>
    <form [formGroup]="form" (ngSubmit)="onNavigateToNextStep()" fxLayout="column">
      <mat-form-field>
        <input matInput placeholder="First Name" formControlName="firstName" required />
        <mat-error *ngIf="firstName.touched && firstName.invalid">
          {{ getFirstNameErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="margin-top-sm">
        <input matInput placeholder="Last Name" formControlName="lastName" required />
        <mat-error *ngIf="lastName.touched && lastName.invalid">
          {{ getLastNameErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="margin-top-sm">
        <input matInput placeholder="Company" formControlName="companyName" required />
        <mat-error *ngIf="companyName.touched && companyName.invalid">
          {{ getCompanyNameErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="margin-top-sm">
        <input type="email" matInput placeholder="Email" formControlName="email" required />
        <mat-error *ngIf="email.touched && email.invalid">
          {{ getEmailErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="margin-top-sm">
        <mat-hint align="start">at least 8 characters</mat-hint>
        <input
          matInput
          placeholder="Password"
          [type]="hidePassword ? 'password' : 'text'"
          formControlName="password"
          required
        />
        <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
          hidePassword ? 'visibility' : 'visibility_off'
        }}</mat-icon>
        <mat-error *ngIf="password.touched && password.invalid">
          {{ getPasswordErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="margin-top-sm">
        <input
          matInput
          [type]="hideConfirmPassword ? 'password' : 'text'"
          placeholder="Confirm password"
          formControlName="confirmPassword"
          [errorStateMatcher]="matcher"
          required
        />
        <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{
          hideConfirmPassword ? 'visibility' : 'visibility_off'
        }}</mat-icon>
        <mat-error *ngIf="confirmPasswordHasError()">
          {{ getConfirmPasswordErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <div class="actions align-center full-width padding-top-xlg">
        <button
          class="margin-bottom"
          [ngClass.lt-md]="'full-width'"
          [ngClass.gt-sm]="'quarter-width'"
          mat-button
          type="button"
          (click)="onNavigateToPreviousStep()"
        >
          Back
        </button>
        <button
          class="margin-bottom"
          [ngClass.lt-md]="'full-width'"
          [ngClass.gt-sm]="'quarter-width'"
          mat-raised-button
          color="primary"
          type="button"
          (click)="onNavigateToNextStep()"
          [disabled]="form.pristine || !form.valid"
        >
          Next
        </button>
        <button
          class="margin-bottom"
          [ngClass.lt-md]="'full-width'"
          [ngClass.gt-sm]="'half-width'"
          mat-button
          type="button"
          (click)="onNavigateToLogin()"
        >
          Already a member? Log in
        </button>
      </div>
    </form>
  </div>
</div>
