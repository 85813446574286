<fleetoperate-header
  [title]="title"
  [backAction]="backAction"
  class="default-background padding-left-md padding-right-md"
></fleetoperate-header>
<div class="quotes-area padding-top-lg padding-right-md padding-left-md">
  <span *ngIf="errorMessage" class="padding-bottom-sm padding-left-md padding-right-md">{{ errorMessage }}</span>
  <div class="no-quotes padding-right-lg padding-left-lg" *ngIf="noCarrierQuotes && noDriverQuotes">
    No quotes to show
  </div>
  <fleetoperate-shipment-quote
    *ngFor="let quote of carrierQuotes"
    class="margin-md"
    [quote]="quote"
    [actions]="quote.actions"
  ></fleetoperate-shipment-quote>

  <fleetoperate-shipment-quote
    *ngFor="let quote of driverQuotes"
    class="margin-md"
    [quote]="quote"
    [actions]="quote.actions"
  ></fleetoperate-shipment-quote>
</div>
