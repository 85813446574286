import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

const routes: Routes = [];

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, MatIconModule, RouterModule.forChild(routes)],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class SharedUiHeaderModule {}
