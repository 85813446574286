<img class="logo" (click)="onLogoClick()" src="assets/img/FleetOperate-white-logo-primary.png" />
<div class="account margin-top-md margin-right-lg">
  <mat-icon class="margin-right-md">person</mat-icon>
  <span class="fullName padding-right-xs">
    {{ name }}
  </span>
  <button
    *ngIf="actions && actions.length"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    class="padding-right-lg"
    aria-label="Settings menu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu xPosition="before" #menu="matMenu">
    <button
      *ngFor="let action of actions"
      fxFlexAlign="center"
      mat-menu-item
      class="btn-actions"
      [disabled]="action.disabled || false"
      (click)="action.clickHandler()"
    >
      <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
      <span>{{ action.title }}</span>
    </button>
  </mat-menu>
  <button mat-button (click)="onLogout()">LOGOUT</button>
</div>
