<fleetoperate-delivery-shipper-app-toolbar
  [data]="user"
  [profilePath]="shipperProfilePath"
  (logout)="onLogout()"
></fleetoperate-delivery-shipper-app-toolbar>
<fleetoperate-header
  [title]="title"
  [backAction]="backAction"
  [actions]="headerActions"
  class="margin-left-lg margin-right-lg"
></fleetoperate-header>
<fleetoperate-error-message
  *ngIf="errorMessage"
  [error]="errorMessage"
  class="margin-left-lg margin-bottom-md"
></fleetoperate-error-message>
<fleetoperate-shipment-summary
  class="margin-left-lg margin-right-lg"
  [shipment]="shipment"
  [refreshAction]="refreshShipmentAction"
></fleetoperate-shipment-summary>
<div
  class="shipment-container margin-top-md margin-bottom-md margin-left-lg margin-right-lg"
  fxLayout="row"
  fxLayout.lt-md="column"
>
  <div fxLayout="column" fxLayout.lt-md="row" fxLayoutGap="5px" class="padding-xs">
    <button
      *ngFor="let action of actions"
      mat-raised-button
      [disabled]="action.disabled"
      (click)="action.action()"
      class="action"
    >
      {{ action.name }}
    </button>
  </div>
  <ng-container [ngSwitch]="viewType">
    <ng-container *ngSwitchCase="'default-view'"> </ng-container>
    <ng-container *ngSwitchCase="'unsecured-view'">
      <p>You have not yet accepted / countered any interests for this shipment.</p>
    </ng-container>
    <ng-container *ngSwitchCase="'driver-confirmation-pending-view'">
      <fleetoperate-shipment-confirmation-pending-views
        *ngIf="shipment"
        [shipment]="shipment"
      ></fleetoperate-shipment-confirmation-pending-views>
    </ng-container>
    <ng-container *ngSwitchCase="'track-shipment-view'">
      <div fxLayout fxFlex>
        <fleetoperate-shipment-map fxFlex *ngIf="shipment" [shipment]="shipment"></fleetoperate-shipment-map>
        <fleetoperate-shipment-events fxFlex [shipment]="shipment"></fleetoperate-shipment-events>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'documents-view'">
      <fleetoperate-document-list
        #documentListComponent
        fxFlex
        (error)="onDocumentListError($event)"
        [shipmentId]="shipment.id"
        [shipmentStatus]="shipment.status"
      >
      </fleetoperate-document-list>
    </ng-container>
  </ng-container>
</div>
