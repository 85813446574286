import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  copyrightYear: number;
  isDeliveryShipper: boolean;
  registrationTitle: string;
  registrationSubtext1: string;
  registrationSubtext2: string;
  logoLink: string;
  backgroundColor: string;

  constructor(private readonly route: ActivatedRoute) {
    this.copyrightYear = new Date().getFullYear();
  }

  ngOnInit() {
    this.registrationTitle = this.route.snapshot.data.registrationTitle;
    this.registrationSubtext1 = this.route.snapshot.data.registrationSubtext1;
    this.registrationSubtext2 = this.route.snapshot.data.registrationSubtext2;
    this.logoLink = this.route.snapshot.data.logoLink;
    this.backgroundColor = this.route.snapshot.data.backgroundColor;
  }
}
