export class Pickup {
  businessName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  driverAssistedPickup: boolean;
  liftgatePickup: boolean;
  tarpPickup: boolean;
  instructions: string;
  pickupDateFrom: string;
  pickupDateTo: string;
}
