import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyComponent } from './company/company.component';
import { CompanyAddressComponent } from './company-address/company-address.component';
import { VerificationComponent } from './verification/verification.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import {
  ROUTE_REGISTRATION,
  ROUTE_COMPANYADDRESS,
  ROUTE_VERIFICATION,
  ROUTE_CONFIRMATION,
  ROUTE_SUBMISSION,
  ROUTE_SIGNIN,
  ROUTE_REGISTRATION_COMPANYADDRESS,
  ROUTE_REGISTRATION_SUBMISSION,
  ROUTE_REGISTRATION_CONFIRMATION
} from './shared/routes';
import { RegistrationComponent } from './registration/registration.component';
import { SubmissionComponent } from './submission/submission.component';

const registrationTitle = 'Get Started with FleetOperate';
const registrationSubtext2 =
  'Be part of a trusted platform, that is redefining Supplychain for Shippers, Carriers and Drivers.';
const logoLink = 'assets/img/FleetOperate-white-logo.png';
const backgroundColor = '#0b184e';

export const registrationRoutes: Routes = [
  {
    path: '',
    component: CompanyComponent,
    data: { backRoute: ROUTE_SIGNIN, forwardRoute: ROUTE_REGISTRATION_COMPANYADDRESS, step: 1, numSteps: 2 }
  },
  {
    path: ROUTE_COMPANYADDRESS,
    component: CompanyAddressComponent,
    data: { backRoute: ROUTE_REGISTRATION, forwardRoute: ROUTE_REGISTRATION_SUBMISSION, step: 2, numSteps: 2 }
  },
  {
    path: ROUTE_SUBMISSION,
    component: SubmissionComponent,
    data: { forwardRoute: ROUTE_SIGNIN, step: 2, numSteps: 2 }
  },
  {
    path: ROUTE_VERIFICATION,
    component: VerificationComponent,
    data: { forwardRoute: ROUTE_REGISTRATION_CONFIRMATION, step: 2, numSteps: 2 }
  },
  {
    path: ROUTE_CONFIRMATION,
    component: ConfirmationComponent,
    data: { forwardRoute: ROUTE_SIGNIN, step: 2, numSteps: 2 }
  }
];

export const routes: Routes = [
  {
    path: ROUTE_REGISTRATION,
    component: RegistrationComponent,
    children: registrationRoutes,
    data: { registrationTitle, registrationSubtext2, logoLink, backgroundColor }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRegistrationFeatureRegistrationShipperRoutingModule {}

export { ROUTE_REGISTRATION } from './shared/routes';
