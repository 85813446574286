import { Injectable } from '@angular/core';
import { ShipperDataModule } from './shipper-data.module';
import { ShipperApiService } from './shipper-api.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Shipper } from '../models/shipper';
import { ShipperStateModel, ShipperDataState } from './state/shipper-data-state';
import { SetShipper, ClearShipper, SetShipperCompany } from './state/shipper-data-actions';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { Router } from '@angular/router';
import { ROUTE_SIGNIN } from '@fleetoperate/shared/authentication/feature-authentication';
import { StorageService } from '@fleetoperate/shared/util';
import { Company } from '../models/company';

const KEY_SHIPPER = 'shipper';

@Injectable({
  providedIn: ShipperDataModule
})
export class ShipperDataService {
  shipper$: Observable<Shipper>;

  constructor(
    private readonly router: Router,
    private readonly shipperApi: ShipperApiService,
    private readonly storageService: StorageService,
    private readonly store: Store,
    private readonly authService: AuthService
  ) {
    const shipper = this.storageService.find(KEY_SHIPPER) as Shipper;
    if (shipper) {
      const shipperStateModel = this.createShipperStateModel(shipper);
      this.store.dispatch([new SetShipper(shipperStateModel)]);
      this.shipper$ = this.store.select(ShipperDataState.shipper);
    }
  }

  loadShippers(): Observable<Shipper[]> {
    return this.shipperApi.getShippers().pipe(map((response: any) => response.data as Shipper[]));
  }

  loadShipper(): Observable<Shipper> {
    return this.shipperApi.getShipper().pipe(
      map((response: any) => response.data),
      tap((shipper: Shipper) => {
        const shipperStateModel = this.createShipperStateModel(shipper);
        this.store.dispatch([new SetShipper(shipperStateModel)]);
        this.store.dispatch([new SetShipperCompany(shipperStateModel.company)]);
        this.storageService.store(KEY_SHIPPER, shipper);
        this.shipper$ = this.store.select(ShipperDataState.shipper);
        return shipper;
      })
    );
  }

  updateShipper(shipper: Shipper): Observable<Shipper> {
    return this.shipperApi.updateShipper(shipper).pipe(
      map((response: any) => response.data),
      tap((shipperResponse: Shipper) => {
        const shipperStateModel = this.createShipperStateModel(shipperResponse);
        this.store.dispatch([new SetShipper(shipperStateModel)]);
        this.storageService.store(KEY_SHIPPER, shipperResponse);
        return shipperResponse;
      })
    );
  }

  updateShipperCompany(shipperID: number, company: Company): Observable<Company> {
    return this.shipperApi.updateShipperCompany(shipperID, company).pipe(
      map((response: any) => response.data),
      tap((companyResponse: Company) => {
        const shipperCompanyStateModel = this.createShipperCompanyStateModel(companyResponse);
        this.store.dispatch([new SetShipperCompany(shipperCompanyStateModel.company)]);
        this.storageService.store(KEY_SHIPPER, companyResponse);
        return companyResponse;
      })
    );
  }

  getShipper(): Shipper {
    const shipperStateModel = this.store.selectSnapshot(ShipperDataState.shipper);

    if (!shipperStateModel || !shipperStateModel.id) {
      return;
    }
    const shipper = this.createShipper(shipperStateModel);
    return shipper;
  }

  logout(): void {
    this.authService.unauthenticate();
    this.clearShipper();
    this.router.navigate([ROUTE_SIGNIN]);
  }

  private clearShipper(): void {
    this.store.dispatch([new ClearShipper()]);
    this.storageService.remove(KEY_SHIPPER);
  }

  private createShipperStateModel(shipper: Shipper): ShipperStateModel {
    const shipperStateModel = {
      id: shipper.id,
      operateInUS: shipper.operateInUS,
      dotNumber: shipper.dotNumber,
      firstName: shipper.firstName,
      lastName: shipper.lastName,
      companyNumber: shipper.companyNumber,
      companyName: shipper.companyName,
      companyID: shipper.companyID,
      address: shipper.address,
      city: shipper.city,
      state: shipper.state,
      zipCode: shipper.zipCode,
      country: shipper.country,
      email: shipper.email,
      phoneNumber: shipper.phoneNumber,
      company: shipper.company
    } as ShipperStateModel;
    return shipperStateModel;
  }

  private createShipperCompanyStateModel(company: Company): ShipperStateModel {
    const shipperStateModel = {
      company: company
    } as ShipperStateModel;
    return shipperStateModel;
  }

  private createShipper(shipperStateModel: ShipperStateModel): Shipper {
    const shipper = {
      id: shipperStateModel.id,
      operateInUS: shipperStateModel.operateInUS,
      dotNumber: shipperStateModel.dotNumber,
      firstName: shipperStateModel.firstName,
      lastName: shipperStateModel.lastName,
      companyNumber: shipperStateModel.companyNumber,
      companyName: shipperStateModel.companyName,
      companyID: shipperStateModel.companyID,
      address: shipperStateModel.address,
      city: shipperStateModel.city,
      state: shipperStateModel.state,
      zipCode: shipperStateModel.zipCode,
      country: shipperStateModel.country,
      email: shipperStateModel.email,
      phoneNumber: shipperStateModel.phoneNumber,
      company: shipperStateModel.company
    } as Shipper;
    return shipper;
  }
}
