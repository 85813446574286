import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../services/registration.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  step: number;
  numSteps: number;
  forwardRoute: string;

  constructor(private readonly route: ActivatedRoute, private readonly registrationService: RegistrationService) {}

  ngOnInit() {
    this.step = this.route.snapshot.data.step;
    this.numSteps = this.route.snapshot.data.numSteps;
    this.forwardRoute = this.route.snapshot.data.forwardRoute;
  }

  onNavigateToNextStep() {
    this.registrationService.navigateToNextStep(this.forwardRoute);
  }
}
