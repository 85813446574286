import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InformationComponent } from './information/information.component';
import { SharedUiDialogModule } from './shared-ui-dialog.module';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { Action } from './models/action';
import { DomSanitizer } from '@angular/platform-browser';
import { first } from 'rxjs/internal/operators/first';

@Injectable({
  providedIn: SharedUiDialogModule
})
export class DialogService {
  constructor(public dialog: MatDialog, private readonly dom: DomSanitizer) {}

  openInformationDialog(title?: string, message?: string, htmlContent?: string): void {
    const dialogRef = this.dialog.open(InformationComponent, {
      data: { title: title, message: message },
      disableClose: true
    });
    dialogRef.componentInstance.htmlContent = htmlContent;
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe();
  }

  openConfirmationDialog(actions: Action[], title?: string, message?: string, htmlContent?: string): MatDialogRef<ConfirmationComponent> {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: { title: title, message: message, actions: actions },
      disableClose: true
    });
    dialogRef.componentInstance.htmlContent = htmlContent;
    return dialogRef;
  }
}
