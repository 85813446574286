<div fxFlex
      fxLayout="column"
      fxShow
      fxHide.lt-md="true"
      class="margin-5x default-background registration-content">
  <div fxFlex fxLayout class="brand-title" *ngIf="!isDeliveryShipper; else showDeliveryLogo">
    <img  src="assets/img/FleetOperate-white-logo.png"/>
  </div>
  <ng-template #showDeliveryLogo>
    <div fxFlex fxLayout class="delivery-brand-title">
      <img  src="assets/img/FleetOperate-delivery-white-logo.png"/>
    </div>
  </ng-template>
  <div fxFlex class="margin-bottom-sm">
    <div class="title">Get Started with FleetOperate</div>
  </div>
  <span class="copy-right">&copy; Copyright {{copyrightYear}} FleetOperate</span>
</div>

<div fxFlex
     fxLayout="column"
     fxLayoutAlign="center"
     [ngStyle.gt-sm]="{'background-color': '#1b4686'}"
     [ngStyle.lt-md]="{'background-color': '#ffffff'}"
     [ngClass.gt-sm]="'margin-5x'">
    <app-sign-in-form class="margin-bottom-sm"></app-sign-in-form>
  <div fxShow
       fxHide.lt-md="true"
       class="contact default-background">
    <a (click)="onOpenContactUsDialog()">Contact</a> |
    <a href="{{fleetOperateUrl}}" target="_blank">fleetoperate.com</a>
  </div>
</div>
