import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { FleetoperateApiService } from '@fleetoperate/shared/data-access';
import { ProfilePasswordResetModel } from './profile-password-reset.model';

const PATH_RESET_PASSWORD = '/auth/resetpassword';

@Injectable({
  providedIn: 'root'
})
export class ProfilePasswordReset {


  constructor(private readonly store: Store,
              private readonly api: FleetoperateApiService,
              private authService: AuthService,
              @Inject('apiUrl') private apiUrl: string) {
   
  }

  resetPassword(model: ProfilePasswordResetModel): Observable<any> {
    return this.authService.isAuthenticated().pipe(
      mergeMap(() => this.api.post(this.apiUrl + PATH_RESET_PASSWORD, model, this.authService.token))
    );
  }
}
