import { Component, Inject } from '@angular/core';
import { DialogService } from 'libs/shared/ui/dialog/src/lib/dialog.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {

  copyrightYear: number;
  contactUsUrl: string;
  fleetOperateUrl: string
  isDeliveryShipper: boolean;

  dialogMessage: string = this.sanitizer.bypassSecurityTrustHtml(
    `<p>Please send us an email at</p>  
     <a href=\"mailto:getonboard@fleetoperate.com\">getonboard@fleetoperate.com</a>`) as string;

  constructor(@Inject('environmentData') environment: any,
              @Inject('appName') private readonly appName: string,
              private readonly dialogService: DialogService,
              private readonly sanitizer: DomSanitizer) {
    this.copyrightYear = (new Date()).getFullYear();
    this.contactUsUrl = environment.contactUsUrl;
    this.fleetOperateUrl = environment.fleetOperateUrl;
    this.isDeliveryShipper = this.appName === 'delivery-shipper' ? true : false;
  }

  onOpenContactUsDialog() {
    this.dialogService.openInformationDialog("Need Help?", undefined, this.dialogMessage);
  }
}
