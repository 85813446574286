import { Injectable } from '@angular/core';
import { SettingsAction } from './models/settings-action.model';
import { ToastService } from '@fleetoperate/shared/util';
import { PushMessagingService } from '@fleetoperate/shared/messaging/data-access-messaging';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Observable, BehaviorSubject, EMPTY, of } from 'rxjs';
import { DeliveryShipperFeatureSettingsModule } from './delivery-shipper-feature-settings.module';

@Injectable({
  providedIn: DeliveryShipperFeatureSettingsModule
})
export class SettingsService {
  private actions$: Observable<SettingsAction[]>;
  private actionsSubject: BehaviorSubject<SettingsAction[]>;

  constructor(
    private readonly pushMessagingService: PushMessagingService,
    private readonly toastService: ToastService
  ) {
    this.actionsSubject = new BehaviorSubject([]);
    this.actions$ = this.actionsSubject.asObservable();
  }

  createActions(): Observable<SettingsAction[]> {
    return this.pushMessagingService.isPushEnabled().pipe(
      map((enabled: boolean) => {
        const actions: SettingsAction[] = this.generateActions(enabled);
        this.actionsSubject.next(actions);
      }),
      switchMap(() => this.actions$),
      catchError((error: any) => {
        this.actionsSubject.next([]);
        return EMPTY;
      })
    );
  }

  private onEnableNotifications(): void {
    this.pushMessagingService.permitToNotify().then((token: string) => {
      const actions = this.generateActions(!!token);
      this.actionsSubject.next(actions);
      if (!!token) {
        this.toastService.showSuccess('Sucessfully enabled notifications.');
      }
    });
  }

  private onDisableNotifications(): void {
    this.pushMessagingService.unsubscribeFromNotify().then((unsubscribed: boolean) => {
      const actions = this.generateActions(!unsubscribed);
      this.actionsSubject.next(actions);
      if (unsubscribed) {
        this.toastService.showSuccess('Sucessfully disabled notifications.');
      }
    });
  }

  private generateActions(enabled: boolean): SettingsAction[] {
    const actions: SettingsAction[] = [];

    if (!enabled) {
      actions.push({
        title: 'Notifications',
        disabled: false,
        icon: 'notifications_off',
        clickHandler: () => this.onEnableNotifications()
      } as SettingsAction);
    }

    if (enabled) {
      actions.push({
        title: 'Notifications',
        disabled: false,
        icon: 'notifications_active',
        clickHandler: () => this.onDisableNotifications()
      } as SettingsAction);
    }
    return actions;
  }
}
