import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { DriverSearchModule } from './driver-search.module';
import { Driver } from '@fleetoperate/shared/driver/data-access-driver';

@Injectable({
  providedIn: DriverSearchModule
})
export class DriverSearchService {

  constructor() { }

  findDrivers(): Observable<Driver[]> {
    const drivers = [];
    return of(drivers);
  }
}
