import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FleetoperateApiService, Response } from '@fleetoperate/shared/data-access';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { ShipperDataModule } from './shipper-data.module';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Shipper } from '../models/shipper';
import { Company } from '../models/company';

const PATH_SHIPPERS = '/shippers';
const PATH_SHIPPERS_ID = PATH_SHIPPERS + '/{id}';

@Injectable({
  providedIn: ShipperDataModule
})
export class ShipperApiService {
  constructor(
    private readonly api: FleetoperateApiService,
    private readonly authService: AuthService,
    @Inject('apiUrl') private apiUrl: string,
    private readonly http: HttpClient
  ) {}

  getShippers(): Observable<Response> {
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${PATH_SHIPPERS}?include=company`, {}, this.authService.token)));
  }

  getShipper(): Observable<Response> {
    const path = PATH_SHIPPERS_ID.replace('{id}', this.authService.userID);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.get(`${this.apiUrl}${path}?include=company`, {}, this.authService.token)));
  }

  updateShipper(shipper: Shipper): Observable<Response> {
    const path = PATH_SHIPPERS_ID.replace('{id}', `${shipper.id}`);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, shipper, this.authService.token)));
  }

  updateShipperCompany(shipperID: number, company: Company): Observable<Response> {
    const path = PATH_SHIPPERS_ID.replace('{id}', `${shipperID}/company/${company.id}`);
    return this.authService
      .isAuthenticated()
      .pipe(mergeMap(() => this.api.put(`${this.apiUrl}${path}`, company, this.authService.token)));
  }
}
