import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input() count: number;
  @Input() activeIndicator: number;

  indicators: Array<number>;

  constructor() {
    this.indicators = Array.from(Array(0).keys());
    this.activeIndicator = 0;
  }

  ngOnInit() {
    this.indicators = Array.from(Array(this.count), (_,x) => x + 1);
  }

}
