import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@fleetoperate/shared/authentication/feature-authentication';
import { ROUTE_DRIVER_SEARCH } from './shared/routes';
import { ShipperGuardService } from '@fleetoperate/delivery-shipper/feature-shell';
import { DriverSearchComponent } from './driver-search/driver-search/driver-search.component';

const routes: Routes = [
  { path: ROUTE_DRIVER_SEARCH, component: DriverSearchComponent, canActivate: [AuthGuardService, ShipperGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeliveryShipperFeatureDriverRoutingModule {}
