import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimeService } from './date-time.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [DateTimeService]
})
export class DateTimeModule { }
