import { Injectable, Inject } from '@angular/core';
import { SharedUnsubscribeEmailDataAccessUnsubscribeEmailModule } from '../shared-unsubscribe-email-data-access-unsubscribe-email.module';
import { FleetoperateApiService, Response } from '@fleetoperate/shared/data-access';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { Observable } from 'rxjs';
import { EmailModel } from '../model/email-model';

const PATH_UNSUBSCRIBE_EMAIL = '/emails/unsubscribe';

@Injectable({
  providedIn: SharedUnsubscribeEmailDataAccessUnsubscribeEmailModule
})
export class UnsubscribeEmailApiService {

  constructor(private readonly api: FleetoperateApiService,
    private readonly authService: AuthService,
    @Inject('apiUrl') private apiUrl: string) { }

  updateEmailToUnsubscribe(model: EmailModel): Observable<Response> {
    const path = PATH_UNSUBSCRIBE_EMAIL;
    return this.api.post(`${this.apiUrl}${path}`, model);
  }
}
