import { ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { Sort, SortDirection } from '@angular/material/sort';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  ShipperDataService,
  ShipmentDataService,
  Shipment
} from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { ROUTE_SHIPMENT_DETAILS, ROUTE_EDIT_SHIPMENT } from '../../shared/routes';
import { CurrencyFormatService } from '@fleetoperate/shared/util';
import {
  SHIPMENT_STATUS_DRAFT,
  SHIPMENT_STATUS_UNSECURED,
  SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING,
  SHIPMENT_STATUS_BOOKED,
  SHIPMENT_STATUS_BILL_OF_LADING_AVAILABLE,
  SHIPMENT_STATUS_OUT_FOR_DELIVERY,
  SHIPMENT_STATUS_DELIVERED,
  SHIPMENT_STATUS_COMPLETED,
  SHIPMENT_STATUS_INACTIVE
} from '../../shared/shipment-status';
import { User } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { ROUTE_SHIPPER_PROFILE } from '@fleetoperate/delivery-shipper/feature-profile';
import * as moment from 'moment-timezone';
import { TabActionLink } from '@fleetoperate/shared/ui/header';
import { OverlayService, OverlayModalRef } from '@fleetoperate/shared/ui/overlay';
import { CreateLoadComponent } from '../../create-load/create-load.module';
import { ShipmentQuotesComponent } from '../../shipment-quotes/shipment-quotes/shipment-quotes.component';

interface ShipmentModel extends Shipment {
  color: string;
  formattedPickupDateAndTime: string;
  formattedDeliveryDateAndTime: string;
  formattedOfferRate: string;
  actions: ShipmentAction[];
}

class ShipmentAction {
  name: string;
  action: Function;
  icon: string;
}

const EDIT_BUTTON_LABEL = 'EDIT';
const DETAIL_BUTTON_LABEL = 'DETAIL';
const QUOTES_BUTTON_LABEL = 'QUOTES';

const SECONDARY_DETAIL_BUTTON_ICON = 'visibility';

const DIRECTION_ASCENDING = 'asc';

const PRICE_SORT = 'price';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss']
})
export class ShipmentListComponent implements OnInit, OnDestroy {
  shipperProfilePath = ROUTE_SHIPPER_PROFILE;
  separatorKeysCodes: number[] = [ENTER];
  addOnBlur = true;
  statusCtrl = new FormControl();
  idCntrl = new FormControl('');
  filteredStatus: Observable<string[]>;
  currentStatus: string[] = [];
  allStatus: string[] = [
    'Draft',
    'Unsecured',
    'Confirmation Pending',
    'Booked',
    'Out for delivery',
    'Delivered',
    'Completed',
    'Inactive'
  ];
  shipmentList: ShipmentModel[] = [];
  displayedColumns = [
    'shipmentId',
    'mode',
    'shipmentReference',
    'price',
    'origin',
    'pickup',
    'destination',
    'delivery',
    'currentLocation',
    'status',
    'actions'
  ];
  filteredShipments: ShipmentModel[] = [];
  loading: boolean;
  pageSizeOptions = [10, 20, 50];
  shipmentsMessage: string;
  user: User;
  actions: Array<TabActionLink> = [];
  title: string;

  private priceSortDirection: SortDirection;
  private overlayRef: OverlayModalRef;
  private companyId: string;

  @ViewChild('statusInput', { static: false }) statusInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('idInput', { static: false }) idInput: ElementRef<HTMLInputElement>;

  constructor(
    private readonly router: Router,
    private readonly shipmentDataService: ShipmentDataService,
    private readonly shipperDataService: ShipperDataService,
    private readonly currencyFormatService: CurrencyFormatService,
    private readonly overlayService: OverlayService
  ) {
    this.filteredStatus = this.statusCtrl.valueChanges.pipe(
      startWith(null),
      map((status: string | null) => (status ? this._filter(status) : this.allStatus.slice()))
    );
    this.title = 'My Shipments';
  }

  ngOnInit() {
    const shipper = this.shipperDataService.getShipper();
    if (shipper) {
      this.user = {
        name: `${shipper.firstName} ${shipper.lastName}`
      };
    }
    this.companyId = shipper ? shipper.companyID : undefined;
    this.loadShipments(this.companyId);
    this.actions.push({
      title: 'CREATE NEW LOAD',
      clickHandler: () => this.onCreateLoad()
    } as TabActionLink);
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.close();
    }
  }

  onLogout(): void {
    this.shipperDataService.logout();
  }

  loadShipments(companyId: string, page = 1, perPage = 10): void {
    this.loading = true;
    this.shipmentDataService.getShipments(page, perPage, companyId).subscribe((shipments: Shipment[]) => {
      this.loading = false;
      this.shipmentList = (shipments as ShipmentModel[]) || [];
      this.idCntrl.reset();
      this.filterShipmentByStatus();
      this.addPropertiesToShipment(this.filteredShipments);
    });
  }

  remove(status: string): void {
    const index = this.currentStatus.indexOf(status);

    if (index >= 0) {
      this.currentStatus.splice(index, 1);
    }
    this.filterShipmentByStatus();
  }

  clearInput() {
    this.statusInput.nativeElement.value = '';
  }

  clearFilters() {
    this.currentStatus = [];
    this.idInput.nativeElement.value = '';
    this.filterShipmentByStatus();
  }

  onSearchInput() {
    this.filterShipment();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.currentStatus.includes(event.option.viewValue)) {
      this.currentStatus.push(event.option.viewValue);
      this.filterShipmentByStatus();
    }
    this.statusInput.nativeElement.value = '';
    this.statusCtrl.setValue(null);
  }

  sortShipments(sort: Sort) {
    const shipments = this.filteredShipments.slice();
    this.priceSortDirection = sort.direction;
    const isAsc = this.priceSortDirection === DIRECTION_ASCENDING;
    if (sort.active && this.priceSortDirection) {
      this.filteredShipments = shipments.sort((a, b) => {
        switch (sort.active) {
          case PRICE_SORT:
            return this.compare(a.offerRate, b.offerRate, isAsc);
          default:
            return 0;
        }
      });
    } else {
      this.filteredShipments = shipments;
    }
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  filterShipmentByStatus() {
    if (this.currentStatus.length) {
      this.filteredShipments = this.shipmentList.filter(shipment => {
        const matchedStatuses = this.currentStatus.filter((current: string) => {
          return current && shipment.status && current.toLowerCase() === shipment.status.toLowerCase();
        });
        return matchedStatuses && matchedStatuses.length > 0;
      });
    } else {
      this.filteredShipments = this.shipmentList;
    }
    this.sortShipments({ active: PRICE_SORT, direction: this.priceSortDirection } as Sort);
  }

  handlePage(pageEvent: PageEvent) {
    this.loadShipments(this.companyId, pageEvent.pageIndex + 1, pageEvent.pageSize);
  }

  filterShipment() {
    let searchText = this.idCntrl.value;
    if (searchText) {
      this.filteredShipments = this.shipmentList.filter(shipment => {
        if (!shipment) {
          return;
        }
        if (
          this.searchShipmentIDs(shipment.shipmentID, searchText) ||
          this.searchOrigins(shipment.origin, searchText) ||
          this.searchDestinations(shipment.destination, searchText) ||
          this.searchEstimatedPickup(shipment.formattedPickupDateAndTime, searchText) ||
          this.searchMode(shipment.fitsIn, searchText)
        ) {
          return shipment;
        }
      });
      this.sortShipments({ active: PRICE_SORT, direction: this.priceSortDirection } as Sort);
    }
  }

  refreshShipments() {
    this.loadShipments(this.companyId);
  }

  private searchShipmentIDs(shipmentID: string, searchText: any): boolean {
    if (shipmentID && searchText) {
      return shipmentID.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  private searchOrigins(origin: string, searchText: any): boolean {
    if (origin && searchText) {
      return origin.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  private searchDestinations(destination: string, searchText: any): boolean {
    if (destination && searchText) {
      return destination.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  private searchEstimatedPickup(pickupDate: string, searchText: any): boolean {
    if (pickupDate && searchText) {
      return pickupDate.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  private searchMode(mode: string, searchText: any): boolean {
    if (mode && searchText) {
      return mode.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  onCreateLoad(): void {
    this.overlayRef = this.overlayService.showDetailPanel({
      data: CreateLoadComponent,
      dataPassed: { shipmentId: undefined },
      panelClass: 'detail-panel'
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allStatus.filter(status => status.toLowerCase().indexOf(filterValue) === 0);
  }

  private addPropertiesToShipment(shipments: ShipmentModel[]): void {
    if (!shipments) {
      return;
    }
    shipments.forEach((shipment: ShipmentModel) => {
      if (!shipment) {
        return;
      }
      this.assignColorToShipment(shipment);
      this.assignActionToShipment(shipment);
      this.assignFormattedCurrency(shipment);
      shipment.formattedPickupDateAndTime = this.assignPickupDateAndTime(shipment);
      shipment.formattedDeliveryDateAndTime = this.assignDeliveryDateAndTime(shipment);
    });
  }

  private assignColorToShipment(shipment: ShipmentModel): void {
    shipment.color = this.determineColor(shipment.status);
  }

  private assignActionToShipment(shipment: ShipmentModel): void {
    const actions: ShipmentAction[] = [];

    if (shipment && shipment.status) {
      if (shipment.status === SHIPMENT_STATUS_DRAFT) {
        actions.push(this.createEditShipmentAction(shipment));
      } else if (
        shipment.status === SHIPMENT_STATUS_UNSECURED ||
        shipment.status === SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING
      ) {
        actions.push(this.createSecondaryDetailShipmentAction(shipment));
        actions.push(this.createQuotesShipmentAction(shipment));
      } else {
        actions.push(this.createDetailShipmentAction(shipment));
      }
    }

    shipment.actions = actions;
  }

  private assignFormattedCurrency(shipment: ShipmentModel): void {
    shipment.formattedOfferRate = this.currencyFormatService.convertToCurrency(shipment.offerRate);
  }

  private createEditShipmentAction(shipment: ShipmentModel): ShipmentAction {
    return {
      name: EDIT_BUTTON_LABEL,
      action: (data: any) => this.onEditButtonClick(shipment),
      icon: ''
    } as ShipmentAction;
  }

  private createQuotesShipmentAction(shipment: ShipmentModel): ShipmentAction {
    return {
      name: QUOTES_BUTTON_LABEL,
      action: (data: any) => this.onQuotesButtonClick(shipment),
      icon: ''
    } as ShipmentAction;
  }

  private createSecondaryDetailShipmentAction(shipment: ShipmentModel): ShipmentAction {
    return {
      name: '',
      action: (data: any) => this.onDetailButtonClick(shipment),
      icon: SECONDARY_DETAIL_BUTTON_ICON
    } as ShipmentAction;
  }

  private createDetailShipmentAction(shipment: ShipmentModel): ShipmentAction {
    return {
      name: DETAIL_BUTTON_LABEL,
      action: (data: any) => this.onDetailButtonClick(shipment),
      icon: ''
    } as ShipmentAction;
  }

  private onEditButtonClick(shipment) {
    this.router.navigate([ROUTE_EDIT_SHIPMENT, shipment.id]);
  }

  private onQuotesButtonClick(shipment) {
    this.overlayRef = this.overlayService.showDetailPanel({
      data: ShipmentQuotesComponent,
      dataPassed: { shipmentId: shipment.id },
      panelClass: 'detail-panel'
    });
  }

  private onDetailButtonClick(shipment: ShipmentModel) {
    this.router.navigate([ROUTE_SHIPMENT_DETAILS, shipment.id]);
  }

  assignPickupDateAndTime(shipment: ShipmentModel): string {
    if (shipment && shipment.pickup) {
      const fromTime = moment.utc(shipment.pickup.pickupDateFrom).tz('America/New_York');
      const toTime = shipment.pickup.pickupDateTo
        ? moment.utc(shipment.pickup.pickupDateTo).tz('America/New_York')
        : undefined;
      const pickupDateFrom = moment
        .utc(shipment.pickup.pickupDateFrom)
        .tz('America/New_York')
        .format('MMM DD YYYY');
      return this.determineFormattedDateTime(pickupDateFrom, fromTime, toTime);
    }
  }

  assignDeliveryDateAndTime(shipment: ShipmentModel): string {
    if (shipment && shipment.delivery) {
      const fromTime = moment.utc(shipment.delivery.deliveryDateFrom).tz('America/New_York');
      const toTime = shipment.delivery.deliveryDateTo
        ? moment.utc(shipment.delivery.deliveryDateTo).tz('America/New_York')
        : undefined;
      const deliveryDateFrom = moment
        .utc(shipment.delivery.deliveryDateFrom)
        .tz('America/New_York')
        .format('MMM DD YYYY');

      return this.determineFormattedDateTime(deliveryDateFrom, fromTime, toTime);
    }
  }

  private determineFormattedDateTime(date: string, fromTime: moment.Moment, toTime: moment.Moment): string {
    if (date && fromTime && toTime) {
      return `${date}, ${fromTime.format('hh:mm a')} - ${toTime.format('hh:mm a')}`;
    } else if (date && toTime) {
      return `${date}, by ${toTime.format('hh:mm a')}`;
    } else if (date && fromTime) {
      return `${date}, ${fromTime.format('hh:mm a')}`;
    } else if (date) {
      return `${date}`;
    } else {
      return '';
    }
  }

  private determineColor(status: string): string {
    switch (status) {
      case SHIPMENT_STATUS_DRAFT: {
        return 'shipment-draft';
        break;
      }
      case SHIPMENT_STATUS_UNSECURED: {
        return 'shipment-unsecured';
        break;
      }
      case SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING: {
        return 'shipment-confirmation-pending';
        break;
      }
      case SHIPMENT_STATUS_BOOKED: {
        return 'shipment-booked';
        break;
      }
      case SHIPMENT_STATUS_BILL_OF_LADING_AVAILABLE: {
        return 'shipment-bill-of-lading-available';
        break;
      }
      case SHIPMENT_STATUS_OUT_FOR_DELIVERY: {
        return 'shipment-out-for-delivery';
        break;
      }
      case SHIPMENT_STATUS_DELIVERED: {
        return 'shipment-delivered';
        break;
      }
      case SHIPMENT_STATUS_COMPLETED: {
        return 'shipment-completed';
        break;
      }
      case SHIPMENT_STATUS_INACTIVE: {
        return 'shipment-inactive';
        break;
      }
      default: {
        return '';
        break;
      }
    }
  }
}
