import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryShipperFeatureShipmentRoutingModule } from './delivery-shipper-feature-shipment-routing.module';
import { SharedDeliveryShipperDataAccessShipperModule } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { ShipmentListModule } from './shipment-list/shipment-list.module';
import { CreateLoadModule } from './create-load/create-load.module';
import { ShipmentDetailsModule } from './shipment-details/shipment-details.module';
import { ShipmentQuotesModule } from './shipment-quotes/shipment-quotes.module';
@NgModule({
  imports: [
    CommonModule,
    ShipmentListModule,
    CreateLoadModule,
    ShipmentDetailsModule,
    ShipmentQuotesModule,
    SharedDeliveryShipperDataAccessShipperModule,
    DeliveryShipperFeatureShipmentRoutingModule
  ]
})
export class DeliveryShipperFeatureShipmentModule {}
