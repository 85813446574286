<div class="mat-headline padding-top" [ngStyle.lt-md]="{ color: '#225a99' }">Login with FleetOperate</div>
<mat-error *ngIf="errorMessage" class="error" data-test="form-error-message">
  {{ errorMessage }}
</mat-error>
<form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column">
  <mat-form-field>
    <input type="email" matInput placeholder="Email" formControlName="email" data-test="email-form-field" />
    <mat-error *ngIf="email.touched && email.invalid" data-test="email-error-field">
      {{ getEmailErrorMessage() }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-hint align="start">at least 8 characters</mat-hint>
    <input
      matInput
      placeholder="Password"
      [type]="hidePassword ? 'password' : 'text'"
      formControlName="password"
      data-test="password-form-field"
    />
    <mat-icon matSuffix (click)="hidePassword = !hidePassword" data-test="password-visibility-button">{{
      hidePassword ? 'visibility' : 'visibility_off'
    }}</mat-icon>
    <mat-error *ngIf="password.touched && password.invalid" data-test="password-error-field">
      {{ getPasswordErrorMessage() }}
    </mat-error>
  </mat-form-field>
  <span fxLayout class="padding-top-sm padding-bottom-md">
    <a fxFlex [routerLink]="" (click)="onNavigateToForgotPassword()" data-test="password-reset-link"
      >Forgot password?</a
    >
  </span>
  <div class="actions full-width padding-top-x4">
    <button
      class="full-width margin-bottom"
      data-test="login-button"
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="form.pristine || !form.valid"
    >
      Login
    </button>
    <button
      *ngIf="allowSignUp"
      class="full-width margin-bottom"
      mat-button
      type="button"
      (click)="onNavigateToSignup()"
    >
      Don't have an account? Sign up
    </button>
  </div>
</form>
