import { Injectable } from '@angular/core';
import { DateTimeModule } from './date-time.module';
import * as moment from 'moment-timezone';

export const MMM_DD_YYYY_h_mm_a_z = 'MMM DD, YYYY h:mm a z';
export const timezoneEDT = moment.tz('America/New_York').format('z');
const MMM_DD_YYYY = 'MMM DD YYYY';
const HH_MM_A = 'hh:mm a';
const HH_MM_A_Z = 'hh:mm a z';

@Injectable({
  providedIn: DateTimeModule
})
export class DateTimeService {
  constructor() {}

  getLocalDateTime(utcDateTime: string): string {
    return moment
      .utc(utcDateTime)
      .local()
      .format();
  }

  printDateTime(dateTime: string): string {
    return moment(dateTime).format(MMM_DD_YYYY);
  }

  getMinPickupTime() {
    const minPickupTime = moment(this.getCurrentDate())
      .tz('America/New_York')
      .add(3, 'hours')
      .format('HH:00');
    return minPickupTime;
  }

  getMinDeliveryTime() {
    const minDeliveryTime = moment(this.getCurrentDate())
      .tz('America/New_York')
      .add(4, 'hours')
      .format('HH:00');
    return minDeliveryTime;
  }

  getCurrentDate(): Date {
    return moment()
      .tz('America/New_York')
      .toDate();
  }

  formatDateTime(utcDate: string, format: string): string {
    return moment
      .utc(utcDate)
      .tz('America/New_York')
      .format(format);
  }

  getUTCDate(): Date {
    return moment.utc().toDate();
  }

  getUTCDateFormatted(): string {
    return moment.utc().format();
  }

  getTime(dateTime: string): string {
    return moment(dateTime)
      .tz('America/New_York')
      .format('HHmm');
  }

  addDateTime(date: string, time: string): string {
    const momentDate = moment.tz(date, 'America/New_York').format('YYYY-MM-DDT');
    const momentTime = moment(time, 'HHmm').format('HH:mm:ss');
    const addedDateTime = `${momentDate}${momentTime}`;
    const utcDateTime = moment
      .tz(addedDateTime, 'America/New_York')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss[Z]');

    return utcDateTime === 'Invalid date' ? undefined : utcDateTime;
  }

  combineDateTime(edtDate: string, edtTime: string): Date {
    const momentDate = moment.tz(edtDate, 'America/New_York').format('YYYY-MM-DDT');
    const momentTime = moment(edtTime, 'HHmm').format('HH:mm:ss');
    const addedDateTime = `${momentDate}${momentTime}`;
    const dateTimeEDT = moment.tz(addedDateTime, 'America/New_York').toDate();
    return dateTimeEDT;
  }

  getISOString(moment: moment.Moment): string {
    return moment.format('YYYY-MM-DDTHH:mm:ss');
  }

  convertDateTimeStringFromUTCDateTimeString(utcDateTime: string): string {
    return moment
      .utc(utcDateTime)
      .tz('America/New_York')
      .format('MMM DD,YYYY hh:mm a z');
  }

  getCurrentMoment(): moment.Moment {
    return moment().tz('America/New_York');
  }

  subtractMilliseconds(date: Date, milliseconds: number): string {
    return moment(date)
      .subtract(milliseconds, 'ms')
      .format();
  }

  subtractDates(date1: string, date2: string): number {
    return moment(date2).diff(moment(date1));
  }

  getConvertedDateAndTime(utcDateFrom: string, utcDateTo: string): string {
    if (utcDateFrom) {
      const ConvertedDateFrom = moment.utc(utcDateFrom).tz('America/New_York');
      const ConvertedDateTo = utcDateTo ? moment.utc(utcDateTo).tz('America/New_York') : undefined;

      return this.determineFormattedDateTime(ConvertedDateFrom, ConvertedDateFrom, ConvertedDateTo);
    }
  }

  private determineFormattedDateTime(date: moment.Moment, fromTime: moment.Moment, toTime: moment.Moment): string {
    if (date && fromTime && toTime) {
      return `${date.format(MMM_DD_YYYY)}, ${fromTime.format(HH_MM_A)} - ${toTime.format(HH_MM_A_Z)}`;
    } else if (date && toTime) {
      return `${date.format(MMM_DD_YYYY)}, by ${toTime.format(HH_MM_A_Z)}`;
    } else if (date && fromTime) {
      return `${date.format(MMM_DD_YYYY)}, ${fromTime.format(HH_MM_A_Z)}`;
    } else if (date) {
      return `${date.format(MMM_DD_YYYY)}`;
    } else {
      return '';
    }
  }
}
