<div fxLayout="column" fxLayoutAlign="center stretch" class="loginPageBackground">
  <div fxLayout fxFlexAlign="center" class="brand-title">
    <img src="assets/img/FleetOperate-white-logo.png" />
    <div class="fleetoperate-logo-light" fxFlexAlign="center" fxLayout="column">
      <span class="fleetoperate-logo-top"></span>
      <span class="fleetoperate-logo-bottom"></span>
    </div>
  </div>
  <div>
    <h1 class="title">Forgot <span>Password</span></h1>
  </div>
  <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex class="padding-top-x2">
    <div fxFlex="none" fxLayout="column" fxLayoutAlign="center stretch" class="padding-top-x2">
      <mat-error fxFlexAlign="center" *ngIf="errorMessage" class="error">
        {{errorMessage}}
      </mat-error>
      <mat-label *ngIf="successMessage" fxFlexAlign="center">{{successMessage}}</mat-label>
      <form [formGroup]="form" #forgotPasswordForm="ngForm" (ngSubmit)="onSubmit(forgotPasswordForm)" fxLayout="column">
        <mat-form-field>
          <mat-hint align="start">at least 8 characters</mat-hint>
          <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password">
          <mat-placeholder>New Password</mat-placeholder>
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}
          </mat-icon>
          <mat-error *ngIf="password.touched && password.invalid">
            {{getPasswordErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-hint align="start">at least 8 characters</mat-hint>
          <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="confirmPassword"
            [errorStateMatcher]="matcher">
          <mat-placeholder>Re-enter New Password</mat-placeholder>
          <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
            {{hideConfirmPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-error *ngIf="confirmPasswordHasError()">
            {{getConfirmPasswordErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <div fxLayout fxLayoutAlign="center end" class="padding-top-lg">
          <button fxFlex.gt-sm="20%" fxFlex.lt-md="100%" class="full-width margin-bottom" mat-raised-button
            color="primary" type="submit" [disabled]="form.pristine || !form.valid || loading">Save</button>
        </div>
        <span fxFlexAlign="center" class="margin-top-lg">
          < <a [routerLink]="" (click)="onNavigateToSignIn()">back to Signin</a>
        </span>
      </form>
    </div>
  </div>