import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, NgForm, Validators, FormGroupDirective, FormControl } from '@angular/forms';
import { SubSink } from 'subsink';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangePasswordModel } from '../shared/change-password.model';
import { ForgotPasswordService } from '../forgot-password.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { LoggerService } from '@fleetoperate/shared/util';
import { ROUTE_SIGNIN } from '../shared/routes';


const RequiredFieldMessage = 'You must enter a value';
const PasswordsDoNotMatchMessage = 'Passwords do not match';
const MinimumLengthFieldMessage = (value: number) => `Must be atleast ${value} characters`;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading: boolean;
  hidePassword: boolean;
  hideConfirmPassword: boolean;
  errorMessage: string;
  successMessage: string;
  matcher: ErrorStateMatcher;

  private subs = new SubSink();
  private email: string;
  private verificationCode: string;
  private signature: string;

  constructor(private readonly fb: FormBuilder,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly forgotPasswordService: ForgotPasswordService,
              private readonly logger: LoggerService) {
    this.loading = false;
    this.errorMessage = undefined;
    this.successMessage = undefined;
    this.hidePassword = true;
    this.hideConfirmPassword = true;
    this.form = this.createForm();
    this.matcher = new MyErrorStateMatcher();
  }

  ngOnInit() {
    this.email = this.activatedRoute.snapshot.queryParams.email;
    this.verificationCode = this.activatedRoute.snapshot.queryParams.verificationCode;
    this.signature = this.activatedRoute.snapshot.queryParams.signature;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSubmit(forgotPasswordForm: NgForm) {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    this.successMessage = undefined;
    this.errorMessage = undefined;

    const model = this.prepareSaveModel();

    this.subs.add(this.forgotPasswordService.changePassword(model).subscribe((result: ChangePasswordModel) => {
      this.successMessage = `Password was reset.`;
      this.logger.log('Reset form');
      this.form.reset();
      forgotPasswordForm.resetForm();
      this.loading = false;
    }, (error: any) => {
      this.errorMessage = error.message;
      this.loading = false;
    }));
  }

  onNavigateToSignIn(): void {
    this.router.navigate([`/${ROUTE_SIGNIN}`]);
  }

  get password() { return this.form.get('password'); }
  get confirmPassword() { return this.form.get('confirmPassword'); }

  getPasswordErrorMessage(): string {
    const errors = this.password.errors;

    return errors.required ? RequiredFieldMessage :
      errors.minlength ? MinimumLengthFieldMessage(errors.minlength.requiredLength) : '';
  }

  confirmPasswordHasError(): boolean {
    return this.confirmPassword.touched && this.confirmPassword.invalid || this.form.hasError('notSame');
  }

  getConfirmPasswordErrorMessage(): string {
    const errors = this.confirmPassword.errors;

    let message = errors && errors.minlength ? MinimumLengthFieldMessage(errors.minlength.requiredLength) : '';
    message = !message && this.form.hasError('notSame') ? PasswordsDoNotMatchMessage : message;

    return message;
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.minLength(8)]]
    }, {validator: this.checkPasswords});

    return form;
  }

  private prepareSaveModel(): ChangePasswordModel {
    const formModel = this.form.value;

    const model = {
      username: this.email,
      password: formModel.password as string,
      verificationCode: this.verificationCode,
      signature: this.signature
    } as ChangePasswordModel;

    return model;
  }

  private checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return control.parent.errors && control.touched && (invalidCtrl || invalidParent);
  }
}
