import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipperService } from '../../shared/shipper.service';
import { first } from 'rxjs/internal/operators/first';
import {
  ROUTE_SHIPPER_PROFILE,
  ROUTE_PROFILE_USER_INFO,
  ROUTE_PROFILE_COMPANY_INFO,
  ROUTE_PROFILE_PASSWORD_RESET,
  ROUTE_PROFILE_LOAD_SETTINGS
} from '../../shared/routes';
import { ShipperDataService } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { User } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { ROUTE_SHIPMENT_SEARCH } from '@fleetoperate/shipper/feature-shipment';

class NavLink {
  label: string;
  link: string;
}

@Component({
  selector: 'fleetoperate-shipper-profile',
  templateUrl: './shipper-profile.component.html',
  styleUrls: ['./shipper-profile.component.scss']
})
export class ShipperProfileComponent implements OnInit {
  navLinks: NavLink[];
  activeLink: NavLink;
  user: User;
  backAction: Function;
  title: String;
  shipperProfilePath = ROUTE_SHIPPER_PROFILE;

  constructor(
    private readonly router: Router,
    private readonly shipperService: ShipperService,
    private readonly shipperDataService: ShipperDataService
  ) {
    this.backAction = () => this.router.navigate([ROUTE_SHIPMENT_SEARCH]);
    this.navLinks = this.createNavLinks();
    this.activeLink = this.navLinks.find(
      (link: NavLink) => ROUTE_SHIPPER_PROFILE + '/' + link.link === this.router.url
    );
  }

  ngOnInit() {
    this.title = 'My Profile';
    this.shipperService
      .loadShipper()
      .pipe(first())
      .subscribe();
    this.user = {
      name: `${this.shipperDataService.getShipper().firstName} ${this.shipperDataService.getShipper().lastName}`
    };
  }

  ngOnDestroy() {}

  private createNavLinks(): NavLink[] {
    return [
      {
        label: 'User Info',
        link: ROUTE_PROFILE_USER_INFO
      },
      {
        label: 'Company Information',
        link: ROUTE_PROFILE_COMPANY_INFO
      },
      {
        label: 'Password Reset',
        link: ROUTE_PROFILE_PASSWORD_RESET
      },
      {
        label: 'Load Settings',
        link: ROUTE_PROFILE_LOAD_SETTINGS
      }
    ] as NavLink[];
  }

  onLogout(): void {
    this.shipperDataService.logout();
  }
}
