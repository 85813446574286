import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_SIGNIN } from '@fleetoperate/shared/authentication/feature-authentication';
import { UnsubscribeEmailDataService } from 'libs/shared/unsubscribe-email/data-access-unsubscribe-email/src/lib/unsubscribe-email-data/unsubscribe-email-data.service';
import { EmailModel } from '../../../../data-access-unsubscribe-email/src/lib/model/email-model';
import { first } from 'rxjs/internal/operators/first';
import { ToastService } from '@fleetoperate/shared/util';
import { ROUTE_SUCCESSFUL_UNSUBSCRIBE_EMAIL, ROUTE_FAILED_UNSUBSCRIBE_EMAIL } from '../shared/routes';
@Component({
  selector: 'fleetoperate-confirm-email-unsubscribe',
  templateUrl: './confirm-email-unsubscribe.component.html',
  styleUrls: ['./confirm-email-unsubscribe.component.scss']
})
export class ConfirmEmailUnsubscribeComponent implements OnInit {

  id: any;
  signature: any;
  type: any;

  copyrightYear = 2020;
  linkedInURL: string = "https://linkedin.com/company/fleetoperate";
  facebookURL: string = "https://www.facebook.com/FleetOperate/";
  twitterURL: string = "https://twitter.com/fleetoperate";
  contactEmail: string = "help@fleetoperate.com";

  constructor(private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly unsubscribeEmailDataService: UnsubscribeEmailDataService,
              @Inject('appName') private readonly appName: string ) { }

  ngOnInit() {
    this.type= this.activatedRoute.snapshot.queryParams.type ? this.activatedRoute.snapshot.queryParams.type : this.appName;
    this.id = this.activatedRoute.snapshot.queryParams.id;
    this.signature = this.activatedRoute.snapshot.queryParams.signature;
  }

  onUnsubscibe(): void {
    const model = this.prepareSaveModel();

    this.unsubscribeEmailDataService
    .updateEmailToUnsubscribe(model)
    .pipe(first())
    .subscribe(
      () => {
        this.router.navigate([ROUTE_SUCCESSFUL_UNSUBSCRIBE_EMAIL]);
      },
      (error: any) => {
        this.router.navigate([ROUTE_FAILED_UNSUBSCRIBE_EMAIL]);
      }
    );
  }

  onKeepSubscribed(): void {
    this.router.navigate([ROUTE_SIGNIN]);
  }

  private prepareSaveModel(): EmailModel {
    const model = {
      userType: this.type,
      userId: this.id,
      signature: this.signature
    } as EmailModel;

    return model;
  }

}
