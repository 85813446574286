import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { OperateLocationModel } from '../operate-location/operate-location-model';
import { CompanyModel } from '../company/company-model';
import { VerificationModel } from '../verification/verification-model';
import {
  SetOperateLocation,
  UpdateCompany,
  ClearCompany,
  ClearRegistration,
  SetMcDotOperateLocation
} from '../registration.actions';
import { RegistrationState } from '../registration.state';
import { Navigate } from '../registration-router.state';
import { Observable, Subject } from 'rxjs';
import { LoggerService } from '@fleetoperate/shared/util';
import { Response, FleetoperateApiService } from '@fleetoperate/shared/data-access';
import { SubSink } from 'subsink';
import {
  ROUTE_REGISTRATION
} from '../shared/routes';
import { tap, switchMap } from 'rxjs/operators';
import { RegistrationModel } from '../shared/registration.model';
import { McDotOperateLocationModel } from '../mc-dot-operate-location/mc-dot-operate-location-model';

@Injectable()
export class RegistrationService {
  verificationErrorObservable: Observable<string>;

  private verificationErrorSubject: Subject<string>;
  private subSink: SubSink;

  constructor(
    private readonly store: Store,
    private readonly logger: LoggerService,
    private readonly api: FleetoperateApiService,
    @Inject('registrationApiUrl') private registrationApiUrl: any
  ) {
    this.subSink = new SubSink();
    this.verificationErrorSubject = new Subject<string>();
    this.verificationErrorObservable = this.verificationErrorSubject.asObservable();
  }

  clearRegistrationInformation(): void {
    this.store.dispatch([new ClearRegistration()]);
  }

  getOperateLocation(): OperateLocationModel {
    return this.store.selectSnapshot(RegistrationState.operateLocation);
  }

  saveOperateLocation(operateInUS: boolean, dot: string, forwardRoute: string): void {
    this.store.dispatch([new SetOperateLocation(operateInUS, dot), new Navigate(forwardRoute)]);
  }

  getMcDotOperateLocation(): McDotOperateLocationModel {
    return this.store.selectSnapshot(RegistrationState.mcDotOperateLocation);
  }

  saveMcDotOperateLocation(mc: number, dot: number, forwardRoute: string): void {
    this.store.dispatch([new SetMcDotOperateLocation(mc, dot), new Navigate(forwardRoute)]);
  }

  getCompany(): CompanyModel {
    return this.store.selectSnapshot(RegistrationState.company);
  }

  saveCompany(model: CompanyModel, forwardRoute: string): void {
    this.store.dispatch([new UpdateCompany(model), new Navigate(forwardRoute)]);
  }

  saveCompanyAddress(model: CompanyModel): Observable<Response> {
    return this.store.dispatch([new UpdateCompany(model)]).pipe(
      switchMap(() => {
        // TODO: Use an improved way to switch between operationLocation state model and mcOperationLocation state model.
        const operationLocation =
          this.registrationApiUrl && this.registrationApiUrl.includes('/carriers')
            ? this.getMcDotOperateLocation()
            : this.getOperateLocation();
        const registrationModel = Object.assign({}, this.getCompany(), operationLocation) as RegistrationModel;
        return this.submitRegistration(registrationModel);
      }),
      tap((response: Response) => {
        const companyModel = Object.assign({}, this.getCompany(), { id: response.data.companyId });
        this.store.dispatch(new UpdateCompany(companyModel));
      })
    );
  }

  sendVerificationCode(verificationModel: VerificationModel, forwardRoute: string): void {
    if (!verificationModel) {
      return;
    }

    const body = Object.assign({}, verificationModel, {
      email: this.getCompany().email,
      companyId: this.getCompany().id
    });
    const params = {};
    this.subSink.add(
      this.sendCompanyVerificationAPI(params, body)
        /* .catch((error: any) => {
        const message = error && error.data && error.data.message ? error.data.message : 'Something went wrong, please contact support';
        this.verificationErrorSubject.next(message);
        return Observable.throw(error || 'Server error');
      }) */
        // .first()
        .subscribe((response: any) => {
          if (!response || !response.data) {
            this.verificationErrorSubject.next('Something went wrong, please contact support');
          }

          if (response.data.verified === false) {
            this.verificationErrorSubject.next('Verification failed! Please try again.');
          } else {
            this.store.dispatch([new ClearCompany(), new Navigate(forwardRoute)]);
            this.verificationErrorSubject.next(null);
          }
        })
    );
  }

  resendVerificationCode(): void {
    if (!this.getCompany().id) {
      return;
    }

    const body = { companyId: this.getCompany().id };
    const params = {};
    this.subSink.add(
      this.resendCompanyVerificationAPI(params, body)
        // .catch(this.handleError)
        // .first()
        .subscribe((response: any) => {
          // intentially empty
        })
    );
  }

  navigateToSignIn(): void {
    this.store.dispatch([new Navigate('signin')]);
  }

  navigateToSignUp(): void {
    this.clearRegistrationInformation();
    this.store.dispatch([new Navigate(ROUTE_REGISTRATION)]);
  }

  navigateToUserFirstTimeLogIn(): void {
    this.store.dispatch([new Navigate('userFirstTimeLogIn')]);
  }

  navigateToPreviousStep(backRoute: string): void {
    this.store.dispatch([new Navigate(backRoute)]);
  }

  navigateToNextStep(forwardRoute: string): void {
    this.store.dispatch([new Navigate(forwardRoute)]);
  }

  private extractData(response: Response) {
    return response || {};
  }

  private handleError(error: HttpResponse<string>) {
    this.logger.error(error.body);
    return Observable.throw(error || 'Server error');
  }

  private submitRegistration(registration: RegistrationModel): Observable<Response> {
    return this.api.post(`${this.registrationApiUrl}`, registration);
  }

  private sendCompanyVerificationAPI(params: any, body): Observable<any> {
    return Observable.create((observer: any) => {
      const data = { data: { verified: true } };
      observer.next(data);
    });
  }

  private resendCompanyVerificationAPI(params: any, body): Observable<any> {
    return Observable.create((observer: any) => {
      const data = {};
      observer.next(data);
    });
  }
}
