import { Injectable } from '@angular/core';
import { ErrorDataModule } from './error-data.module';
import { Error } from '../models/error.model';
import { ErrorApiService } from './error-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Response } from '@fleetoperate/shared/data-access';

@Injectable({
  providedIn: ErrorDataModule
})
export class ErrorDataService {
  constructor(private readonly errorsAPIService: ErrorApiService) {}

  loadErrors(capturedTime: string): Observable<Error[]> {
    /* return this.errorsAPIService.getErrors(capturedTime).pipe(map((response: Response) => response.data as Error[])); */
    return this.errorsAPIService
      .getMockErrors(capturedTime)
      .pipe(map((response: Response) => response.data as Error[]));
  }

  createError(error: Error): Observable<Error> {
    return this.errorsAPIService.createError(error).pipe(map((response: any) => response.data as Error));
  }
}
