import { Component, OnInit, Inject } from '@angular/core';
import { OverlayModalRef } from './overlay-ref';
import { COMPONENT_DATA } from './overlay.tokens';

@Component({
  selector: 'fleetoperate-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {
  constructor(public overlayRef: OverlayModalRef, @Inject(COMPONENT_DATA) public data: any) {}

  ngOnInit() {}
}
