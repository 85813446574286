import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { ShipperDataState } from './state/shipper-data-state';
import { StorageModule } from '@fleetoperate/shared/util';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StorageModule,
    NgxsModule.forFeature([ShipperDataState])
  ]
})
export class ShipperDataModule { }
