import { Injectable } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { CurrencyFormatModule } from './currency-format.module';

@Injectable({
  providedIn: CurrencyFormatModule
})
export class CurrencyFormatService {

  constructor() { }

  convertToCurrency(offerRate: number): string {
    if (offerRate === undefined || offerRate === null) {
      return;
    }
    return this.changeCurrency(offerRate);
  }

  private changeCurrency(offerRate: number): string {
    let result: string;

    const locale = 'en-US';
    const currencyCode = 'USD';
    const digitsInfo = '1.2-2';
    result = formatCurrency(
      offerRate,
      locale,
      getCurrencySymbol(currencyCode, 'narrow'),
      currencyCode,
      digitsInfo,
    );

    return result;
  }
}
