import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrencyFormatService } from '@fleetoperate/shared/util';

class Action {
  name: string;
  action: () => any;
  disabled: boolean;
  color: string;
}

export interface DialogData {
  actorName: string;
  actorPicture: string;
  primaryOfferRate: number;
  counterOfferRate: number;
  confirmAndCancelActions: Action[];
}

@Component({
  selector: 'fleetoperate-counter-offer-quote-dialog',
  templateUrl: './counter-offer-quote-dialog.component.html',
  styleUrls: ['./counter-offer-quote-dialog.component.scss']
})

export class CounterOfferQuoteDialogComponent implements OnInit {
  actions: Action[];

  constructor(
    public dialogRef: MatDialogRef<CounterOfferQuoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly currencyFormatService: CurrencyFormatService) { }

  ngOnInit() { }

  formatCurrency(offerRate: number): string {
    return this.currencyFormatService.convertToCurrency(offerRate);
  }
}
