import { Component, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_SHIPMENT_SEARCH, ROUTE_SHIPMENT_QUOTES } from '../../shared/routes';
import {
  ShipmentDataService,
  Shipment,
  ShipperDataService
} from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { ShipmentService } from '../../shared/shipment.service';
import {
  SHIPMENT_STATUS_DRAFT,
  SHIPMENT_STATUS_UNSECURED,
  SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING,
  SHIPMENT_STATUS_BOOKED,
  SHIPMENT_STATUS_BILL_OF_LADING_AVAILABLE,
  SHIPMENT_STATUS_OUT_FOR_DELIVERY,
  SHIPMENT_STATUS_DELIVERED,
  SHIPMENT_STATUS_COMPLETED,
  SHIPMENT_STATUS_INACTIVE
} from '../../shared/shipment-status';
import { SubSink } from 'subsink';
import { User } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { ROUTE_SHIPPER_PROFILE } from 'libs/shipper/feature-profile/src/lib/shared/routes';
import { TabActionLink } from '@fleetoperate/shared/ui/header';
import { ToastService } from '@fleetoperate/shared/util';
import { DialogService, Action } from '@fleetoperate/shared/ui/dialog';
import { MatDialogRef } from '@angular/material/dialog';

const VIEW_TYPE_DEFAULT = 'default-view';
const VIEW_TYPE_UNSECURED = 'unsecured-view';
const VIEW_TYPE_DOCUMENTS = 'documents-view';
const VIEW_TYPE_TRACK_SHIPMENT = 'track-shipment-view';
const VIEW_TYPE_DRIVER_CONFIRMATION_PENDING = 'driver-confirmation-pending-view';

@Component({
  selector: 'app-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss']
})
export class ShipmentDetailsComponent implements OnInit {
  shipperProfilePath = ROUTE_SHIPPER_PROFILE;
  shipment: Shipment;
  viewType: string;
  actions: Action[];
  user: User;
  backAction: Function;
  title: string;
  headerActions: Array<TabActionLink> = [];
  loading: boolean;
  errorMessage: string;
  refreshShipmentAction: Function;

  @ViewChild('documentListComponent', { static: false }) documentListComponent;

  private subSink: SubSink;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly shipmentDataService: ShipmentDataService,
    private readonly shipmentService: ShipmentService,
    private readonly router: Router,
    private readonly shipperDataService: ShipperDataService,
    private readonly dialogService: DialogService,
    private readonly toastService: ToastService
  ) {
    this.subSink = new SubSink();
    this.shipment = {} as Shipment;
    this.viewType = VIEW_TYPE_DEFAULT;
    this.backAction = () => this.router.navigate([ROUTE_SHIPMENT_SEARCH]);
    this.refreshShipmentAction = () => this.refreshShipment();
    this.title = 'Shipment Details';
    this.loading = false;
  }

  ngOnInit(): void {
    const shipper = this.shipperDataService.getShipper();
    this.user = {
      name: `${shipper.firstName} ${shipper.lastName}`
    };
    this.actions = this.createActions(this.shipment);
    this.headerActions = this.createHeaderActions();
    this.subSink.add(
      this.shipmentService.$shipment.subscribe((shipment: Shipment) => {
        this.shipment = shipment;
        if (this.viewType === VIEW_TYPE_DEFAULT) {
          this.viewType = this.determineView(this.shipment);
        }
        this.refreshShipmentDocuments();
        this.actions = this.createActions(this.shipment);
        this.headerActions = this.createHeaderActions();
      })
    );
    this.shipmentService.loadShipment(this.route.snapshot.params.id);
  }

  private createHeaderActions(): TabActionLink[] {
    const headerActions: TabActionLink[] = [];
    if (this.shipment.status !== SHIPMENT_STATUS_COMPLETED && this.shipment.status !== SHIPMENT_STATUS_INACTIVE) {
      headerActions.push({
        title: 'Complete Shipment',
        disabled: this.loading || !(this.shipment.status === SHIPMENT_STATUS_DELIVERED),
        clickHandler: () => this.onCompleteShipment()
      } as TabActionLink);
    }
    if (this.shipment && this.shipment.status === SHIPMENT_STATUS_INACTIVE) {
      headerActions.push({
        title: 'Delete',
        disabled: this.loading,
        color: 'delete',
        clickHandler: () => this.onDelete(this.shipment.id)
      } as TabActionLink);
    }
    return headerActions;
  }

  private onDelete(shipmentId: string): void {
    const deleteDialogMessage = 'Please confirm you want to delete the inactive shipment?';
    const deleteDialogTitle = 'Delete Shipment';
    const headerActions = this.createDeleteDirtyPromptActions();
    const dialogRef = this.dialogService.openConfirmationDialog(
      headerActions,
      deleteDialogTitle,
      undefined,
      deleteDialogMessage
    );

    const cancelAction = headerActions[0];
    const confirmAction = headerActions[1];

    confirmAction.action = () => this.dialogDeleteShipment(dialogRef, shipmentId, confirmAction, cancelAction);
    cancelAction.action = () => this.dialogCancelAction(dialogRef, confirmAction, cancelAction);

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe();
  }

  private dialogDeleteShipment(
    dialogRef: MatDialogRef<any>,
    shipmentId: string,
    confirmAction: Action,
    cancelAction: Action
  ): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;

    this.shipmentDataService
      .deleteShipment(shipmentId)
      .pipe(first())
      .subscribe(() => {
        dialogRef.close();
        confirmAction.disabled = false;
        cancelAction.disabled = false;
        this.toastService.showSuccess('Sucessfully deleted shipment');
        this.router.navigate([ROUTE_SHIPMENT_SEARCH]);
      });
  }

  private dialogCancelAction(dialogRef: MatDialogRef<any>, confirmAction: Action, cancelAction: Action): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;

    dialogRef.close();
  }

  private createDeleteDirtyPromptActions(): Action[] {
    const dialogActions: Action[] = [];

    const cancelAction = {
      name: 'Cancel',
      action: (data: any) => {},
      disabled: false,
      color: 'cancel'
    } as Action;
    dialogActions.push(cancelAction);

    const confirmAction = {
      name: 'Confirm',
      action: (data: any) => {},
      disabled: false,
      color: 'delete'
    } as Action;
    dialogActions.push(confirmAction);

    return dialogActions;
  }

  private determineView(shipment?: Shipment): string {
    let view = VIEW_TYPE_DEFAULT;

    if (shipment && shipment.status) {
      switch (shipment.status) {
        case SHIPMENT_STATUS_DRAFT: {
          // TODO: Assign view here?
          break;
        }
        case SHIPMENT_STATUS_UNSECURED: {
          view = VIEW_TYPE_UNSECURED;
          break;
        }
        case SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING: {
          view = VIEW_TYPE_DRIVER_CONFIRMATION_PENDING;
          break;
        }
        case SHIPMENT_STATUS_BOOKED: {
          view = VIEW_TYPE_TRACK_SHIPMENT;
          break;
        }
        case SHIPMENT_STATUS_BILL_OF_LADING_AVAILABLE: {
          view = VIEW_TYPE_TRACK_SHIPMENT;
          break;
        }
        case SHIPMENT_STATUS_OUT_FOR_DELIVERY: {
          view = VIEW_TYPE_TRACK_SHIPMENT;
          break;
        }
        case SHIPMENT_STATUS_DELIVERED: {
          view = VIEW_TYPE_TRACK_SHIPMENT;
          break;
        }
        case SHIPMENT_STATUS_COMPLETED: {
          view = VIEW_TYPE_TRACK_SHIPMENT;
          break;
        }
        case SHIPMENT_STATUS_INACTIVE: {
          view = VIEW_TYPE_TRACK_SHIPMENT;
          break;
        }
        default: {
          view = VIEW_TYPE_DOCUMENTS;
          break;
        }
      }

      return view;
    }

    return view;
  }

  private createActions(shipment?: Shipment): Action[] {
    const actions: Action[] = [];

    if (shipment && shipment.status) {
      actions.push(...this.createDynamicActions(shipment.status));
    }

    actions.push(this.createDocumentsAction());

    return actions;
  }

  private createDynamicActions(shipmentStatus: string): Action[] {
    const actions: Action[] = [];

    if (
      SHIPMENT_STATUS_BOOKED === shipmentStatus ||
      SHIPMENT_STATUS_BILL_OF_LADING_AVAILABLE === shipmentStatus ||
      SHIPMENT_STATUS_OUT_FOR_DELIVERY === shipmentStatus ||
      SHIPMENT_STATUS_DELIVERED === shipmentStatus ||
      SHIPMENT_STATUS_COMPLETED === shipmentStatus
    ) {
      actions.push(this.createTrackShipmentAction());
    }

    return actions;
  }

  private createTrackShipmentAction(enabled: boolean = true): Action {
    return {
      name: 'Track',
      action: (data: any) => this.showTrackShipmentView(),
      disabled: !enabled
    } as Action;
  }

  private createDocumentsAction(enabled: boolean = true): Action {
    return {
      name: 'Documents',
      action: (data: any) => this.showDocumentsView(),
      disabled: !enabled
    } as Action;
  }

  private showDocumentsView(): void {
    this.viewType = VIEW_TYPE_DOCUMENTS;
  }

  private showTrackShipmentView(): void {
    this.viewType = VIEW_TYPE_TRACK_SHIPMENT;
  }

  onLogout(): void {
    this.shipperDataService.logout();
  }

  private refreshShipment(): void {
    this.loading = true;
    this.shipmentService.loadShipment(this.shipment.id);
  }

  private refreshShipmentDocuments(): void {
    if (this.viewType === VIEW_TYPE_DOCUMENTS) {
      if (this.documentListComponent) {
        this.documentListComponent.loadDocuments();
      }
    }
  }

  private onCompleteShipment(): void {
    this.loading = true;
    this.shipmentService
      .completeShipment(this.shipment.id)
      .pipe(first())
      .subscribe(
        () => {
          this.loading = false;
          this.errorMessage = undefined;
        },
        (error: { message: string }) => {
          this.loading = false;
          this.errorMessage = error && error.message;
        }
      );
  }

  onDocumentListError(errorMessage: string) {
    this.errorMessage = errorMessage;
  }
}
