<fleetoperate-delivery-shipper-app-toolbar
  [data]="user"
  (logout)="onLogout()"
></fleetoperate-delivery-shipper-app-toolbar>
<div fxLayout class="margin-left-lg margin-right-lg">
  <h1 class="title">Find a <span>Driver</span></h1>
</div>

<form
  fxLayout
  fxLayoutAlign="start center"
  class="padding-left-4x margin-left-lg margin-right-lg"
  #driverSearchForm="ngForm"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <mat-form-field class="margin-right-x2">
    <input
      type="text"
      placeholder="From"
      aria-label="Number"
      matInput
      formControlName="from"
      [matAutocomplete]="autoFrom"
    />
    <mat-autocomplete #autoFrom="matAutocomplete">
      <mat-option *ngFor="let state of (fromStates | async)" [value]="state">
        {{ state }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="margin-right-x2">
    <input
      type="text"
      placeholder="To"
      aria-label="Number"
      matInput
      formControlName="to"
      [matAutocomplete]="autoTo"
      required
    />
    <mat-autocomplete #autoTo="matAutocomplete">
      <mat-option *ngFor="let state of (toStates | async)" [value]="state">
        {{ state }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="margin-right-x2">
    <mat-label>Truck type</mat-label>
    <mat-select formControlName="truckType" required>
      <mat-option value="Van">Van</mat-option>
      <mat-option value="Reefer">Reefer</mat-option>
      <mat-option value="Flatbed">Flatbed</mat-option>
      <mat-option value="Tanker">Tanker</mat-option>
      <mat-option value="LongCombinationVehicle">Long Combination Vehicle</mat-option>
    </mat-select>
  </mat-form-field>

  <button class="margin-right-x2" mat-raised-button type="submit" [disabled]="form.pristine || !form.valid">
    {{ submitButtonText }}
  </button>
  <button (click)="onClearResults(driverSearchForm)" type="button" mat-raised-button>Clear</button>
</form>
<div *ngIf="showResults" class="results-container margin-left-lg margin-right-lg" fxLayout="row">
  <div class="filters">
    <div class="filters-title">Filter results</div>
    <div class="filter">
      <h4>Years operation</h4>
      <mat-slider max="10" min="1" step="1" color="primary"></mat-slider>
    </div>
    <div class="filter">
      <h4>Rating</h4>
      <mat-slider max="10" min="1" step="1"></mat-slider>
    </div>
    <div class="filter">
      <h4>Safety</h4>
      <mat-slider max="10" min="1" step="1"></mat-slider>
    </div>
    <div class="filter">
      <h4>Price</h4>
      <mat-slider max="10" min="1" step="1"></mat-slider>
    </div>
  </div>
  <!-- <div class="results" fxLayout="row wrap" >
    <app-driver-card
      *ngFor="let driver of driverList" [driver]="driver">
    </app-driver-card>
  </div> -->
  <!-- <div *ngIf="isQuoteSearch" class="results" fxLayout="column" >
    <app-quote-card
      *ngFor="let quote of quoteList"
      [quote]="quote">
    </app-quote-card>
  </div> -->
</div>
