export class Delivery {
  businessName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  driverAssistedDelivery: boolean;
  instructions: string;
  deliveryDateFrom: string;
  deliveryDateTo: string;
}
