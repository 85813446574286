<div class="table-container">
  <table mat-table [dataSource]="documentDataSource">

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">{{element.type}}</td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let element">{{formatCreatedDate(element.createdDate)}}</td>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef>Created by</th>
      <td mat-cell *matCellDef="let element">{{element.createdBy}}</td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let element">{{element.role}}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" fxLayoutGap="5px">
        <button mat-raised-button
                  *ngIf="element.status === 'uploaded'"
                  type="button"
                  [disabled]="loading"
                  (click)="onShipmentDocumentDownload(element.id)">Download</button>
        <div class="showPending margin-left-md" *ngIf="element.status === 'pending'">
          Pending
        </div>
      </td>
    </ng-container>

    <!--TODO: Postponed until proper logic can be determine for outcome of document deletion-->
        <!-- <button mat-raised-button
                type="button"
                [disabled]="loading"
                (click)="onShipmentDocumentDelete(element.id)">Delete</button> -->
       
    <tr mat-header-row *matHeaderRowDef="documentDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: documentDisplayedColumns;"></tr>
  </table>
  <div class="table-message">
    <mat-spinner class="margin-top-md" *ngIf="loading"></mat-spinner>
    <p *ngIf="(!documentDataSource || documentDataSource.length <= 0) && !loading" >No documents found.</p>
  </div>
</div>
<mat-card fxLayout>
  <form [formGroup]="documentForm" novalidate fxLayout fxLayoutGap="16px" class="content">
    <mat-form-field class="padding-right-sm">
      <mat-label>Document type</mat-label>
      <mat-select formControlName="documentType" required>
        <mat-option>--</mat-option>
        <mat-option *ngFor="let documentType of documentTypes" [value]="documentType.name">
            {{documentType.displayName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <input fxFlexAlign="center"
           mat-input
           type="file"
           accept="application/pdf,image/jpeg"
           [disabled]="!documentForm.dirty || !documentForm.valid"
           (change)="onFileChange($event)">
  </form>
</mat-card>
