<div class="mat-app-background basic-container">
  <button mat-button class="close-button" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
  <h1 mat-dialog-title cdkFocusInitial>{{data.title}}</h1>
  <div mat-dialog-content *ngIf="data.message;else showHtmlContent">
    {{data.message}}
  </div>
  <ng-template #showHtmlContent>
    <div mat-dialog-content [innerHtml]="htmlContent"></div>
  </ng-template>
</div>