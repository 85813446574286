import { ShipperStateModel } from './shipper-data-state';
import { Company } from '../../models/company';

export class SetShipper {
  static readonly type = '[ShipperData] SetShipper';
  constructor(public payload: ShipperStateModel) {}
}

export class SetShipperCompany {
  static readonly type = '[ShipperData] SetShipperCompany';
  constructor(public payload: Company) {}
}

export class ClearShipper {
  static readonly type = '[ShipperData] ClearShipper';
  constructor() {}
}
