import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { FleetoperateApiService } from '@fleetoperate/shared/data-access';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { tap, catchError } from 'rxjs/operators';
import { LoggerService } from '@fleetoperate/shared/util';
import { Response } from '@fleetoperate/shared/data-access';
import { Token } from '@fleetoperate/shared/authentication/data-access';
import { Router } from '@angular/router';

const PATH_LOGIN = '/auth/login';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  apiUrl: string;

  constructor(private readonly router: Router,
              private readonly api: FleetoperateApiService,
              private readonly authService: AuthService,
              private readonly logger: LoggerService,
              @Inject('apiUrl') apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  login(model: any): Observable<any> {
    return this.api.post(this.apiUrl + PATH_LOGIN, model).pipe(
      tap((response: Response) => {
        const token = response.data as Token;
        const validToken = this.authService.authenticate(token, model.username);
        validToken ? this.logger.log(`Token is valid`) : this.logger.log(`Token is invalid: ${token}`);
      }),
      tap(() => this.navigateToReturnUrl()),
      catchError(err => {
          this.logger.error('Caught authentication error, will logout and rethrow error.', err);
          this.authService.unauthenticate();
          return throwError(err);
      })
    );
  }

  navigateToReturnUrl(): void {
    const returnUrl = this.authService.returnUrl;
    this.authService.clearReturnUrl();

    returnUrl ? this.router.navigate([`${returnUrl}`]) : this.navigateToRoot();
  }

  private navigateToRoot(): void {
    this.router.navigate([`/`]);
  }
}
