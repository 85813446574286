import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionsComponent } from './actions/actions.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ActionsComponent],
  exports: [ActionsComponent]
})
export class SharedUiActionsModule {}
