<div fxFlex class="margin-top-sm">
  <h2>
    <mat-icon data-test="exit-button" *ngIf="backAction" (click)="backAction()" class="icon-back"
      >keyboard_backspace</mat-icon
    >
    <span class="margin-left-sm">{{ title }}</span>
  </h2>
</div>
<div fxLayoutAlign="end" *ngFor="let action of actions" class="margin-left-sm">
  <button
    fxFlexAlign="center"
    mat-raised-button
    class="btn-actions"
    [color]="action.color"
    [disabled]="action.disabled || false"
    (click)="action.clickHandler()"
    data-test="action-button"
  >
    {{ action.title }}
  </button>
</div>
