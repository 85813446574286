<div fxLayout fxLayoutAlign="space-evenly" class="force-flex">
  <div class="source margin-right-md">
    <h5>Source: <span>{{shipment.origin}}</span></h5>
  </div>
  <div class="destination">
    <h5>Destination: <span>{{shipment.destination}}</span></h5>
  </div>
</div>
<agm-map>
  <agm-direction [origin]="origin" [destination]="destination" [renderOptions]="renderOptions"
    [markerOptions]="markerOptions">
  </agm-direction>
</agm-map>
