import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SuccessfulUnsubscribeEmailComponent } from './sucessful-unsubscribe-email/successful-unsubscribe-email.component';
import { ConfirmEmailUnsubscribeComponent } from './confirm-email-unsubscribe/confirm-email-unsubscribe.component';
import { ROUTE_UNSUBSCRIBE_EMAIL, ROUTE_SUCCESSFUL_UNSUBSCRIBE_EMAIL, ROUTE_FAILED_UNSUBSCRIBE_EMAIL} from './shared/routes';
import { FailureUnsubscribeEmailComponent } from './failure-unsubscribe-email/failure-unsubscribe-email.component';

export const routes: Routes = [
    { path: ROUTE_SUCCESSFUL_UNSUBSCRIBE_EMAIL, component: SuccessfulUnsubscribeEmailComponent },
    { path: ROUTE_FAILED_UNSUBSCRIBE_EMAIL, component: FailureUnsubscribeEmailComponent},
    { path: ROUTE_UNSUBSCRIBE_EMAIL, component: ConfirmEmailUnsubscribeComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedUnsubscribeEmailFeatureUnsubscribeEmailRoutingModule { }
