<div fxLayout="column" fxFlex>
  <progress-bar [count]="numSteps" [activeIndicator]="step" class="padding-bottom-md"></progress-bar>
  <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex>
    <span class="mat-subheading-1">Step {{ step }}</span>
    <span class="mat-headline padding-bottom">All done! Verify your email address</span>
    <span class="mat-subheading-2">We have sent a 4 digit code to your email {{ email$ | async }}</span>
    <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
    <form [formGroup]="form" #f="ngForm" (ngSubmit)="onNavigateToNextStep()" fxLayout="column" class="padding-top-md">
      <mat-form-field>
        <input matInput placeholder="Enter a 4 digit number" formControlName="verificationCode" />
      </mat-form-field>
      <div class="actions full-width padding-top-xlg">
        <button
          class="margin-bottom"
          [ngClass.lt-md]="'full-width'"
          [ngClass.gt-sm]="'half-width'"
          mat-button
          type="button"
          (click)="onNoCodeReceived()"
        >
          Didn't receive the code?
        </button>
      </div>
      <div class="actions full-width padding-top-xlg">
        <button
          class="margin-bottom"
          [ngClass.lt-md]="'full-width'"
          [ngClass.gt-sm]="'half-width'"
          mat-raised-button
          color="primary"
          type="button"
          (click)="onNavigateToNextStep()"
          [disabled]="form.pristine || !form.valid"
        >
          Validate Email
        </button>
      </div>
    </form>
  </div>
</div>
