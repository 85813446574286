import { Component, OnInit, OnChanges, AfterViewInit, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { CompanyModel } from '../company/company-model';
import { RegistrationService } from '../services/registration.service';
import { ErrorDataService, Error } from '@fleetoperate/shared/data-access-error';
import { DateTimeService, GoogleAddressAutocompleteService } from '@fleetoperate/shared/util';
import { Response } from '@fleetoperate/shared/data-access';
import { ActivatedRoute } from '@angular/router';

const InvalidPhoneMessage = 'Please enter using a ###-###-#### format';
const RequiredMessage = 'Required';
const validAddressMessage = 'Please enter a full valid address';
const ACTION_SIGN_UP = 'Sign up';

class AddressModel {
  fullAddress: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}

@Component({
  selector: 'app-company-address',
  templateUrl: './company-address.component.html',
  styleUrls: ['./company-address.component.scss']
})
export class CompanyAddressComponent implements OnInit, OnChanges, AfterViewInit {
  form: FormGroup;
  errorMessage: string;
  step: number;
  numSteps: number;
  backRoute: string;
  forwardRoute: string;

  @ViewChild('addressText', { static: false }) addressText: any;

  companyAddress: AddressModel;

  constructor(
    private readonly route: ActivatedRoute,
    private fb: FormBuilder,
    private readonly googleAddressAutocompleteService: GoogleAddressAutocompleteService,
    private registrationService: RegistrationService,
    private readonly errorDataService: ErrorDataService,
    private readonly dateTimeService: DateTimeService,
    private readonly zone: NgZone
  ) {
    this.errorMessage = undefined;
    this.companyAddress = new AddressModel();
    this.form = this.createForm();
  }
  ngAfterViewInit(): void {
    this.googleAddressAutocompleteService.getPlaceAutocomplete(this.form, this.addressText, "address");
  }

  ngOnInit() {
    this.step = this.route.snapshot.data.step;
    this.numSteps = this.route.snapshot.data.numSteps;
    this.backRoute = this.route.snapshot.data.backRoute;
    this.forwardRoute = this.route.snapshot.data.forwardRoute;
    const formModel = this.getFormModel();
    this.populateForm(formModel);
  }

  ngOnChanges() {
    this.rebuildForm();
  }

  getAddressMessage(): string {
    const errors = this.address.errors;
    return errors.required ? RequiredMessage : errors.pattern ? validAddressMessage : '';
  }

  getPhoneMessage(): string {
    const errors = this.phone.errors;
    return errors.required ? RequiredMessage : errors.pattern ? InvalidPhoneMessage : '';
  }

  onNavigateToNextStep() {
    if (!this.form.valid) {
      return;
    }

    const model = this.prepareSaveModel();
    this.registrationService
      .saveCompanyAddress(model)
      .pipe(
        catchError((error: any) => {
          this.errorMessage = `${error.message}. Please try again or contact us for help.`;

          let errorInformation = {
            affectedUser: model.email,
            attemptedAction: ACTION_SIGN_UP,
            capturedTime: this.dateTimeService.getUTCDateFormatted(),
            error: error
          } as Error;
          return this.errorDataService.createError(errorInformation);
        })
      )
      .subscribe((response: Response) => {
        this.registrationService.navigateToNextStep(this.forwardRoute);
      });
  }

  onNavigateToLogin(): void {
    this.registrationService.navigateToSignIn();
  }

  onNavigateToPreviousStep(): void {
    this.registrationService.navigateToPreviousStep(this.backRoute);
  }

  get address() {
    return this.form.get('address');
  }
  get city() {
    return this.form.get('city');
  }
  get phone() {
    return this.form.get('phone');
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      address: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*,[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*, [A-Z]{2} [A-Z0-9 ]*, [A-Za-z]+$'
          )
        ]
      ],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')]]
    });

    return form;
  }

  private populateForm(model: CompanyModel): void {
    this.form.setValue({
      address: model.address,
      phone: model.phoneNumber
    });
    this.form.markAsDirty();
  }

  private rebuildForm() {
    const model = this.getFormModel();
    this.form.reset({
      address: this.companyAddress.fullAddress,
      phone: this.formatPhoneNumberForDisplay(model.phoneNumber)
    });
  }

  private getFormModel(): CompanyModel {
    return this.registrationService.getCompany();
  }

  private formatPhoneNumberForDisplay(phoneNumber: string): string {
    return phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 10);
  }

  private prepareSaveModel(): CompanyModel {
    const formModel = this.form.value;
    const numbersOnlyPhoneNumber = formModel.phone.replace(/-/g, '');

    this.companyAddress = this.googleAddressAutocompleteService.parseAddress(this.address.value);

    const saveModel = {
      address: this.companyAddress.streetAddress,
      city: this.companyAddress.city,
      state: this.companyAddress.state,
      zipCode: this.companyAddress.zipcode,
      country: this.companyAddress.country.substring(0, 2).toLowerCase().trim(),
      phoneNumber: numbersOnlyPhoneNumber as string
    };
    return saveModel as CompanyModel;
  }

}
