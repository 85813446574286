import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatProgressBarModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressBarModule
  ],
  exports: [
    MatButtonModule,
    MatProgressBarModule
  ]
})
export class UiKitMaterialModule {}
