import { Injectable, Inject } from '@angular/core';

@Injectable()
export class LoggerService {
  constructor(@Inject('loggingEnabled') private readonly loggingEnabled: boolean) {}

  log(msg: string) {
    if (this.loggingEnabled) {
      console.log(msg);
    }
  }

  error(msg: string, error?: any) {
    if (this.loggingEnabled) {
      console.error(msg, error);
    }
  }
}
