import { Component, OnInit, ViewChild, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_SHIPMENT_SEARCH } from '../../shared/routes';
import {
  ShipperDataService,
  Shipment,
  ShipmentDataService,
  SHIPMENT_LOOKUPS_FITSIN
} from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import {
  FormGroup,
  Validators,
  FormBuilder,
  NgForm,
  FormGroupDirective,
  FormControl,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';
import * as moment from 'moment-timezone';
import { Lookup } from 'libs/shared/delivery-shipper/data-access-shipper/src/lib/models/lookup';
import { Rate } from 'libs/shared/delivery-shipper/data-access-shipper/src/lib/models/rate';
import { first, debounceTime, catchError, finalize, tap } from 'rxjs/operators';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInputEvent, ErrorStateMatcher } from '@angular/material';
import { User } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { ROUTE_SHIPPER_PROFILE } from '@fleetoperate/shipper/feature-profile';
import { TabActionLink } from '@fleetoperate/shared/ui/header';
import { OverlayModalRef } from '@fleetoperate/shared/ui/overlay';
import { ToastService, DateTimeService, GoogleAddressAutocompleteService } from '@fleetoperate/shared/util';
import { COMPONENT_DATA_PASSED } from 'libs/shared/ui/overlay/src/lib/overlay/overlay.tokens';
import { SHIPMENT_STATUS_DRAFT } from '@fleetoperate/shipper/feature-shipment';
import { throwError } from 'rxjs';

const RequiredFieldMessage = 'Required';
const validCharactersMessage = 'Only letters, numbers, spaces, and hyphens are acceptable';
const NumbersOnlyMessage = 'Must use numbers only';
const PositiveNumberMessage = 'Must be greater or equal to zero';
const ERROR_MESSAGE = `There was an error. Please try again or contact us for help.`;
const ERROR_MESSAGE_INVALID_USER = `Invalid user. Please try to sign in again.`;
const MaximumLengthFieldMessage = (value: number) => `Must be less than ${value} characters`;
const validAddressMessage = 'Please enter a full valid address';
const MaximumNumberMessage = `Time must be less than 23:59`;
const ValidTimeFormatMessage = 'Must be 00:00 format';
const maxTime = 2359;
const ValidPickupTimeMessage = 'Pickup at least 30 minutes from now.';
const ValidDeliveryTimeMessage = 'The delivery time must be after pickup time.';
const ValidContactPhoneFormat = 'Must be +1 ###-###-####';
const debounceMs = 500;

class AddressModel {
  fullAddress: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}

interface RateModel {
  distanceUnits: string;
  distance: string;
  rate: string;
}
@Component({
  selector: 'fleetoperate-create-load',
  templateUrl: './create-load.component.html',
  styleUrls: ['./create-load.component.scss']
})
export class CreateLoadComponent implements OnInit, AfterViewInit {
  @ViewChild('fromDeliveryDate', {
    read: MatInput,
    static: false
  })
  @ViewChild('originText', {
    static: true
  })
  focusCtrl: ElementRef;

  fromDeliveryDate: MatInput;

  shipperProfilePath = ROUTE_SHIPPER_PROFILE;
  originAddress: AddressModel;
  destinationAddress: AddressModel;
  minDatePickup: Date;
  defaultPickupTime: string;
  defaultDeliveryTime: string;
  defaultPickupDateTime: string;
  defaultDeliveryDateTime: string;
  minDateDelivery: Date;
  createLoadForm: FormGroup;
  message: string;
  loading: boolean;
  fitsInTypes: Lookup[];
  rate: Rate | RateModel;
  actions: Array<TabActionLink> = [];
  title: string;
  backAction: Function;
  shipment: Shipment;
  pickupTimeMatcher: ErrorStateMatcher;
  deliveryTimeMatcher: ErrorStateMatcher;

  user: User;
  @ViewChild('originText', { static: false }) originText: any;
  @ViewChild('destinationText', { static: false }) destinationText: any;
  @ViewChild('createLoadFormRef', { static: false }) createLoadFormRef: FormGroupDirective;

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly googleAddressAutocompleteService: GoogleAddressAutocompleteService,
    private readonly shipperDataService: ShipperDataService,
    private readonly shipmentDataService: ShipmentDataService,
    private readonly overlayRef: OverlayModalRef,
    private readonly dateTimeService: DateTimeService,
    private readonly toastService: ToastService,
    @Inject(COMPONENT_DATA_PASSED) private readonly dataPassed: any
  ) {
    this.setDefaultDateTime();
    this.createLoadForm = this.createForm();
    this.loading = false;
    this.title = 'Create a new load';
    this.backAction = () => this.overlayRef.close();
    this.rate = { distanceUnits: '-', distance: '', rate: '-' } as RateModel;
    this.originAddress = undefined;
    this.destinationAddress = undefined;
    this.actions = this.createActions(this.shipment);
    this.pickupTimeMatcher = new PickupTimeErrorStateMatcher();
    this.deliveryTimeMatcher = new DeliveryTimeErrorStateMatcher();
  }

  ngOnInit(): void {
    this.focusCtrl.nativeElement.focus();
    const shipper = this.shipperDataService.getShipper();
    this.minDatePickup = this.dateTimeService.getCurrentDate();
    this.minDateDelivery = this.dateTimeService.getCurrentDate();
    this.user = {
      name: `${shipper.firstName} ${shipper.lastName}`
    };
    if (!shipper || !shipper.id || !shipper.companyID) {
      this.message = ERROR_MESSAGE_INVALID_USER;
    }

    if (this.dataPassed && this.dataPassed.shipmentId) {
      this.loadShipment(this.dataPassed.shipmentId);
    }

    this.shipmentDataService
      .getShipmentLookups([SHIPMENT_LOOKUPS_FITSIN])
      .pipe(first())
      .subscribe(
        (lookups: Lookup[]) => {
          this.fitsInTypes = lookups;
        },
        error => (this.message = ERROR_MESSAGE)
      );

    this.createLoadForm.valueChanges.subscribe(() => {
      this.actions = this.createActions(this.shipment);
    });

    this.origin.valueChanges.pipe(debounceTime(debounceMs)).subscribe(() => {
      this.determineDeliveryRate();
    });

    this.destination.valueChanges.pipe(debounceTime(debounceMs)).subscribe(() => {
      this.determineDeliveryRate();
    });

    this.pickupDate.valueChanges.pipe(debounceTime(debounceMs)).subscribe(() => {
      this.determineDeliveryRate();
    });

    this.pickupTime.valueChanges.pipe(debounceTime(debounceMs)).subscribe(() => {
      this.determineDeliveryRate();
    });
  }

  ngAfterViewInit(): void {
    this.googleAddressAutocompleteService.getPlaceAutocomplete(this.createLoadForm, this.originText, 'origin');
    this.googleAddressAutocompleteService.getPlaceAutocomplete(
      this.createLoadForm,
      this.destinationText,
      'destination'
    );
  }

  onMyShipments(): void {
    this.router.navigate([ROUTE_SHIPMENT_SEARCH]);
  }

  onLogout(): void {
    this.shipperDataService.logout();
  }

  onSubmit(createLoadFormRef: NgForm): void {
    if (!this.createLoadForm.valid) {
      return;
    }

    this.loading = true;
    this.message = undefined;
    this.actions = this.createActions(this.shipment);

    if (!this.isPickupDateAllowed()) {
      this.message = ValidPickupTimeMessage;
      this.loading = false;
      return;
    }

    if (!this.isDeliveryDateAllowed()) {
      this.message = ValidDeliveryTimeMessage;
      this.loading = false;
      return;
    }

    if (this.shipment) {
      const newShipment = this.prepareSaveModel(this.shipment);

      this.shipmentDataService
        .updateShipment(newShipment)
        .pipe(first())
        .subscribe(
          (shipment: Shipment) => {
            this.toastService.showSuccess('Successfully updated the load');
            this.message = undefined;
            this.shipment = shipment;
            this.loading = false;
            this.resetForm(this.shipment, undefined);
            this.setTitle(this.shipment);
            this.actions = this.createActions(this.shipment);
          },
          (error: any) => {
            this.message = error.message || ERROR_MESSAGE;
            this.loading = false;
          }
        );
    } else {
      const newShipment = this.prepareSaveModel();
      newShipment.companyID = this.shipperDataService.getShipper().companyID;

      this.shipmentDataService
        .createShipment(newShipment)
        .pipe(
          first(),
          tap((shipment: Shipment) => {
            this.message = undefined;
            this.shipment = shipment;
            this.resetForm(this.shipment, createLoadFormRef);
            this.toastService.showSuccess('Successfully created a load');
          }),
          catchError((error: any) => {
            this.message = error.message || ERROR_MESSAGE;
            return throwError(this.message);
          }),
          finalize(() => {
            this.loading = false;
            this.setTitle(this.shipment);
            this.actions = this.createActions(this.shipment);
          })
        )
        .subscribe();
    }
  }

  get origin() {
    return this.createLoadForm.get('origin');
  }
  get destination() {
    return this.createLoadForm.get('destination');
  }

  get fitsIn() {
    return this.createLoadForm.get('fitsIn');
  }
  get pickupDate() {
    return this.createLoadForm.get('pickupDate');
  }
  get pickupTime() {
    return this.createLoadForm.get('pickupTime');
  }
  get deliveryDate() {
    return this.createLoadForm.get('deliveryDate');
  }
  get deliveryTime() {
    return this.createLoadForm.get('deliveryTime');
  }
  get commodity() {
    return this.createLoadForm.get('commodity');
  }
  get orderNumber() {
    return this.createLoadForm.get('orderNumber');
  }
  get weightInKgs() {
    return this.createLoadForm.get('weightInKgs');
  }
  get comments() {
    return this.createLoadForm.get('comments');
  }
  get customerName() {
    return this.createLoadForm.get('customerName');
  }
  get customerPhone() {
    return this.createLoadForm.get('customerPhone');
  }

  getOriginErrorMessage(): string {
    const errors = this.origin.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? validAddressMessage : '';
  }

  getDestinationErrorMessage(): string {
    const errors = this.destination.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? validAddressMessage : '';
  }

  getFitsInErrorMessage(): string {
    const errors = this.fitsIn.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getPickupDateErrorMessage(): string {
    const errors = this.pickupDate.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getPickupTimeErrorMessage(): string {
    const errors = this.pickupTime.errors;
    return errors && errors.required
      ? RequiredFieldMessage
      : errors && errors.minlength
      ? ValidTimeFormatMessage
      : this.createLoadForm && this.createLoadForm.errors && this.createLoadForm.errors.invalidMaxPickup
      ? MaximumNumberMessage
      : '';
  }

  getDeliveryDateErrorMessage(): string {
    const errors = this.deliveryDate.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getDeliveryTimeErrorMessage(): string {
    const errors = this.deliveryTime.errors;
    return errors && errors.required
      ? RequiredFieldMessage
      : errors && errors.minlength
      ? ValidTimeFormatMessage
      : this.createLoadForm && this.createLoadForm.errors && this.createLoadForm.errors.invalidMaxDelivery
      ? MaximumNumberMessage
      : '';
  }

  getCustomerNameErrorMessage(): string {
    const errors = this.customerName.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getCustomerPhoneErrorMessage(): string {
    const errors = this.customerPhone.errors;
    return errors.required ? RequiredFieldMessage : errors.minlength ? ValidContactPhoneFormat : '';
  }

  getCommodityErrorMessage(): string {
    const errors = this.commodity.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getOrderNumberErrorMessage(): string {
    const errors = this.orderNumber.errors;
    return errors.pattern
      ? validCharactersMessage
      : errors.maxlength
      ? MaximumLengthFieldMessage(errors.maxlength.requiredLength)
      : '';
  }

  getWeightInKgsErrorMessage(): string {
    const errors = this.weightInKgs.errors;
    return errors.required
      ? RequiredFieldMessage
      : errors.min
      ? PositiveNumberMessage
      : errors.pattern
      ? NumbersOnlyMessage
      : '';
  }

  getCommentsErrorMessage(): string {
    const errors = this.comments.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  onPickupDateChange(event: MatDatepickerInputEvent<Date>) {
    this.minDateDelivery = event.value;
  }

  private setDefaultDateTime() {
    const moment = this.dateTimeService.getCurrentMoment();
    this.defaultPickupTime = moment.add(3, 'hours').format('HH:00');
    this.defaultPickupDateTime = this.dateTimeService.getISOString(moment);
    this.defaultDeliveryTime = moment.add(1, 'hours').format('HH:00');
    this.defaultDeliveryDateTime = this.dateTimeService.getISOString(moment);
  }

  private loadShipment(shipmentID: string) {
    this.shipmentDataService
      .getShipment(shipmentID)
      .pipe(first())
      .subscribe((shipment: Shipment) => {
        this.shipment = shipment;
        this.setTitle(this.shipment);
        this.actions = this.createActions(this.shipment);
        this.resetForm(this.shipment, undefined);
      });
  }

  private resetForm(shipment: Shipment, createFormRef: NgForm): void {
    if (createFormRef) {
      createFormRef.resetForm();
    }
    const formModel = {
      origin: shipment && shipment.origin ? shipment.origin : '',
      destination: shipment && shipment.destination ? shipment.destination : '',
      distance: shipment && shipment.distance ? shipment.distance : '',
      fitsIn: shipment && shipment.fitsIn ? shipment.fitsIn : '',
      pickupDate: shipment && shipment.pickup.pickupDateFrom ? shipment.pickup.pickupDateFrom : '',
      deliveryDate: shipment && shipment.delivery.deliveryDateFrom ? shipment.delivery.deliveryDateFrom : '',
      pickupTime:
        shipment && shipment.pickup.pickupDateFrom
          ? this.dateTimeService.getTime(shipment.pickup.pickupDateFrom).replace(/^(.{2})/, '$1:')
          : '',
      deliveryTime:
        shipment && shipment.delivery.deliveryDateFrom
          ? this.dateTimeService.getTime(shipment.delivery.deliveryDateFrom).replace(/^(.{2})/, '$1:')
          : '',
      customerName: shipment && shipment.delivery.businessName ? shipment.delivery.businessName : '',
      customerPhone:
        shipment && shipment.delivery.contactPhone ? this.getFormContactPhone(shipment.delivery.contactPhone) : '',
      commodity: shipment && shipment.commodity ? shipment.commodity : '',
      orderNumber: shipment && shipment.shipmentReferenceNumber ? shipment.shipmentReferenceNumber : '',
      weightInKgs: shipment && shipment.weight ? shipment.weight : '',
      offerRate: shipment && shipment.offerRate ? shipment.offerRate : '',
      comments: shipment && shipment.comment ? shipment.comment : ''
    };
    this.createLoadForm.reset(formModel);
  }

  private getFormContactPhone(phone: string): string {
    const contactPhone = phone.replace(/^1/, '');
    const contactPhoneMatch = contactPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
    return contactPhoneMatch[1] + '-' + contactPhoneMatch[2] + '-' + contactPhoneMatch[3];
  }

  private determineDeliveryRate(): void {
    this.checkOriginDestination(
      this.origin.value,
      this.destination.value,
      this.dateTimeService.convertDateTimeStringFromUTCDateTimeString(
        this.dateTimeService.addDateTime(this.pickupDate.value, this.pickupTime.value)
      )
    );
  }

  private setTitle(shipment: Shipment) {
    this.title = shipment ? 'Edit load' : 'Create a New Load';
  }

  private createActions(shipment?: Shipment): TabActionLink[] {
    const actions: TabActionLink[] = [];

    if (shipment && shipment.status && SHIPMENT_STATUS_DRAFT === shipment.status) {
      actions.push({
        title: 'APPROVE',
        disabled: this.createLoadForm.dirty || this.loading,
        clickHandler: () => this.onApproveLoad()
      } as TabActionLink);
    }

    if ((shipment && shipment.status && SHIPMENT_STATUS_DRAFT === shipment.status) || !shipment) {
      actions.push({
        title: 'SAVE',
        disabled: !this.createLoadForm.dirty || !this.createLoadForm.valid || this.loading,
        clickHandler: () => this.createLoadFormRef.ngSubmit.emit()
      } as TabActionLink);
    }

    return actions;
  }

  private onApproveLoad(): void {
    this.loading = true;
    this.message = undefined;
    this.actions = this.createActions(this.shipment);
    this.shipmentDataService
      .approveLoadShipment(this.shipment)
      .pipe(first())
      .subscribe(
        (shipment: Shipment) => {
          this.shipment = shipment;
          this.loading = false;
          this.message = undefined;
          this.actions = this.createActions(this.shipment);
          this.toastService.showSuccess('Successfully approved shipment.');
          this.overlayRef.close();
          window.location.reload();
        },
        (error: any) => {
          this.message = error.message;
          this.loading = false;
          this.actions = this.createActions(this.shipment);
        }
      );
  }

  private getOriginAddress(): string {
    const shipper = this.shipperDataService.getShipper();
    const addressString = `${shipper.address}, ${shipper.city}, ${shipper.state} ${shipper.zipCode}`;
    if (shipper.country === 'us') {
      return `${addressString}, USA`;
    } else {
      return `${addressString}, Canada`;
    }
  }

  private createForm(): FormGroup {
    const originAddress = this.getOriginAddress();
    const form = this.fb.group(
      {
        origin: [
          originAddress,
          [
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*,[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*, [A-Z]{2} [A-Z0-9 ]*, [A-Za-z]+$'
            )
          ]
        ],
        destination: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*,[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*, [A-Z]{2} [A-Z0-9 ]*, [A-Za-z]+$'
            )
          ]
        ],
        distance: [undefined, [Validators.pattern('^\\d+(\\.\\d{1,2})?$'), Validators.min(0)]],
        fitsIn: ['', Validators.required],
        pickupDate: [this.defaultPickupDateTime, Validators.required],
        deliveryDate: [this.defaultDeliveryDateTime, Validators.required],
        pickupTime: [this.defaultPickupTime, [Validators.required, Validators.minLength(5)]],
        deliveryTime: [this.defaultDeliveryTime, [Validators.required, Validators.minLength(5)]],
        customerName: ['', Validators.required],
        customerPhone: ['', [Validators.required, Validators.minLength(12)]],
        commodity: ['', Validators.required],
        orderNumber: ['', [Validators.maxLength(50), Validators.pattern('[a-zA-Z0-9 -]*')]],
        weightInKgs: ['', [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$'), Validators.min(0)]],
        offerRate: [''],
        comments: ['']
      },
      { validator: [this.checkPickupTime(), this.checkDeliveryTime()] }
    );
    return form;
  }

  checkPickupTime(): ValidatorFn {
    return (form: FormGroup): ValidationErrors => {
      if (form.get('pickupTime').value === null) {
        return;
      } else {
        const pickupTime = Number(form.get('pickupTime').value.replace(':', ''));
        const condition = form.get('pickupTime').value && pickupTime > maxTime;
        return condition ? { invalidMaxPickup: true } : null;
      }
    };
  }

  checkDeliveryTime(): ValidatorFn {
    return (form: FormGroup): ValidationErrors => {
      if (form.get('deliveryTime').value === null) {
        return;
      } else {
        const deliveryTime = Number(form.get('deliveryTime').value.replace(':', ''));
        const condition = form.get('deliveryTime').value && deliveryTime > maxTime;
        return condition ? { invalidMaxDelivery: true } : null;
      }
    };
  }

  private prepareSaveModel(shipment?: Shipment): Shipment {
    const formModel = this.createLoadForm.value;
    const pickupMomentDate = moment(formModel.pickupDate).format('YYYY-MM-DDT');
    const pickupMomentTime = moment(formModel.pickupTime, 'HHmm').format('HH:mm:ss');
    const formPickupDateTime = `${pickupMomentDate}${pickupMomentTime}`;
    const pickupDateUTC = moment
      .tz(formPickupDateTime, 'America/New_York')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss[Z]');
    const deliveryMomentDate = moment(formModel.deliveryDate).format('YYYY-MM-DDT');
    const deliveryMomentTime = moment(formModel.deliveryTime, 'HHmm').format('HH:mm:ss');
    const formDeliveryDateTime = `${deliveryMomentDate}${deliveryMomentTime}`;
    const deliveryDateUTC = moment
      .tz(formDeliveryDateTime, 'America/New_York')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss[Z]');

    const phone = formModel.customerPhone;
    const modifiedPhoneNumber = `1${phone.replace('-', '').replace('-', '')}`;
    const saveModel = shipment ? Object.assign({}, shipment) : ({ pickup: {}, delivery: {} } as Shipment);

    saveModel.origin = formModel.origin as string;
    saveModel.destination = formModel.destination as string;
    saveModel.distance = this.rate.distance as number;
    saveModel.fitsIn = formModel.fitsIn as string;
    saveModel.commodity = formModel.commodity as string;
    saveModel.shipmentReferenceNumber = (formModel.orderNumber as string) || undefined;
    saveModel.pickup.pickupDateFrom = pickupDateUTC;
    saveModel.delivery.deliveryDateFrom = deliveryDateUTC;
    saveModel.delivery.businessName = formModel.customerName;
    saveModel.delivery.contactPhone = modifiedPhoneNumber;
    saveModel.offerRate = this.rate.rate === '-' ? undefined : Number(this.rate.rate);
    saveModel.weight = Number(formModel.weightInKgs);
    saveModel.comment = (formModel.comments as string) || undefined;

    this.originAddress = this.googleAddressAutocompleteService.parseAddress(formModel.origin);
    this.destinationAddress = this.googleAddressAutocompleteService.parseAddress(formModel.destination);

    saveModel.pickup.streetAddress = this.originAddress.streetAddress;
    saveModel.pickup.city = this.originAddress.city;
    saveModel.pickup.state = this.originAddress.state;
    saveModel.pickup.zipcode = this.originAddress.zipcode;
    saveModel.pickup.country = this.originAddress.country;

    saveModel.delivery.streetAddress = this.destinationAddress.streetAddress;
    saveModel.delivery.city = this.destinationAddress.city;
    saveModel.delivery.state = this.destinationAddress.state;
    saveModel.delivery.zipcode = this.destinationAddress.zipcode;
    saveModel.delivery.country = this.destinationAddress.country;

    return saveModel;
  }

  private getDistanceRate(origin: string, destination: string, pickup: string) {
    this.shipmentDataService
      .getShipmentRate(origin, destination, pickup, '')
      .pipe(first())
      .subscribe(
        (rate: Rate) => {
          this.rate = rate;
        },
        error => (this.message = ERROR_MESSAGE)
      );
  }

  private isPickupDateAllowed(): boolean {
    const date = this.dateTimeService.getCurrentDate();
    const pickupDate = this.pickupDate.value;
    const pickupTime = this.pickupTime.value;
    const pickupDateTime = this.dateTimeService.combineDateTime(pickupDate, pickupTime);
    const minPickupDateTime = moment(date).add(30, 'minutes');
    return moment(pickupDateTime).isSameOrAfter(minPickupDateTime, 'minute');
  }

  private isDeliveryDateAllowed(): boolean {
    const pickupTime = this.pickupTime.value;
    const deliveryTime = this.deliveryTime.value;
    const pickupDate = this.pickupDate.value;
    const deliveryDate = this.deliveryDate.value;
    const pickupDateTime = this.dateTimeService.combineDateTime(pickupDate, pickupTime);
    const deliveryDateTime = this.dateTimeService.combineDateTime(deliveryDate, deliveryTime);
    return moment(pickupDateTime).isSameOrBefore(deliveryDateTime, 'minute');
  }

  private checkOriginDestination(origin: string, destination: string, pickup: string) {
    if (origin && destination && pickup) {
      this.getDistanceRate(origin, destination, pickup);
    }
  }
}
export class PickupTimeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.errors) || (form && form.errors && form.errors.invalidMaxPickup);
  }
}

export class DeliveryTimeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.errors) || (form && form.errors && form.errors.invalidMaxDelivery);
  }
}
