import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { ROUTE_SIGNIN } from '../shared/routes';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isAuthenticated().pipe(
      map((authenticated: boolean) => {
        if (authenticated) {
          return true;
        }

        // navigate to login page
        this.authService.returnUrl = state.url;
        this.router.navigate([ROUTE_SIGNIN]);
        return false;
      }),
      catchError((error: any) => {
        // navigate to login page
        this.authService.returnUrl = state.url;
        return this.router.navigate([ROUTE_SIGNIN]);
      })
    );
  }
}
