<div fxLayout fxLayoutAlign="space-between" class="padding-top-md padding-right-md padding-left-md">
  <div>{{ actorName }}</div>
  <div>{{ primaryOfferRate }}</div>
</div>
<div fxLayoutAlign="end" class="secondaryOfferRate padding-top-sm padding-right-md padding-bottom-sm">
  {{ secondaryOfferRate }}
</div>
<span class="message padding-bottom-sm">
  {{ message }}
</span>
<fleetoperate-actions [actions]="actions"></fleetoperate-actions>
