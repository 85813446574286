import { Component, OnInit, Input } from '@angular/core';
import {
  Shipment,
  Quote,
  SHIPMENT_STATUS_CONFIRMATION_PENDING,
  Bid
} from '@fleetoperate/shared/shipper/data-access-shipper';
import { CurrencyFormatService, DateTimeService } from '@fleetoperate/shared/util';
import {
  SHIPMENT_STATUS_DRAFT,
  SHIPMENT_STATUS_UNSECURED,
  SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING,
  SHIPMENT_STATUS_BOOKED,
  SHIPMENT_STATUS_BILL_OF_LADING_AVAILABLE,
  SHIPMENT_STATUS_OUT_FOR_DELIVERY,
  SHIPMENT_STATUS_DELIVERED,
  SHIPMENT_STATUS_COMPLETED,
  SHIPMENT_STATUS_INACTIVE,
  SHIPMENT_STATUS_CANCELLED
} from '@fleetoperate/shipper/feature-shipment';
import { QUOTE_STATUS_CONFIRMED } from 'libs/shipper/feature-shipment/src/lib/shared/quote.status';

const NoActorName = 'No driver/carrier';

interface ShipmentModel extends Shipment {
  color: string;
  actorName: string;
  formattedPickupDateAndTime: string;
  formattedDeliveryDateAndTime: string;
  formattedOfferRate: string;
}
@Component({
  selector: 'fleetoperate-shipment-summary',
  templateUrl: './shipment-summary.component.html',
  styleUrls: ['./shipment-summary.component.scss']
})
export class ShipmentSummaryComponent implements OnInit {
  @Input() shipment: ShipmentModel = {} as ShipmentModel;
  @Input() refreshAction: Function;

  displayedColumns: string[] = [];
  columns = [
    'shipmentId',
    'mode',
    'shipmentReference',
    'price',
    'carrier/driver',
    'origin',
    'pickup',
    'destination',
    'delivery',
    'status',
    'refresh'
  ];

  constructor(
    private readonly currencyFormatService: CurrencyFormatService,
    private readonly dateTimeService: DateTimeService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.shipment && this.shipment.pickup && this.shipment.delivery) {
      this.displayedColumns = this.determineColumnsToShow(this.shipment, this.columns);
      this.shipment.color = this.determineColor(this.shipment.status);
      this.shipment.formattedPickupDateAndTime = this.dateTimeService.getConvertedDateAndTime(
        this.shipment.pickup.pickupDateFrom,
        this.shipment.pickup.pickupDateTo
      );
      this.shipment.formattedDeliveryDateAndTime = this.dateTimeService.getConvertedDateAndTime(
        this.shipment.delivery.deliveryDateFrom,
        this.shipment.delivery.deliveryDateTo
      );
      this.shipment.formattedOfferRate = this.formatCurrency(
        this.shipment.confirmedOfferRate || this.shipment.offerRate
      );

      if (this.shipment.quotes) {
        const acceptedQuote = this.getAcceptedQuote();
        this.shipment.actorName = acceptedQuote ? acceptedQuote.actorName : NoActorName;
      } else {
        this.shipment.actorName = NoActorName;
      }
    }
  }

  private determineColumnsNotToDisplay(shipment: Shipment): string[] {
    let columnsNotToDisplay: string[] = [];

    if (shipment.status === SHIPMENT_STATUS_DRAFT) {
      columnsNotToDisplay = ['price', 'carrier/driver', 'refresh'];
    } else if (
      shipment.status === SHIPMENT_STATUS_CONFIRMATION_PENDING ||
      shipment.status === SHIPMENT_STATUS_UNSECURED ||
      shipment.status === SHIPMENT_STATUS_INACTIVE
    ) {
      columnsNotToDisplay = ['carrier/driver'];
    }

    if (!shipment.shipmentReferenceNumber) {
      columnsNotToDisplay.push('shipmentReference');
    }
    return columnsNotToDisplay;
  }

  private determineColumnsToShow(shipment: Shipment, columns: string[]): string[] {
    let displayedColumns: string[] = [];
    const columnsNotToDisplay = this.determineColumnsNotToDisplay(shipment);

    if (columnsNotToDisplay.length > 0) {
      displayedColumns = columns.filter((columnID: any) => {
        const cond = !columnsNotToDisplay.includes(columnID);
        return cond ? columnID : undefined;
      });
    } else {
      displayedColumns = columns;
    }
    return displayedColumns;
  }

  private formatCurrency(offerRate: number): string {
    return this.currencyFormatService.convertToCurrency(offerRate);
  }

  get origin(): string {
    if (this.shipment && this.shipment.pickup && this.shipment.pickup.city && this.shipment.pickup.state) {
      return `${this.shipment.pickup.city}, ${this.shipment.pickup.state}`;
    } else {
      return this.shipment.origin;
    }
  }

  get destination(): string {
    if (this.shipment && this.shipment.delivery && this.shipment.delivery.city && this.shipment.delivery.state) {
      return `${this.shipment.delivery.city}, ${this.shipment.delivery.state}`;
    } else {
      return this.shipment.destination;
    }
  }

  private getAcceptedQuote(): Quote {
    const neededQuote = this.shipment.quotes.filter((quote: Quote) => {
      if (!quote) return;
      const neededBid = quote.bids.filter((bid: Bid) => {
        if (!bid) return;
        return bid.event === QUOTE_STATUS_CONFIRMED;
      });
      if (neededBid.length > 0) {
        return neededBid;
      }
    });
    return neededQuote && neededQuote.length > 0 ? neededQuote[0] : undefined;
  }

  private determineColor(status: string): string {
    switch (status) {
      case SHIPMENT_STATUS_DRAFT: {
        return 'shipment-draft';
        break;
      }
      case SHIPMENT_STATUS_UNSECURED: {
        return 'shipment-unsecured';
        break;
      }
      case SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING: {
        return 'shipment-confirmation-pending';
        break;
      }
      case SHIPMENT_STATUS_BOOKED: {
        return 'shipment-booked';
        break;
      }
      case SHIPMENT_STATUS_BILL_OF_LADING_AVAILABLE: {
        return 'shipment-bill-of-lading-available';
        break;
      }
      case SHIPMENT_STATUS_OUT_FOR_DELIVERY: {
        return 'shipment-out-for-delivery';
        break;
      }
      case SHIPMENT_STATUS_DELIVERED: {
        return 'shipment-delivered';
        break;
      }
      case SHIPMENT_STATUS_COMPLETED: {
        return 'shipment-completed';
        break;
      }
      case SHIPMENT_STATUS_INACTIVE: {
        return 'shipment-inactive';
        break;
      }
      case SHIPMENT_STATUS_CANCELLED: {
        return 'shipment-cancelled';
        break;
      }
      default: {
        return '';
        break;
      }
    }
  }
}
