export const SHIPMENT_STATUS_DRAFT = 'draft';
export const SHIPMENT_STATUS_UNSECURED = 'unsecured';
export const SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING = 'confirmation pending';
export const SHIPMENT_STATUS_BOOKED = 'booked';
export const SHIPMENT_STATUS_BILL_OF_LADING_AVAILABLE = 'bill of lading available';
export const SHIPMENT_STATUS_OUT_FOR_DELIVERY = 'out for delivery';
// export const SHIPMENT_STATUS_PROOF_OF_DELIVERY_AVAILABLE = 'proof of delivery available';
export const SHIPMENT_STATUS_DELIVERED = 'delivered';
export const SHIPMENT_STATUS_COMPLETED = 'completed';
export const SHIPMENT_STATUS_INACTIVE = 'inactive';
