import { Component, OnInit, Input } from '@angular/core';
import { Quote } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';

@Component({
  selector: 'fleetoperate-shipment-confirmation-pending-view',
  templateUrl: './shipment-confirmation-pending-view.component.html',
  styleUrls: ['./shipment-confirmation-pending-view.component.scss']
})
export class ShipmentConfirmationPendingViewComponent implements OnInit {
  @Input() quote: Quote;
  actorName: string;
  actorPicture: string;
  primaryOfferRate: number;
  //actorLink: string;
  //offerCurrencyUnit: string;

  constructor() {}

  ngOnInit(): void {
    if (!this.quote) {
      return;
    }
    this.actorName = this.quote.actorName;
    this.actorPicture = this.quote.actorPicture;

    if (this.quote && this.quote.bids && this.quote.bids.length > 0 && this.quote.bids[0] && this.quote.primaryOffer) {
      this.primaryOfferRate = this.quote.primaryOffer;
    }
  }
}
