import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Action, DialogService } from '@fleetoperate/shared/ui/dialog';
import { interval, concat } from 'rxjs';
import { first } from 'rxjs/operators';
import { CheckForUpdateModule } from './check-for-update.module';
import { LoggerService } from '../logging/logging.module';

@Injectable({
  providedIn: CheckForUpdateModule
})
export class CheckForUpdateService {
  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly dialogService: DialogService,
    private readonly logger: LoggerService,
    private readonly appRef: ApplicationRef
  ) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(event => {
        this.promptUser();
      });
      this.swUpdate.activated.subscribe(event => {
        this.logger.log('Application updated to latest version.');
      });

      // Required to enable updates on Windows and ios.
      // this.swUpdate.activateUpdate();

      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everyTimeInterval$ = interval(10 * 60 * 1000);
      const everyTimeIntervalOnceAppIsStable$ = concat(appIsStable$, everyTimeInterval$);

      everyTimeIntervalOnceAppIsStable$.subscribe(() => {
        this.swUpdate.checkForUpdate().then(() => {
          this.logger.log('Checking for updates');
        });
      });
    }
  }

  private promptUser(): void {
    const appUpdateMessage = 'Load new version now for the best experience.';
    const actions = this.createAppUpdateActions();
    const dialogRef = this.dialogService.openConfirmationDialog(
      actions,
      'New version available',
      undefined,
      appUpdateMessage
    );

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe();
  }

  private createAppUpdateActions(): Action[] {
    const actions: Action[] = [];

    let action = {
      name: 'Later',
      action: (data: any) => {},
      disabled: false,
      color: 'cancel'
    } as Action;
    actions.push(action);

    action = {
      name: 'Now',
      action: (data: any) => {
        this.swUpdate.activateUpdate().then(() => {
          window.location.reload();
        });
      },
      disabled: false,
      color: 'primary'
    } as Action;
    actions.push(action);

    return actions;
  }
}
