import { Injectable, Injector, ComponentRef } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, ComponentType } from '@angular/cdk/portal';
import { SharedUiOverlayModule } from '../shared-ui-overlay.module';
import { OverlayModalRef } from './overlay-ref';
import { COMPONENT_DATA, COMPONENT_DATA_PASSED } from './overlay.tokens';
import { OverlayComponent } from './overlay.component';

const defaultOverlayComponent = OverlayComponent;

interface OverlayModalConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: any;
  dataPassed?: any;
}

const DEFAULT_CONFIG: OverlayModalConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'overlay-panel'
};

@Injectable({
  providedIn: SharedUiOverlayModule
})
export class OverlayService {
  overlayRef: OverlayRef;
  constructor(private overlay: Overlay, private injector: Injector) {}

  showDetailPanel(config: any = {}): OverlayModalRef {
    const overlayConfig = { ...DEFAULT_CONFIG, ...config };
    const overlayRef = this.createOverlay(overlayConfig);
    const overlayModalRef = new OverlayModalRef(overlayRef);
    this.attachDialogContainer(overlayRef, overlayConfig, overlayModalRef, defaultOverlayComponent);
    overlayRef.backdropClick().subscribe(_ => overlayModalRef.close());
    return overlayModalRef;
  }

  private attachDialogContainer(
    overlayRef: OverlayRef,
    config: OverlayModalConfig,
    dialogRef: OverlayModalRef,
    component: ComponentType<any>
  ): ComponentRef<any> {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(component, null, injector);
    const containerRef = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createOverlay(config: OverlayModalConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: OverlayModalConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().global();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }

  private createInjector(config: OverlayModalConfig, overlayRef: OverlayModalRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(OverlayModalRef, overlayRef);
    injectionTokens.set(COMPONENT_DATA, config.data);
    injectionTokens.set(COMPONENT_DATA_PASSED, config.dataPassed);

    return new PortalInjector(this.injector, injectionTokens);
  }
}
