import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipmentMapComponent } from './shipment-map.component';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';

@NgModule({
  declarations: [ShipmentMapComponent],
  imports: [
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCBDK79YbUP9Sa69xBVitNpctye5aAl1Pc'
    }),
    AgmDirectionModule,
  ],
  exports: [ShipmentMapComponent]
})
export class ShipmentMapModule { }
