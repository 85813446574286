import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fleetoperate-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  @Input() error: string;

  constructor() {}

  ngOnInit() {}
}
