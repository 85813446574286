import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { NgxsModule } from '@ngxs/store';
import { RegistrationRouterState } from './registration-router.state';
import { RegistrationState } from './registration.state';
import { ReactiveFormsModule } from '@angular/forms';
import { RegistrationComponent } from './registration/registration.component';
import { OperateLocationComponent } from './operate-location/operate-location.component';
import { CompanyComponent } from './company/company.component';
import { CompanyAddressComponent } from './company-address/company-address.component';
import { SubmissionComponent } from './submission/submission.component';
import { VerificationComponent } from './verification/verification.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RegistrationService } from './services/registration.service';
import { McDotOperateLocationComponent } from './mc-dot-operate-location/mc-dot-operate-location.component';
import { ErrorDataModule } from '@fleetoperate/shared/data-access-error';
import { GoogleAddressAutocompleteService } from '@fleetoperate/shared/util';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    NgxsModule.forFeature([RegistrationRouterState, RegistrationState]),
    ReactiveFormsModule,
    ErrorDataModule
  ],
  declarations: [
    RegistrationComponent,
    OperateLocationComponent,
    CompanyComponent,
    CompanyAddressComponent,
    SubmissionComponent,
    VerificationComponent,
    ConfirmationComponent,
    ProgressBarComponent,
    McDotOperateLocationComponent
  ],
  providers: [RegistrationService, GoogleAddressAutocompleteService]
})
export class SharedRegistrationFeatureRegistrationModule {}
