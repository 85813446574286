import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipmentSummaryComponent } from './shipment-summary/shipment-summary.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTableModule } from '@angular/material/table';
import { DateTimeModule } from '@fleetoperate/shared/util';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatTableModule, MatIconModule, MatButtonModule, DateTimeModule],
  exports: [ShipmentSummaryComponent],
  declarations: [ShipmentSummaryComponent]
})
export class SharedShipperUiShipperModule {}
