import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiKitMaterialModule } from '@fleetoperate/ui-kit-material';
import { ShellComponent } from './shell.component';

@NgModule({
  declarations: [ShellComponent],
  imports: [
    CommonModule,
    RouterModule,
    UiKitMaterialModule
  ],
  providers: [],
  bootstrap: [ShellComponent]
})
export class ShellModule {}
