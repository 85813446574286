import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ForgotPasswordModel } from './shared/forgot-password-model';
import { Observable } from 'rxjs';
import { ChangePasswordModel } from './shared/change-password.model';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { FleetoperateApiService } from '@fleetoperate/shared/data-access';

const PATH_FORGOT_PASSWORD = '/auth/forgotpassword';
const PATH_CONFIRM_FORGOT_PASSWORD = '/auth/confirmforgotpassword';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private readonly router: Router,
              private readonly api: FleetoperateApiService,
              private readonly authService: AuthService,
              @Inject('apiUrl') private apiUrl: string) {}

  sendForgotPasswordNotice(model: ForgotPasswordModel): Observable<any> {
    return this.api.post(this.apiUrl + PATH_FORGOT_PASSWORD, model);
  }

  changePassword(model: ChangePasswordModel): Observable<any> {
    return this.api.post(this.apiUrl + PATH_CONFIRM_FORGOT_PASSWORD, model);
  }
}
