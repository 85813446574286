<div class="default-background">
  <div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
    <div fxFlex="none" fxLayout="column" fxLayoutAlign="center stretch" class="padding-top-x2 padding-sides-2x">
      <mat-error fxFlexAlign="center" *ngIf="errorMessage" class="error">
        {{errorMessage}}
      </mat-error>
      <form [formGroup]="form" #forgotPasswordForm="ngForm" (ngSubmit)="onSubmit(forgotPasswordForm)" fxLayout="column">
        <mat-form-field>
          <mat-hint align="start">at least 8 characters</mat-hint>
          <input matInput placeholder="Old Password" [type]="hideOldPassword ? 'password' : 'text'"
            formControlName="oldPassword">
          <mat-icon matSuffix (click)="hideOldPassword = !hideOldPassword">
            {{hideOldPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-error *ngIf="oldPassword.touched && oldPassword.invalid">
            {{getOldPasswordErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-hint align="start">at least 8 characters</mat-hint>
          <input matInput placeholder="New Password" [type]="hideNewPassword ? 'password' : 'text'"
            formControlName="newPassword">
          <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword">
            {{hideNewPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-error *ngIf="newPassword.touched && newPassword.invalid">
            {{getNewPasswordErrorMessage()}}
          </mat-error>
        </mat-form-field>
        <div class="margin-top-lg margin-right-lg margin-bottom-lg">
          <button mat-raised-button class="formButton" mat-raised-button color="primary" type="submit"
            [disabled]="form.pristine || !form.valid || loading">Submit</button>
        </div>
      </form>
    </div>