<mat-card class="example-card" fxLayout="column" fxLayoutAlign="center center">
  <div class="card-header margin-bottom-md" fxLayout="row">
    <mat-icon class="margin-right-sm" *ngIf="!data.actorPicture">person</mat-icon>
    <img *ngIf="data.actorPicture" class="margin-right-sm" [src]="data.actorPicture" width="24px" height="24px">
    <mat-card-title class="actorName">{{data.actorName}}</mat-card-title>
  </div>
  <h2>{{formatCurrency(data.primaryOfferRate)}}</h2>
  <div class="card-content" fxLayout="column" fxLayoutAlign="center center">
    <mat-card-content>
      <input matInput class="counterInput" [(ngModel)]="data.counterOfferRate" placeholder="Counter offer" type="number">
    </mat-card-content>
  </div>
  <div class="card-buttons" fxLayout="column" fxLayoutAlign="center center">
    <mat-card-actions>
      <button *ngFor="let action of data.confirmAndCancelActions" mat-raised-button [disabled]="action.disabled"
        [color]="action.color" (click)="action.action(data.counterOfferRate)">{{action.name}}</button>
    </mat-card-actions>
  </div>
</mat-card>