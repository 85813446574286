import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedAuthenticationAuthenticationModule } from '@fleetoperate/shared/authentication/data-access';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedAuthenticationAuthenticationModule
  ]
})
export class ShipmentDataModule { }
