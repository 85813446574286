<div fxLayout="column" fxFlex>
  <progress-bar [count]="numSteps" [activeIndicator]="step" class="padding-bottom-md"></progress-bar>
  <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex>
    <!--fxFlex=".8 0 auto"-->
    <span class="mat-subheading-1">Step {{ step }}</span>
    <span class="mat-headline padding-bottom">Company Contact</span>
    <span class="mat-subheading-2 padding-bottom-md">You are almost done!</span>
    <mat-error *ngIf="errorMessage" fxFlexAlign="center" class="padding-bottom">{{ errorMessage }}</mat-error>
    <form [formGroup]="form" (ngSubmit)="onNavigateToNextStep()" fxLayout="column">
      <mat-form-field>
        <input matInput #addressText placeholder="Address" formControlName="address" required />
        <mat-error *ngIf="address.touched && address.invalid">
          {{ getAddressMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-hint align="start">###-###-####</mat-hint>
        <input matInput placeholder="Phone Number" formControlName="phone" required />
        <mat-error *ngIf="phone.touched && phone.invalid">
          {{ getPhoneMessage() }}
        </mat-error>
      </mat-form-field>
      <div class="actions align-center full-width padding-top-xlg">
        <button
          class="margin-bottom"
          [ngClass.lt-md]="'full-width'"
          [ngClass.gt-sm]="'quarter-width'"
          mat-button
          type="button"
          (click)="onNavigateToPreviousStep()"
        >
          Back
        </button>
        <button
          class="margin-bottom"
          [ngClass.lt-md]="'full-width'"
          [ngClass.gt-sm]="'quarter-width'"
          mat-raised-button
          color="primary"
          type="button"
          (click)="onNavigateToNextStep()"
          [disabled]="form.pristine || !form.valid"
        >
          Sign up
        </button>
        <button
          class="margin-bottom"
          [ngClass.lt-md]="'full-width'"
          [ngClass.gt-sm]="'half-width'"
          mat-button
          type="button"
          (click)="onNavigateToLogin()"
        >
          Already a member? Log in
        </button>
      </div>
    </form>
  </div>
</div>
