<div fxLayout="column" fxLayoutAlign="center stretch" class="loginPageBackground">
  <div fxLayout fxFlexAlign="center" class="brand-title" *ngIf="!isDeliveryShipper; else showDeliveryLogo">
    <img src="assets/img/FleetOperate-white-logo.png" />
    <div class="fleetoperate-logo-light" fxFlexAlign="center" fxLayout="column">
      <span class="fleetoperate-logo-top"></span>
      <span class="fleetoperate-logo-bottom"></span>
    </div>
  </div>
  <ng-template #showDeliveryLogo>
    <div fxLayout fxFlexAlign="center" class="delivery-brand-title">
      <img src="assets/img/FleetOperate-delivery-white-logo.png" />
      <div class="fleetoperate-logo-light" fxFlexAlign="center" fxLayout="column">
        <span class="fleetoperate-logo-top"></span>
        <span class="fleetoperate-logo-bottom"></span>
      </div>
    </div>
  </ng-template>
  <div>
    <h2 class="title">Forgot <span>Password</span></h2>
  </div>
  <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex class="padding-top-x2">
    <div fxFlexAlign="center" class="messageText">
      <p>{{ message }}</p>
    </div>

    <mat-error fxFlexAlign="center" *ngIf="errorMessage" class="error" data-test="form-error-message">
      {{ errorMessage }}
    </mat-error>
    <form [formGroup]="form" #forgotPasswordForm="ngForm" (ngSubmit)="onSubmit(forgotPasswordForm)" fxLayout="column">
      <mat-form-field>
        <input type="email" matInput formControlName="email" data-test="email-form-field" />
        <mat-placeholder>Email</mat-placeholder>
        <mat-error *ngIf="email.touched && email.invalid">
          {{ getEmailErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <div fxLayout fxLayoutAlign="center end" class="padding-top-x4">
        <button
          fxFlex.gt-sm="20%"
          fxFlex.lt-md="100%"
          class="full-width margin-bottom"
          mat-raised-button
          color="primary"
          type="submit"
          data-test="submit-button"
          [disabled]="form.pristine || !form.valid || loading"
        >
          Submit
        </button>
      </div>
    </form>
    <span fxFlexAlign="center">
      <a mat-button [routerLink]="" (click)="onNavigateToSignIn()"
        ><i class="material-icons" data-test="backto-signin-link">chevron_left</i> Back to Signin</a
      >
    </span>
  </div>
</div>
