import { Component, OnInit, Input } from '@angular/core';
import { Shipment, Quote } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { QUOTE_STATUS_OWNER_ACCEPTED } from '../shared/quote.status';

@Component({
  selector: 'fleetoperate-shipment-confirmation-pending-views',
  templateUrl: './shipment-confirmation-pending-views.component.html',
  styleUrls: ['./shipment-confirmation-pending-views.component.scss']
})
export class ShipmentConfirmationPendingViewsComponent implements OnInit {
  @Input() shipment: Shipment;
  ownerAcceptedQuotes: Quote[];

  constructor() {
    this.ownerAcceptedQuotes = [];
  }

  ngOnInit() {
    if (!this.shipment || !this.shipment.quotes || this.shipment.quotes.length <= 0) {
      return;
    }
    for (let i = 0; i < this.shipment.quotes.length; i++) {
      if (!this.shipment.quotes[i]) {
        return;
      }
      if (this.shipment.quotes[i].status === QUOTE_STATUS_OWNER_ACCEPTED) {
        this.ownerAcceptedQuotes.push(this.shipment.quotes[i]);
      }
    }
  }
}
