<fleetoperate-delivery-shipper-app-toolbar
  [data]="user"
  [profilePath]="shipperProfilePath"
  (logout)="onLogout()"
></fleetoperate-delivery-shipper-app-toolbar>
<fleetoperate-header
  [title]="title"
  [backAction]="backAction"
  class="margin-left-lg margin-right-md"
></fleetoperate-header>
<div class="margin-right-lg margin-left-lg ">
  <div class="navbar">
    <nav mat-tab-nav-bar fxLayout>
      <a
        mat-tab-link
        *ngFor="let link of navLinks"
        class="margin-right-sm"
        (click)="activeLink = link"
        [active]="activeLink == link"
        [routerLink]="link.link"
        fxFlex
        routerLinkActive="router-link-active"
      >
        <div class="tabs">{{ link.label }}</div>
      </a>
    </nav>
  </div>
  <div class="outlets">
    <router-outlet></router-outlet>
  </div>
</div>
